import {Component} from "@angular/core";
import {Organization, SessionParticipationMode, SessionType} from "../../../revogo-client/revogo-client.types";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {RevogoClientService} from "../../../revogo-client/revogo-client.service";
import {StateRouterService} from "../../../state-router/state-router.service";
import {ActivatedRoute} from "@angular/router";
import {STATE} from "../../app-states";

@Component({
  selector: 'app-session-new',
  templateUrl: './session-new.component.html',
  styleUrls: ['./session-new.component.sass']
})
export class SessionNewComponent {
  public form!: FormGroup;
  public loading = false;
  private organization!: Organization;


  public sessionType = SessionType;
  public sessionTypes = [SessionType.QCM, SessionType.ProblemAnalysis];

  public participationModes = Object.values(SessionParticipationMode);
  public participationModeDisplayName: {[key: string]: string} = {
    [SessionParticipationMode.AnonymousInvitation]: "Invitation anonyme",
    [SessionParticipationMode.AuthenticatedUsers]: "Utilisateurs enregistrés"
  }

  constructor(private formBuilder: FormBuilder,
              private revogoClient: RevogoClientService,
              private stateRouter: StateRouterService,
              private route: ActivatedRoute) {
    this.form = formBuilder.group({
      sessionName: ['', Validators.required],
      sessionType: [this.sessionTypes[0], Validators.required],
      participationMode: [this.participationModes[0], Validators.required],
    });
    this.organization = route.snapshot.data.organization;
    this.adaptFormToParticipationMode();
    this.adaptFormToSessionType();
  }

  public adaptFormToParticipationMode() {
    this.form.removeControl("tokenCount");
    switch (this.form.controls.participationMode.value) {
      case SessionParticipationMode.AnonymousInvitation:
        this.form.addControl('tokenCount', new FormControl(5, Validators.required));
        break;
    }
  }

  public adaptFormToSessionType() {
    this.form.removeControl('problemAnalysisStatement');
    switch (this.form.controls.sessionType.value) {
      case SessionType.ProblemAnalysis:
      case SessionType.SolutionGeneration:
        this.form.addControl('problemAnalysisStatement', new FormControl('', Validators.required));
        break
    }
  }

  public async submit() {
    if (this.form.valid) {
      this.loading = true;
      const payload: { [key: string]: any } = {
        organization_id: this.organization.organizationId,
        session_name: this.form.controls.sessionName.value,
        session_type: this.form.controls.sessionType.value,
        participation_mode: this.form.controls.participationMode.value,
      };
      switch (payload.participation_mode) {
        case SessionParticipationMode.AnonymousInvitation:
          payload['anonymous_invitation'] = {
            token_count: this.form.controls.tokenCount.value
          };
          break;
        case SessionParticipationMode.AuthenticatedUsers:
          payload['anonymous_invitation'] = {
            token_count: 0
          };
        // Implement other types here!
      }

      switch (payload.session_type) {
        case SessionType.ProblemAnalysis:
        case SessionType.SolutionGeneration:
          payload.session_definition = {
            statement: this.form.controls.problemAnalysisStatement.value,
          }
      }

      const created = await this.revogoClient.createSession(
        payload
      ).toPromise();
      this.loading = false;
      await this.stateRouter.nav(STATE.SESSIONS, created.sessionId, STATE.SESSIONS_DETAILS);
    }
  }
}
