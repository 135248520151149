import { Injectable } from "@angular/core";
import { environment } from './environment';

export type AppConfig = { [key: string]: string };

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private configuration: AppConfig = {...environment};

  constructor() {
    let env: {[key:string]: string};
    try {
      env = process.env as AppConfig;
    } catch (err) {
      env = {};
    }

    for (var property in this.configuration) {
      if (Object.prototype.hasOwnProperty.call(this.configuration, property)) {
          if (env[property] !== undefined) {
            this.configuration[property] = env[property] as string;
          }
      }
    }
  }

  public getConfig(): AppConfig {
    return {...this.configuration};
  }

  public get(key: string): string {
    return this.configuration[key];
  }
}
