import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {GenericConfirmationModalComponent} from "./generic-confirmation-modal.component";
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";

@NgModule({
  imports: [CommonModule, MatDialogModule, MatButtonModule],
  declarations: [GenericConfirmationModalComponent],
  exports: [GenericConfirmationModalComponent]
}) export class ModalsModule {}
