import {Component, Input} from "@angular/core";

@Component({
  selector: 'generic-confirmation-modal',
  template: `
    <h2 mat-dialog-title>{{title}}</h2>
    <mat-dialog-content class="m-4 text-center">
      <div *ngFor="let line of textLines" class="my-2">{{line}}</div>
    </mat-dialog-content>
    <mat-dialog-actions class="flex">
      <button mat-stroked-button color="primary" [mat-dialog-close]="false">Annuler</button>
      <div class="flex-grow"></div>
      <button mat-raised-button color="primary" [mat-dialog-close]="true">Confirmer</button>
    </mat-dialog-actions>`
})
export class GenericConfirmationModalComponent {
  @Input()
  title!: string;
  @Input()
  textLines!: string[];
}
