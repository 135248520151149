export enum SessionType {
  QCM = "QCM",
  ProblemAnalysis = "PROBLEM_ANALYSIS",
  SolutionGeneration = "SOLUTION_GENERATION"
}

export enum SessionParticipationMode {
  AnonymousInvitation = "anonymous_invitation",
  AuthenticatedUsers = "authenticated_users",
}

export enum SessionState {
  Draft = "draft",
  Open = "open",
  Started = "started",
  Finished = "finished"
}

export enum AnonymousInvitationState {
  Draft = "draft",
  Open = "open",
  Closed = "closed"
}

export enum SessionUserRole {
  Sage = "sage",
  Player = "player",
  Guest = "guest",
}

export type UserAuthorizations = {
  userId: string;
  roles: {
    organizationId: string,
    roleName: string,
    resources: string[],
    services: string[]
  }[]
}

export type UserData = {
  givenName: string,
  email: string,
  familyName: string,
};

export type UserOrganizationAuthorization = {
  organizationId: string;
  userId: string;
  roles: string[];
  user: UserData;
}

export type Session = {
  inviteSlug: string;
  organizationId: string;
  sessionId: string;
  startDate: Date | null;
  endDate: Date | null;
  creationDate: Date;
  sessionName: string;
  sessionType: SessionType;
  sessionState: SessionState;
  participationMode: SessionParticipationMode;
  sessionDefinition: { [key: string]: any };
  anonymousInvitation: {
    tokenCount: number,
    state: AnonymousInvitationState
  }
  parentMindMapNode?: string,
  claimedTokenCount?: number,
}

export enum QcmPropositionAnswerType {
  Choice = "CHOICE",
  FreeText = "FREE_TEXT"
}

export type QcmPropositionAnswer = {
  type: QcmPropositionAnswerType,
  label: string,
  options?: {[key: string]: any},
};

export type QcmProposition = {
  title: string
  allowedAnswers: QcmPropositionAnswer[]
}

export type QcmSession = Session & {
  sessionDefinition: {
    propositions: QcmProposition[]
  }
}

export type ProblemAnalysisSession = Session & {
  sessionDefinition: {
    statement: string
  }
}

export type SolutionGenerationSession = Session & {
  sessionDefinition: {
    statement: string
  }
}

export type Organization = {
  organizationId: string,
  organizationName: string
}

export enum SessionEntityType {
  QcmAnswers = "qcm_answers",
  ThreadMessage = "thread_message",
}

export type SessionEntity = {
  sessionId: string,
  entityId: string,
  sessionType: SessionType,
  ownerReference: string,
  entityType: SessionEntityType,
  createdAt: Date,
}

export type QcmSessionEntityAnswer = {
  index: number,
  content?: string
};

export type QcmSessionEntity = SessionEntity & {
  entityData: {
    updatedAt: Date,
    validated: boolean,
    answers: QcmSessionEntityAnswer[]
  }
}

export type ThreadMessageEntity = SessionEntity & {
  entityData: {
    parentMessageId?: string,
    authorInfo?: {
      ownerReference: string,
      authorDisplayName: string,
      authorRole: SessionUserRole
    }
    content: string,
    votes: {
      pro: string[],
      against: string[],
    },
    mindMapAnnotations?: {
      kind: string,
      sign?: number,
    }
  }
}

export type QcmSessionResults = {
  propositions: {
    answers: {
      count: number,
      text: {[key: string]: number},
    }[],
    participantCount: number,
  }[],
  participantCount: number,
}

export type MindMapProblem = {
  id: string,
  sessionId?: string,
  organizationId: string,
  title: string,
  description?: string,
  creationDate: Date,
  resolutionDate?: Date,
}

export type MindMapSolution = {
  id: string,
  sessionId?: string,
  problemId: string,
  organizationId: string,
  title: string,
  description?: string,
  creationDate: Date,
  establishmentDate?: Date,
}

export enum ArgumentSign {
  Pro = 1,
  Against = -1
}

export type MindMapArgument = {
  id: string,
  sessionId?: string,
  organizationId: string,
  targetNode: string,
  description: string,
  sign: ArgumentSign,
  weight?: number,
  creationDate: Date,
  editable: boolean,
}
