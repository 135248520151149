<div class="qcm-content-container">
  <h1>
    {{session.sessionName}}
  </h1>
  <div>
    <div class="screen-container flex-grow max-w-screen-sm object-center" *ngIf="ready">
      <div class="border border-gray-500 rounded-sm proposition-container m-2">
        <div class="text-center m-4 text-md">
          {{propositions[pI].title}}
        </div>
        <div class="text-lg text-gray-500 m-2">{{pI + 1}}/{{propositions.length}}</div>
      </div>
      <div class="form-container" *ngIf="propositions[pI].allowedAnswers">
        <form [formGroup]="form">
          <mat-button-toggle-group formControlName="answer" vertical required>
            <ng-container class="answer-container" *ngFor="let allowedAnswer of propositions[pI].allowedAnswers; let index = index">
              <mat-button-toggle [value]="index">
                {{allowedAnswer.label}}
              </mat-button-toggle>
              <ng-container *ngIf="allowedAnswer.type === qcmPropositionAnswerType.FreeText">
                <mat-form-field [formGroup]="form" *ngIf="form.controls.answer.value === index">
                  <mat-label>Pouvez-vous préciser ?</mat-label>
                  <textarea matInput [formControlName]="'freeText'+index.toString()"></textarea>
                </mat-form-field>
              </ng-container>
            </ng-container>
          </mat-button-toggle-group>
        </form>
      </div>
      <div class="qcm-flow-actions-container" *ngIf="form && propositions">
        <div class="previous-button-container">
          <button mat-stroked-button color="primary" [disabled]="pI === 0" (click)="previous()">Précédent</button>
        </div>
        <div class="next-button-container">
          <button mat-raised-button color="primary" [disabled]="!form || !freeTextIsFilled()" (click)="next()" >Suivant</button>
        </div>
      </div>
    </div>
    <div class="flex" *ngIf="!ready">
      <div class="flex-grow"></div>
      <mat-spinner [diameter]="128"></mat-spinner>
      <div class="flex-grow"></div>
    </div>
  </div>

</div>
