import {Injectable} from "@angular/core";
import {Router} from "@angular/router";

@Injectable({providedIn: 'root'})
export class TempRedirectService {
  private storageKey = 'redirectToBeExecuted';
  private storedValue!: string | undefined;

  constructor(private router: Router) {
    this.sync();
  }

  private sync() {
    const stored = localStorage.getItem(this.storageKey);
    if (stored !== null) {
      this.storedValue = stored;
    }
  }

  public setUrl(url: string | undefined) {
    if (url === undefined) {
      localStorage.removeItem(this.storageKey);
    } else {
      localStorage.setItem(this.storageKey, url);

    }
    this.sync();
  }

  public getUrl(): string | undefined {
    this.sync();
    return this.storedValue;
  }

  public hasRedirect(): boolean {
    this.sync();
    return this.storedValue !== undefined;
  }

  public async execute() {
    this.sync();
    if (this.storedValue !== undefined) {
      await this.router.navigateByUrl(this.storedValue);
      this.setUrl(undefined);
    } else {
      console.error('Redirect service could not execute nonexistent redirect');
    }
  }
}
