import {Component, Input, OnInit, Output, EventEmitter} from "@angular/core";
import {
  ProblemAnalysisSession,
  Session,
  SessionState
} from "../../../../revogo-client/revogo-client.types";
import {RevogoClientService} from "../../../../revogo-client/revogo-client.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {OrganizationService} from "../../../../auth/organization.service";

@Component({
  selector: 'app-problem-analysis-session-content',
  template: `
    <div class="border border-gray-500 rounded-sm">
      <div class="m-2 flex">
        <div class="mx-1">
          <mat-icon matTooltip="Le message sur lequel les participants se baseront pour discuter du problème">info</mat-icon>
        </div>
        <div class="text-sm text-gray-500 mt-1">Définition du problème</div>
      </div>
      <div class="m-4" *ngIf="!editMode; else statementInput">
        <p class="leading-3" *ngFor="let line of statementLines">{{line}}</p>
      </div>
      <ng-template #statementInput>
        <div class="m-4">
          <mat-form-field class="w-full">
            <mat-label>Définition du problème</mat-label>
            <textarea matInput rows="10" [(ngModel)]="session.sessionDefinition.statement" (change)="setDirty()"></textarea>
          </mat-form-field>
        </div>
      </ng-template>
      <div *ngIf="editable" class="m-2">
        <button mat-raised-button color="primary" (click)="toggleEdit()" *ngIf="editMode; else noEdit">Sauvegarder</button>
        <ng-template #noEdit>
          <button mat-stroked-button color="primary" (click)="toggleEdit()">Modifier</button>
        </ng-template>
      </div>
    </div>
  `,
})
export class ProblemAnalysisSessionContentComponent implements OnInit {
  @Input('session')
  public _session!: Session;
  public session!: ProblemAnalysisSession;
  public editable!: boolean;
  public sessionState = SessionState;
  public editMode = false;
  public statementLines!: string[];

  @Input()
  public canWrite!: boolean;

  @Output()
  public dirty = new EventEmitter<boolean>();

  constructor(private revogoClient: RevogoClientService,
              private organizationService: OrganizationService,
              private snackbar: MatSnackBar) {

  }

  async ngOnInit() {
    this.session = this._session as ProblemAnalysisSession;
    this.statementLines = this.session.sessionDefinition.statement.split('\n');
    this.editable = this.session.sessionState === SessionState.Draft && this.canWrite;
    console.log(this.session.sessionState);
    console.log(this.canWrite);
  }

  public async updateSessionDefinition(body: { [key: string]: any } | undefined = undefined) {
    if (body === undefined) {
      body = this.session.sessionDefinition;
    }
    await this.revogoClient.updateSessionDefinition(this.session.sessionId, body).toPromise().then(
      () => {
        this.snackbar.open('Vos modifications ont été sauvegardées', undefined, {
          duration: 2000
        });
      }
    );
    this.dirty.emit(false);
  }

  public setDirty() {
    this.dirty.emit(true);
  }

  public async toggleEdit() {
    if (this.editMode) {
      await this.updateSessionDefinition();
      this.editMode = false;
    } else {
      this.statementLines = this.session.sessionDefinition.statement.split('\n');
      this.editMode = true;
    }
  }
}
