<div class="body-container">
  <div class="login-container">
    <h3>Connexion</h3>

    <div id="social-login-container">
      <button (click)="googleSignIn()" mat-raised-button id="google-login-container">
        <img class="google-logo" src="/assets/google_logo.svg" alt="google logo">
        <span class="spacer"></span>
        <span class="google-connect-text">Continuer avec <span class="font-bold">Google</span></span>
        <span class="spacer"></span>
      </button>

      <button (click)="microsoftSignIn()" mat-raised-button id="microsoft-login-container">
        <img  class="microsoft-logo" src="/assets/microsoft_logo.svg" alt="microsoft logo">
        <span class="spacer"></span>
        <span class="microsoft-connect-text">Continuer avec <span class="font-bold">Microsoft</span></span>
        <span class="spacer"></span>
      </button>
    </div>
    <div>
      <form (submit)="login()" action="#" [formGroup]="form">
        <div>
          <mat-form-field>
            <mat-label>E-Mail</mat-label>
            <input matInput type="text" formControlName="email" required>
          </mat-form-field>
        </div>

        <div>
          <mat-form-field>
            <mat-label>Mot de passe</mat-label>
            <input matInput type="password" formControlName="password" required>
          </mat-form-field>
        </div>

        <div *ngIf="showForgotPassword && this.form.controls.email.valid" class="m-1 text-center text-sm text-darkgray cursor-pointer" [routerLink]="'/forgot_password'" [queryParams]="{u: this.form.controls.email.value}">
          Mot de passe oublié ? Cliquez ici.
        </div>

        <div>
          <button mat-raised-button color="primary" (click)="login()" [disabled]="loading || !form.valid" type="submit">
            <mat-spinner style="margin:0 auto;" *ngIf="loading; else connecttext" diameter="32"></mat-spinner>
            <ng-template #connecttext>
              <span>Se connecter</span>
            </ng-template>
          </button>
        </div>
      </form>
      <div id="go-to-signup-cta">
        Pas encore de compte ? <a href="{{signUpLink}}">Inscrivez-vous!</a>
      </div>
    </div>
  </div>
</div>
