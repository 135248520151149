import {Component, Input, OnInit} from "@angular/core";
import {Session, SolutionGenerationSession} from "../../../revogo-client/revogo-client.types";
import {Subject} from "rxjs";
import {
  ThreadContextActionEvent,
  ThreadEvent,
  ThreadEventKind,
  ThreadReplyEvent,
  ThreadVoteEvent
} from "../../message-thread/thread.event";
import {DeleteThreadMessageModalComponent} from "./delete-thread-message-modal.component";
import {BanThreadUserModalComponent} from "./ban-thread-user-modal.component";
import {LiveThreadService, ThreadNode} from "../../../live-thread/live-thread.service";
import {RevogoClientService} from "../../../revogo-client/revogo-client.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MatSelectChange} from "@angular/material/select";
import {MessageContextButtonDefinition} from "../../message-thread/thread-context-actions";

@Component({
  selector: 'app-play-solution-generation-thread',
  template: `
    <app-thread-view
      [session]="session"
      [contextButtonDefinition]="messageContextActionsButtonsDefinition"
      [syncDebounceChannel]="syncDebounceChannel"
      [messageProcessor]="messageProcessor"
      (event)="processThreadEvent($event)"
    ></app-thread-view>
  `
})
export class PlaySolutionGenerationThread implements OnInit {
  @Input("session")
  _session!: Session;
  public session!: SolutionGenerationSession;
  public syncDebounceChannel = new Subject<boolean>();

  public messageContextActionsButtonsDefinition: MessageContextButtonDefinition[] = [
    {
      icon: 'delete',
      eventKey: 'delete',
      label: 'Supprimer',
      displayCondition: (node, service) => ((node.isMine || service.amSage()) && node.children.length === 0),
    },
    {
      icon: 'edit',
      eventKey: 'edit',
      label: 'Modifier',
      displayCondition: (node, service) => (node.isMine || service.amSage()),
    },
    {
      icon: 'no_accounts',
      eventKey: 'banUser',
      label: 'Bannir',
      displayCondition: (node, service) => (node.ownerId.startsWith('guest') && service.amSage())
    }
  ];

  constructor(private liveThreadService: LiveThreadService,
              private revogoClient: RevogoClientService,
              private snackbar: MatSnackBar,
              private dialog: MatDialog) {
  }

  ngOnInit() {
    this.session = this._session as SolutionGenerationSession;
  }

  public messageProcessor(node: ThreadNode): string {
    if (node.isRoot && node.mindMapAnnotations?.kind === 'solution') {
      return `${node.message}<div class="text-green-500 italic mt-1">Solution</div>`;
    } else if (!node.isRoot && node.mindMapAnnotations?.kind === 'argument') {
      if (node.mindMapAnnotations?.sign === 1) {
        return `${node.message}<div class="text-green-500 italic mt-1">Argument pour</div>`;
      } else {
        return `${node.message}<div class="text-red-500 italic mt-1">Argument contre</div>`;
      }
    }
    else {
      return node.message;
    }
  }


  public synchronize(debounce: boolean = true) {
    this.syncDebounceChannel.next(debounce);
  }

  public async postMessage(modalContent: { message: string, kind: string, sign?: number }, parentId: string | undefined = undefined) {
    const body: { [key: string]: any } = {
      entity_data: {
        content: modalContent.message,
        mind_map_annotations: {
          kind: modalContent.kind,
        }
      }
    };
    if (modalContent.sign !== undefined) {
      body.entity_data.mind_map_annotations.sign = modalContent.sign;
    }
    if (parentId !== undefined) {
      body.entity_data.parent_message_id = parentId;
    }
    console.log(body);
    await this.revogoClient.createSessionEntity(this.session.sessionId, body).toPromise().catch(error => {
      this.snackbar.open(`Nous n'avons pas pu publier votre message: ${error}`, undefined, {
        duration: 5000,
        panelClass: ['error-snackbar']
      });
    }).then(async (e) => {
      if (e) {
        this.snackbar.open('Message publié avec succès', undefined, {duration: 2000});
        await this.doVote({kind: ThreadEventKind.Vote, nodeId: e.entityId, sign: +1}, false);
      }
    });
  }

  public async editMessage(nodeId: string, modalContent: { message: string, kind: string, sign?: number }) {
    const body = {
      entity_data: {
        content: modalContent.message,
        mind_map_annotations: {
          kind: modalContent.kind,
        }
      }
    };
    if (modalContent.sign !== undefined) {
      // @ts-ignore
      body.entity_data.mind_map_annotations.sign = modalContent.sign;
    }
    console.log(body);
    await this.revogoClient.updateSessionEntity(this.session.sessionId, nodeId, body).toPromise().catch(error => {
      this.snackbar.open("Nous n'avons pas pu publier votre message. Vous pouvez réessayer.", undefined, {
        panelClass: ['error-snackbar'],
        duration: 5000
      });
    }).then(
      async res => {
        this.snackbar.open("Message édité avec succès", undefined, {duration: 2000});
        await this.synchronize();
      }
    );
  }


  public postMessageModal(parentId: string | undefined = undefined) {
    const ref = this.dialog.open(PostSolutionGenerationMessageModalComponent, {panelClass: 'wide-modal'});
    ref.componentInstance.isRoot = parentId === undefined;
    ref.afterClosed().subscribe(
      async result => {
        if (result) {
          await this.postMessage(result, parentId);
        }
      }
    )
  }

  public editMessageModal(node: ThreadNode) {
    const ref = this.dialog.open(EditSolutionGenerationMessageModalComponent, {panelClass: 'wide-modal'});
    ref.componentInstance.isRoot = node.isRoot;
    ref.componentInstance.message = node.message;
    ref.componentInstance.kind = node.mindMapAnnotations?.kind ?? 'comment';
    ref.componentInstance.sign = node.mindMapAnnotations?.sign;
    ref.afterClosed().subscribe(
      async result => {
        if (result) {
          await this.editMessage(node.id, result);
        }
      }
    )
  }

  private async deleteMessage(nodeId: string) {
    await this.revogoClient.deleteSessionEntity(this.session.sessionId, nodeId).toPromise().then(() => {
        this.snackbar.open('Message supprimé', undefined, {duration: 2000});
        this.synchronize();
      }
    ).catch(
      err => {
        this.snackbar.open('Impossible de supprimer votre message.', undefined, {
          duration: 5000,
          panelClass: ['error-snackbar']
        });
      }
    );
  }

  public deleteMessageModal(nodeId: string) {
    const ref = this.dialog.open(DeleteThreadMessageModalComponent, {panelClass: 'wide-modal'});
    ref.afterClosed().subscribe(
      async result => {
        if (result) {
          await this.deleteMessage(nodeId);
        }
      }
    );
  }

  public banUserModal(userId: string) {
    const ref = this.dialog.open(BanThreadUserModalComponent, {panelClass: 'wide-modal'});
    ref.afterClosed().subscribe(
      async result => {
        if (result) {
          await this.revogoClient.banSessionUser(this.session.sessionId, userId, result.deleteEntities).toPromise();
          this.synchronize(false);
          this.snackbar.open("L'utilisateur a été banni.", undefined, {duration: 5000});
        }
      }
    )
  }

  public async doVote(event: ThreadVoteEvent, debounce: boolean = true) {
    this.liveThreadService.setVote(event.nodeId, event.sign);
    await this.revogoClient.sessionEntityVote(this.session.sessionId, event.nodeId, event.sign).toPromise().catch(
      err => this.snackbar.open(`Erreur lors de l'enrigstrement de votre vote. Vous pouvez réessayer.`, undefined, {
        panelClass: ['error-snackbar'],
        duration: 5000
      })
    );
    await this.synchronize(debounce);
  }

  public async processThreadEvent(event: ThreadEvent) {
    switch (event.kind) {
      case ThreadEventKind.Reply:
        const replyEvent = event as ThreadReplyEvent;
        this.postMessageModal(replyEvent.parentNodeId);
        break;
      case ThreadEventKind.Vote:
        const voteEvent = event as ThreadVoteEvent;
        await this.doVote(voteEvent);
        break
      case ThreadEventKind.UnspecifiedContextAction:
        const contextEvent = event as ThreadContextActionEvent;
        switch (contextEvent.specificEventKey) {
          case 'delete':
            this.deleteMessageModal(contextEvent.node.id);
            break
          case 'edit':
            this.editMessageModal(contextEvent.node);
            break;
          case 'banUser':
            this.banUserModal(contextEvent.node.ownerId);
        }
        break;
    }
  }
}

@Component({
  selector: 'app-post-solution-generation-message-modal',
  template: `
    <mat-dialog-content class="mat-typography">
      <form [formGroup]="form">
        <mat-form-field class="my-2">
          <mat-label>Votre réponse est :</mat-label>
          <mat-select formControlName="kind" (selectionChange)="updateForm($event)">
            <mat-option *ngIf="isRoot" [value]="'solution'">Une idée de solution</mat-option>
            <mat-option *ngIf="!isRoot" [value]="'argument'">Un argument</mat-option>
            <mat-option [value]="'comment'">Un commentaire</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="!isRoot && form.controls.kind.value === 'argument'">
          <mat-label>Pour ou contre son parent ?</mat-label>
          <mat-select formControlName="sign">
            <mat-option [value]="1">Pour</mat-option>
            <mat-option [value]="-1">Contre</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="w-full my-2">
          <mat-label>Votre réponse</mat-label>
          <textarea rows="10" class="w-full" matInput formControlName="message"></textarea>
        </mat-form-field>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions class="flex">
      <button mat-stroked-button color="primary" [mat-dialog-close]="null">
        Annuler
      </button>
      <div class="flex-grow"></div>
      <button mat-raised-button color="primary" [mat-dialog-close]="formData()" [disabled]="!form.valid">
        Valider
      </button>
    </mat-dialog-actions>
  `
})
export class PostSolutionGenerationMessageModalComponent implements OnInit {
  @Input()
  isRoot!: boolean;

  public form!: FormGroup;

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    let defaultKind;
    if (this.isRoot) {
      defaultKind = 'solution';
    } else {
      defaultKind = 'argument';
    }

    this.form = this.formBuilder.group({
      message: new FormControl('', Validators.required),
      kind: new FormControl(defaultKind, Validators.required),
    });
    if (!this.isRoot) {
      this.form.addControl('sign', new FormControl(undefined, Validators.required));
    }
  }

  public updateForm($event: MatSelectChange) {
    if (this.form.controls.sign !== undefined) {
      this.form.removeControl('sign');
    }
    if ($event.value === 'argument') {
      this.form.addControl('sign', new FormControl(undefined, Validators.required));
    }
  }

  public formData(): { message: string, kind: string, sign?: number } {
    const data: { message: string, kind: string, sign?: number } = {
      message: this.form.controls.message.value,
      kind: this.form.controls.kind.value,
    };
    if (data.kind === 'argument') {
      data.sign = this.form.controls.sign.value;
    }
    return data;
  }
}

@Component({
  selector: 'app-edit-solution-generation-message',
  template: `
    <mat-dialog-content class="mat-typography">
      <form [formGroup]="form">
        <mat-form-field class="my-2">
          <mat-label>Votre réponse est :</mat-label>
          <mat-select formControlName="kind" (selectionChange)="updateForm($event)">
            <mat-option *ngIf="isRoot" [value]="'solution'">Une idée de solution</mat-option>
            <mat-option *ngIf="!isRoot" [value]="'argument'">Un argument</mat-option>
            <mat-option [value]="'comment'">Un commentaire</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="!isRoot && form.controls.kind.value === 'argument'">
          <mat-label>Pour ou contre son parent ?</mat-label>
          <mat-select formControlName="sign">
            <mat-option [value]="1">Pour</mat-option>
            <mat-option [value]="-1">Contre</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="w-full my-2">
          <mat-label>Votre réponse</mat-label>
          <textarea rows="10" class="w-full" matInput formControlName="message"></textarea>
        </mat-form-field>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions class="flex">
      <button mat-stroked-button color="primary" [mat-dialog-close]="null">
        Annuler
      </button>
      <div class="flex-grow"></div>
      <button mat-raised-button color="primary" [mat-dialog-close]="formData()" [disabled]="!form.valid">
        Valider
      </button>
    </mat-dialog-actions>
  `
})
export class EditSolutionGenerationMessageModalComponent implements OnInit {
  @Input()
  isRoot!: boolean;

  @Input()
  message!: string;

  @Input()
  kind!: string;

  @Input()
  sign!: number | undefined;

  public form!: FormGroup;

  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      message: new FormControl(this.message, Validators.required),
      kind: new FormControl(this.kind, Validators.required),
    });
    if (!this.isRoot) {
      this.form.addControl('sign', new FormControl(this.sign, Validators.required));
    }
  }

  public updateForm($event: MatSelectChange) {
    if (this.form.controls.sign !== undefined) {
      this.form.removeControl('sign');
    }
    if ($event.value === 'argument') {
      this.form.addControl('sign', new FormControl(this.sign, Validators.required));
    }
  }

  public formData(): { message: string, kind: string, sign?: number } {
    const data: { message: string, kind: string, sign?: number } = {
      message: this.form.controls.message.value,
      kind: this.form.controls.kind.value,
    };
    if (data.kind === 'argument') {
      data.sign = this.form.controls.sign.value;
    }
    return data;
  }
}
