import {Component, Input, OnInit} from "@angular/core";
import {
  MindMapProblem,
  ProblemAnalysisSession,
  Session,
  SessionState,
  SessionType
} from "../../../revogo-client/revogo-client.types";
import {MindMapRepository} from "../session-list/session-list.component";
import {Observable} from "rxjs";
import {sessionTypeDisplayName} from "../session-type.component";

@Component({
  selector: 'app-problem-analysis-session-card',
  templateUrl: './problem-analysis-session-card.component.html',
})
export class ProblemAnalysisSessionCard implements OnInit {
  @Input("session")
  public _session!: Session;

  @Input()
  public mindMapRepository!: Observable<MindMapRepository>;

  @Input()
  public editable!: boolean;

  public session!: ProblemAnalysisSession;
  public sessionState = SessionState;
  public validated!: boolean;
  public parentProblem!: MindMapProblem | undefined;
  public sessionTypes = sessionTypeDisplayName;

  async ngOnInit() {
    this.session = this._session as ProblemAnalysisSession;
    if (this.session.parentMindMapNode !== undefined) {
      const problemId = this.session.parentMindMapNode.split(':')[1];

      const data = await this.mindMapRepository.toPromise();
      this.parentProblem = data.problems[problemId];
    }
  }

  public isValidProblemAnalysisSession(): boolean {
    return (
      this.session.sessionDefinition &&
      this.session.sessionDefinition.statement !== undefined &&
      this.session.sessionDefinition.statement.length !== 0
    );
  }
}
