import {Component, Input, OnInit} from "@angular/core";
import {MindMapProblem, MindMapSolution, Session} from "../../../../revogo-client/revogo-client.types";
import {StateRouterService} from "../../../../state-router/state-router.service";
import {STATE} from "../../../app-states";
import {Subject} from "rxjs";
import {SessionMindMapEntities} from "../session-details.component";

@Component({
  selector: 'app-session-mind-map-entities',
  templateUrl: './session-mind-map-entities.component.html',
  styleUrls: ['./session-mind-map-entities.component.sass']
})
export class SessionMindMapEntitiesComponent implements OnInit {
  @Input()
  session!: Session;

  @Input()
  mindMapEntities!: Subject<SessionMindMapEntities>;

  public problems!: MindMapProblem[];
  public solutions!: MindMapSolution[];

  constructor(
    private stateRouter: StateRouterService
  ) {
  }

  ngOnInit() {
    this.mindMapEntities.subscribe(({problems, solutions}) => {
      this.problems = problems;
      this.solutions = solutions;
    })
  }

  ngOnDestroy() {
    this.mindMapEntities.unsubscribe();
  }

  public problemLink(problem: MindMapProblem): string {
    return this.stateRouter.makePath(STATE.PROBLEMS, problem.id);
  }

  public solutionLink(solution: MindMapSolution): string {
    return this.stateRouter.makePath(STATE.SOLUTIONS, solution.id);
  }
}
