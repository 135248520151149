import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoginModule} from './login/login.module';
import {SignupModule} from './signup/signup.module';
import {SessionsModule} from './sessions/sessions.module';
import {AuthModule} from 'src/auth/auth.module';
import {EnvironmentModule} from 'src/environments/environment.module';
import {HttpClientModule} from "@angular/common/http";
import {RevogoClientModule} from "../revogo-client/revogo-client.module";
import {ProblemsModule} from "./problems/problems.module";
import {SolutionsModule} from "./solutions/solutions.module";
import {ArgumentsModule} from "./arguments/arguments.module";
import {ModalsModule} from "../modals/modals.module";
import {HeaderModule} from "./header/header.module";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    HeaderModule,
    HttpClientModule,
    RevogoClientModule,
    EnvironmentModule,
    AuthModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LoginModule,
    SignupModule,
    SessionsModule,
    ProblemsModule,
    SolutionsModule,
    ArgumentsModule,
    ModalsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
