import {Component, Input, OnInit} from "@angular/core";
import {QcmSession, Session, SessionState, SessionType} from "../../../revogo-client/revogo-client.types";

@Component({
  selector: 'app-qcm-session-card',
  templateUrl: './qcm-session-card.component.html',
  styleUrls: ['./qcm-session-card.component.sass']
})
export class QcmSessionCard implements OnInit {
  @Input("session")
  public _session!: Session;
  public session!: QcmSession;

  @Input()
  public editable!: boolean;

  public sessionState = SessionState;
  public validated!: boolean;

  ngOnInit() {
    this.session = this._session as QcmSession;
  }

  public isValidQcm(): boolean {
    const baseSchemaIsValid = (
      this.session.sessionType === 'QCM' &&
      this.session.sessionDefinition &&
      this.session.sessionDefinition.propositions !== undefined &&
      this.session.sessionDefinition.propositions.length !== undefined
    );
    if (!baseSchemaIsValid) {
      return false;
    } else {
      const propositionsIntegrity = this.session.sessionDefinition.propositions.map(
        proposition =>
          proposition.allowedAnswers !== undefined &&
          proposition.allowedAnswers.length > 0 &&
          proposition.title !== undefined
      );
      return propositionsIntegrity.indexOf(false) === -1;
    }
  }

}
