import {NgModule} from "@angular/core";
import {HeaderComponent} from "./header.component";
import {MatDividerModule} from "@angular/material/divider";
import {MatRippleModule} from "@angular/material/core";
import {MatIconModule} from "@angular/material/icon";
import {RouterModule} from "@angular/router";
import {MatMenuModule} from "@angular/material/menu";
import {CommonModule} from "@angular/common";

@NgModule({
  imports: [
    MatDividerModule,
    MatRippleModule,
    MatIconModule,
    RouterModule,
    MatMenuModule,
    CommonModule
  ],
  declarations: [HeaderComponent],
  exports: [HeaderComponent]
}) export class HeaderModule {}
