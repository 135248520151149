import {NgModule} from "@angular/core";
import {RouterModule} from '@angular/router';

import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {STATE} from "../app-states";
import {OrganizationsComponent} from "./organizations.component";
import {AuthorizedOrganizationsResolve} from "../../auth/organization.resolvers";
import {CommonModule} from "@angular/common";
import {UserResolve} from "../../auth/auth.resolvers";


export const ORGANIZATION_SELECT_STATE = {
  path: STATE.ORGANIZATION_SELECT,
  component: OrganizationsComponent,
  resolve: {
    authorizedOrganizations: AuthorizedOrganizationsResolve,
    userData: UserResolve,
  },
};

@NgModule({
  declarations: [
    OrganizationsComponent,
  ],
  imports: [
    RouterModule.forChild([ORGANIZATION_SELECT_STATE]),
    CommonModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
  ],
  providers: [AuthorizedOrganizationsResolve, UserResolve],
})
export class OrganizationsModule {
}
