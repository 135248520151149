import {ActivatedRouteSnapshot, Resolve, Router} from "@angular/router";
import {Injectable} from "@angular/core";
import {OrganizationService} from "./organization.service";
import {Organization} from "../revogo-client/revogo-client.types";


@Injectable()
export class AuthorizedOrganizationsResolve implements Resolve<Organization[]> {
  constructor (private organizationService: OrganizationService,
               private router: Router) {}

  async resolve(route: ActivatedRouteSnapshot) {
    return this.organizationService.getAuthorizedOrganizations().toPromise().then(
      async organizations => {
        const selectedOrganization = await this.organizationService.getSelectedOrganization().toPromise();
        if (selectedOrganization !== null && organizations.find(org => org.organizationId === selectedOrganization.organizationId) !== undefined) {
          await this.router.navigate(['/sessions/list']);
          return organizations;
        } else {
          return organizations;
        }
      }
    ).catch(
      (err) => {
          if (err.status !== undefined && err.status === 403) {
            console.log(err); return []
          } else {
            throw err;
          }
        }
      );
  }
}

@Injectable()
export class SelectedOrganizationResolve implements Resolve<Organization> {
  constructor (private organizationService: OrganizationService) {}

  async resolve(route: ActivatedRouteSnapshot) {
    console.log('In selected organization resolver');
    const organization = await this.organizationService.getSelectedOrganization().toPromise();
    if (organization === null) {
      throw new Error("No organization selected");
    } else {
      return organization;
    }
  }
}
