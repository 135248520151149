import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from "../../auth/auth.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {STATE} from "../app-states";
import {Router} from "@angular/router";
import {validatePasswordRequirements} from "./password-validator";

const AmazonCognitoIdentity = require('amazon-cognito-identity-js');

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.sass']
})
export class SignupComponent implements OnInit {
  public hide: boolean = true;
  public form!: FormGroup;
  public loading: boolean = false;

  constructor(private authService: AuthService,
              private snackbar: MatSnackBar,
              private router: Router) {

  }

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl('', Validators.email),
      password: new FormControl('', validatePasswordRequirements),
      firstName: new FormControl('', Validators.required),
      familyName: new FormControl('', Validators.required),
    });
  }

  public signUp() {
    const email: string = this.form.controls.email.value;
    const password = this.form.controls.password.value;
    const firstName = this.form.controls.firstName.value;
    const familyName = this.form.controls.familyName.value;

    const attributes = [
      new AmazonCognitoIdentity.CognitoUserAttribute({Name: 'email', Value: email}),
      new AmazonCognitoIdentity.CognitoUserAttribute({Name: 'given_name', Value: firstName}),
      new AmazonCognitoIdentity.CognitoUserAttribute({Name: 'family_name', Value: familyName}),
    ];

    this.authService.userPool.signUp(email, password, attributes, [], (err, result) => {
      if (err) {
        if (err.name === 'UsernameExistsException') {
          this.snackbar.open('Un compte avec cet email existe déja. Essayez plutôt de vous connectez.', undefined, {panelClass: ['error-snackbar'], duration: 10000});
        } else {
          console.log(err);
          this.snackbar.open(`Nous n'avons pas pu valider votre inscription: ${err.message}`, undefined, {panelClass: ['error-snackbar'], duration: 10000});
        }
      } else if (result) {
        const cognitoUser = result.user;
        console.log(cognitoUser);
        this.router.navigate(['/', STATE.CONFIRM_REGISTRATION], {queryParams: {u: email}});
      }
    });
  }
}
