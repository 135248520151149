import {NgModule} from "@angular/core";
import {MatButtonModule} from "@angular/material/button";
import {MatDialogModule} from "@angular/material/dialog";
import {CreateProblemModalComponent} from "./create-problem-modal.component";
import {CommonModule} from "@angular/common";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CreateSolutionModalComponent} from "./create-solution-modal.component";
import {CreateArgumentModalComponent} from "./create-argument-modal.component";
import {ArgumentCardComponent} from "./argument-card.component";
import {RouterModule} from "@angular/router";
import {MatDividerModule} from "@angular/material/divider";
import {MatRippleModule} from "@angular/material/core";
import {MatIconModule} from "@angular/material/icon";
import {ArgumentExplorerComponent} from "./argument-explorer.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {CreateSessionFromNodeModalComponent} from "./create-session-from-node-modal.component";
import {MatSelectModule} from "@angular/material/select";
import {SessionsModule} from "../sessions/sessions.module";

@NgModule({
  declarations: [
    CreateProblemModalComponent,
    CreateSolutionModalComponent,
    CreateArgumentModalComponent,
    ArgumentCardComponent,
    ArgumentExplorerComponent,
    CreateSessionFromNodeModalComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MatDividerModule,
    MatRippleModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSelectModule,
  ],
  exports: [
    ArgumentCardComponent,
    ArgumentExplorerComponent,
    CreateSessionFromNodeModalComponent
  ],
  providers: []
})
export class MindMapModule {}
