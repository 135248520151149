import {Component, Input, OnInit} from "@angular/core";
import {SessionType} from "../../revogo-client/revogo-client.types";


export const sessionTypeDisplayName = {
  [SessionType.QCM]: 'QCM',
  [SessionType.ProblemAnalysis]: "Approfondir un problème",
  [SessionType.SolutionGeneration]: "Chercher des solutions",
};


@Component({
  selector: 'app-session-type',
  template: `{{displayName}}`,
})
export class SessionTypeComponent implements OnInit {
  @Input()
  public type!: SessionType;
  public displayName!: string;

  ngOnInit() {
    this.displayName = sessionTypeDisplayName[this.type];
  }
}
