import { NgModule } from "@angular/core";
import { LoginComponent } from "./login.component";
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { CommonModule } from "@angular/common";
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { STATE } from "src/app/app-states";
import {MatIconModule} from "@angular/material/icon";
import {RouterModule} from "@angular/router";

export const LOGIN_STATE = {
  path: STATE.LOGIN,
  component: LoginComponent
};

@NgModule({
  declarations: [
    LoginComponent,
  ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        RouterModule,
    ],
  providers: [],
})
export class LoginModule {}
