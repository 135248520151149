import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {RevogoClientService} from "../../../revogo-client/revogo-client.service";
import {MindMapProblem, MindMapSolution, Session} from "../../../revogo-client/revogo-client.types";
import {MatDialog} from "@angular/material/dialog";
import {StateRouterService} from "../../../state-router/state-router.service";
import {Location} from '@angular/common';
import {OrganizationService} from "../../../auth/organization.service";

export type SessionMindMapEntities = {
  problems: MindMapProblem[],
  solutions: MindMapSolution[]
};

@Component({
  selector: 'app-session-details',
  templateUrl: './session-details.component.html',
  styleUrls: ['./session-details.component.sass']
})
export class SessionDetailsComponent implements OnInit {
  private sessionId!: string;
  public session: Session;
  private dirty: boolean = false;
  public canWrite!: boolean;

  constructor(private route: ActivatedRoute,
              private revogoClient: RevogoClientService,
              private organizationService: OrganizationService,
              private dialog: MatDialog,
              private stateRouter: StateRouterService,
              private location: Location) {
    this.sessionId = this.route.snapshot.params.sessionId;
    this.session = this.route.snapshot.data.session;
  }

  async ngOnInit() {
    this.canWrite = await this.organizationService.userHasRole(['manager']);
  }

  public setDirty(value: boolean) {
    this.dirty = value;
  }

  public confirmQuit() {
    if (this.dirty) {
      const ref = this.dialog.open(SaveChangesDialogComponent);
      ref.afterClosed().subscribe(res => {
        if (res === false) {
          this.location.back();
        }
      });
    } else {
      this.location.back();
    }
  }
}


@Component({
  selector: 'app-save-changes-dialog-component',
  template: `
    <h2 mat-dialog-title>Êtes-vous sûr(e) de vouloir quitter cette page ?</h2>
    <mat-dialog-content class="mat-typography">
      <p>Vos changements n'ont pas été enregistrés.</p>
      <p>Vous perdrez ces modifications si vous quittez sans sauvegarder.</p>
    </mat-dialog-content>
    <mat-dialog-actions class="flex">
      <button mat-stroked-button [mat-dialog-close]="false">Ignorer</button>
      <div class="flex-grow"></div>
      <button mat-raised-button color="primary" [mat-dialog-close]="true">Annuler</button>
    </mat-dialog-actions>
  `
})
export class SaveChangesDialogComponent {
}
