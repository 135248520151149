import {Injectable} from "@angular/core";
import {RevogoClientService} from "./revogo-client.service";
import {QcmSession, QcmSessionEntity, QcmSessionResults, SessionType} from "./revogo-client.types";

@Injectable({
  providedIn: 'root'
})
export class QcmSessionResultsService {
  constructor(private revogoClient: RevogoClientService) {
  }

  public async getSessionResults(sessionId: string): Promise<QcmSessionResults> {
    const session = await this.revogoClient.getSession(sessionId).toPromise() as QcmSession;
    if (session.sessionType !== SessionType.QCM) {
      throw new Error(`QCM sessions results service cannot deal with session of type '${session.sessionType}'`);
    }

    const entities = await this.revogoClient.getAllSessionEntities(sessionId).toPromise() as QcmSessionEntity[];

    // Shaping results based on session definition
    const aggregate: QcmSessionResults = {
      participantCount: 0,
      propositions: session.sessionDefinition.propositions.map(proposition => {
        return {
          participantCount: 0,
          answers: proposition.allowedAnswers.map(answer => ({
            count: 0,
            text: {},
          })),
        }
      })
    };

    const allParticipants: { [key: string]: boolean } = {};
    const participantsByProposition: { [key: number]: {[key: string]: boolean}} = {};

    // Storing answers and participants
    entities.forEach(entity => {
      const owner = entity.ownerReference;
      entity.entityData.answers.forEach(({index: answerIndex, content: freeText}, propositionIndex) => {
        allParticipants[owner] = true;
        if (participantsByProposition[propositionIndex] === undefined) {
          participantsByProposition[propositionIndex] = {};
        }
        participantsByProposition[propositionIndex][owner] = true;
        aggregate.propositions[propositionIndex].answers[answerIndex].count += 1;
        if (freeText !== undefined) {
          if ( aggregate.propositions[propositionIndex].answers[answerIndex].text[freeText] === undefined) {
            aggregate.propositions[propositionIndex].answers[answerIndex].text[freeText] = 1;
          } else {
            aggregate.propositions[propositionIndex].answers[answerIndex].text[freeText] += 1;
          }
        }
      });
    });

    // Computing participant counts
    aggregate.participantCount = Object.keys(allParticipants).length;
    aggregate.propositions.forEach((prop, index) => {
      aggregate.propositions[index].participantCount = Object.keys(participantsByProposition[index] || {}).length;
    });

    return aggregate;
  }
}
