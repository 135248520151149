import {AuthService} from "../../auth/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Component, OnInit} from "@angular/core";
import {STATE} from "../app-states";
import {MatSnackBar} from "@angular/material/snack-bar";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {validatePasswordRequirements} from "./password-validator";

const AmazonCognitoIdentity = require('amazon-cognito-identity-js');

enum ResetPasswordFlowState {
  Info,
  CodeSent,
  Finished,
}

@Component({
  selector: 'app-forgot-password',
  template: `
    <div class="body-container">

      <div *ngIf="flowState === flowStates.Info" class="info-container text-center">
        <div>
          Vous vous apprêtez à demander une réinitialisation du mot de passe pour le compte associé à l'addresse email :
        </div>
        <div class="font-bold">{{email}}</div>
        <div class="my-2">Cliquez sur continuer pour recevoir un code de confirmation</div>
        <div class="my-2">
          <button *ngIf="!spinning" mat-raised-button color="primary" (click)="startFlow()">Continuer</button>
          <div *ngIf="spinning" class="flex">
            <div class="flex-grow"></div>
            <mat-spinner></mat-spinner>
            <div class="flex-grow"></div>
          </div>
        </div>
      </div>

      <div *ngIf="flowState === flowStates.CodeSent" class="info-container text-center">
        <div>
          Veuillez saisir le code de sécurité reçu ainsi que votre nouveau mot de passe.
        </div>
        <div>Votre mot de passe doit comporter au moins 8 caractères et contenir au moins un chiffre.</div>
        <div class="my-2">
          <form [formGroup]="form" (submit)="confirmPassword()">
            <div class="m-1 w-full">
              <mat-form-field class="w-full">
                <mat-label>Code de sécurité</mat-label>
                <input class="w-full" matInput type="text" formControlName="confirmationCode" required>
              </mat-form-field>
            </div>
            <div class="m-1 w-full">
              <mat-form-field class="w-full">
                <mat-label>Nouveau mot de passe</mat-label>
                <input class="w-=full" matInput type="password" formControlName="newPassword" required>
              </mat-form-field>
            </div>

            <div class="my-2">
              <button *ngIf="!spinning" mat-raised-button color="primary" type="submit" [disabled]="!form.valid">Continuer</button>
              <div *ngIf="spinning" class="flex">
                <div class="flex-grow"></div>
                <mat-spinner></mat-spinner>
                <div class="flex-grow"></div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div *ngIf="flowState === flowStates.Finished" class="info-container text-center">
        Votre mot de passe a bien été modifié. Vous allez être redirigé vers la page de connexion dans quelques secondes.
      </div>

    </div>
  `,
  styleUrls: ['./forgot-password.component.sass']
})
export class ForgotPasswordComponent implements OnInit {
  public email!: string;
  public readonly flowStates = ResetPasswordFlowState;
  public flowState!: ResetPasswordFlowState;
  public spinning = false;
  public confirmationCode = '';
  public form!: FormGroup;
  private cognitoUser!: typeof AmazonCognitoIdentity.CognitoUser;

  constructor(private authService: AuthService,
              private router: Router,
              private route: ActivatedRoute,
              private snackbar: MatSnackBar) {

  }

  ngOnInit() {
    this.flowState = ResetPasswordFlowState.Info;
    this.email = this.route.snapshot.queryParams['u'];
    if (this.email === undefined) {
      this.router.navigate(['/', STATE.LOGIN]);
    }
    this.cognitoUser = new AmazonCognitoIdentity.CognitoUser({
      Username: this.email,
      Pool: this.authService.userPool,
    });
    this.form = new FormGroup({
      confirmationCode: new FormControl('', Validators.required),
      newPassword: new FormControl('', validatePasswordRequirements)
    });
  }

  public startFlow() {
    this.spinning = true;
    this.cognitoUser.forgotPassword({
      onSuccess: (data: any) => {
        this.spinning = false;
        this.flowState = ResetPasswordFlowState.CodeSent;
        console.log(data);
      },
      onFailure: (error: any) => {
        this.spinning = false;
        this.snackbar.open(`Erreur "${error.name}" : ${error.message}`, undefined, {panelClass: ['error-snackbar'], duration: 10000});
      }
    });
  }

  public confirmPassword() {
    this.spinning = true;
    this.cognitoUser.confirmPassword(this.form.controls.confirmationCode.value, this.form.controls.newPassword.value, {
      onSuccess: () => {
        this.spinning = false;
        this.flowState = ResetPasswordFlowState.Finished;
        setTimeout(() => {this.router.navigate(['/', STATE.LOGIN]);}, 5000);
      },
      onFailure: (error: any) => {
        this.spinning = false;
        console.log(error);
        if (error.name === 'CodeMismatchException') {
          this.snackbar.open('Code de sécurité incorrect.', undefined, {panelClass: ['error-snackbar'], duration: 5000});
        } else {
          this.snackbar.open(`Erreur "${error.name}" : ${error.message}`, undefined, {panelClass: ['error-snackbar'], duration: 10000});
        }
      },
    })
  }

}
