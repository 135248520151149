import {Component, Input, OnInit} from "@angular/core";
import {ProblemAnalysisSession, Session} from "../../../revogo-client/revogo-client.types";
import {Subject} from "rxjs";
import {LiveThreadService} from "../../../live-thread/live-thread.service";
import {RevogoClientService} from "../../../revogo-client/revogo-client.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {PostThreadMessageModalComponent} from "./post-thread-message-modal.component";
import {
  ThreadContextActionEvent,
  ThreadEvent,
  ThreadEventKind,
  ThreadReplyEvent,
  ThreadVoteEvent
} from "../../message-thread/thread.event";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../auth/auth.service";
import {EditThreadMessageModalComponent} from "./edit-thread-message-modal.component";
import {DeleteThreadMessageModalComponent} from "./delete-thread-message-modal.component";
import {MessageContextButtonDefinition} from "../../message-thread/thread-context-actions";
import {BanThreadUserModalComponent} from "./ban-thread-user-modal.component";
import {MatSelectChange} from "@angular/material/select";

type ThreadSession = ProblemAnalysisSession;

@Component({
  selector: 'app-play-thread',
  template: `
    <app-thread-view [session]="session"
                     [contextButtonDefinition]="messageContextActionsButtonsDefinition"
                     [syncDebounceChannel]="syncDebounceChannel"
                     (event)="processThreadEvent($event)"
    ></app-thread-view>
  `
})
export class PlayThreadComponent implements OnInit {
  @Input("session")
  public _session!: Session;
  public session!: ThreadSession;
  public syncDebounceChannel = new Subject<boolean>();

  public readonly messageContextActionsButtonsDefinition: MessageContextButtonDefinition[] = [
    {
      icon: 'delete',
      eventKey: 'delete',
      label: 'Supprimer',
      displayCondition: (node, service) => ((node.isMine || service.amSage()) && node.children.length === 0),
    },
    {
      icon: 'edit',
      eventKey: 'edit',
      label: 'Modifier',
      displayCondition: (node, service) => (node.isMine || service.amSage()),
    },
    {
      icon: 'no_accounts',
      eventKey: 'banUser',
      label: 'Bannir',
      displayCondition: (node, service) => (node.ownerId.startsWith('guest') && service.amSage())
    }
  ];

  constructor(
              private liveThreadService: LiveThreadService,
              private authService: AuthService,
              private revogoClient: RevogoClientService,
              private snackbar: MatSnackBar,
              private dialog: MatDialog,
              private route: ActivatedRoute,
              private router: Router) {
  }

  async ngOnInit() {
    this.session = this._session as ThreadSession;
  }

  public synchronize(debounce: boolean = true) {
    this.syncDebounceChannel.next(debounce);
  }

  public async postMessage(message: string, parentId: string | undefined = undefined) {
    const body: { [key: string]: any } = {
      entity_data: {content: message}
    };
    if (parentId !== undefined) {
      body.entity_data.parent_message_id = parentId;
    }
    this.liveThreadService.synchronousAddMessage(message, parentId, undefined);
    const entity = await this.revogoClient.createSessionEntity(this.session.sessionId, body).toPromise().catch(error => {
      this.liveThreadService.resetAddedMessage();
      this.snackbar.open(`Nous n'avons pas pu publier votre message: ${error}`, undefined, {
        duration: 5000,
        panelClass: ['error-snackbar']
      });
      return undefined;
    });
    if (entity !== undefined) {
      this.snackbar.open('Message publié avec succès', undefined, {duration: 2000});
      await new Promise(r => setTimeout(r, 1000));
      await this.doVote({kind: ThreadEventKind.Vote, nodeId: entity.entityId, sign: +1}, false);
    }
  }

  public async editMessage(nodeId: string, message: string) {
    const body = {entityData: {content: message}};
    await this.revogoClient.updateSessionEntity(this.session.sessionId, nodeId, body).toPromise().catch(error => {
      this.snackbar.open("Nous n'avons pas pu publier votre message. Vous pouvez réessayer.", undefined, {panelClass: ['error-snackbar'], duration: 5000});
    }).then(
      async res => {
        this.snackbar.open("Message édité avec succès", undefined, {duration: 2000});
        await this.synchronize();
      }
    );
  }


  public postMessageModal(parentId: string | undefined = undefined) {
    const ref = this.dialog.open(PostThreadMessageModalComponent, {panelClass: 'wide-modal'});
    ref.afterClosed().subscribe(
      async result => {
        if (result) {
          await this.postMessage(result, parentId);
        }
      }
    )
  }

  public editMessageModal(nodeId: string, currentContent: string) {
    const ref = this.dialog.open(EditThreadMessageModalComponent, {panelClass: 'wide-modal'});
    ref.componentInstance.message = currentContent;
    ref.afterClosed().subscribe(
      async result => {
        if (result) {
          await this.editMessage(nodeId, result);
        }
      }
    )
  }


  private async deleteMessage(nodeId: string) {
    await this.revogoClient.deleteSessionEntity(this.session.sessionId, nodeId).toPromise().then(() => {
        this.snackbar.open('Message supprimé', undefined, {duration: 2000});
        this.synchronize();
      }
    ).catch(
      err => {
        this.snackbar.open('Impossible de supprimer votre message.', undefined, {duration: 5000, panelClass: ['error-snackbar']});
      }
    );
  }


  public deleteMessageModal(nodeId: string) {
    const ref = this.dialog.open(DeleteThreadMessageModalComponent, {panelClass: 'wide-modal'});
    ref.afterClosed().subscribe(
      async result => {
        if (result) {
          await this.deleteMessage(nodeId);
        }
      }
    );
  }


  public banUserModal(userId: string) {
    const ref = this.dialog.open(BanThreadUserModalComponent, {panelClass: 'wide-modal'});
    ref.afterClosed().subscribe(
      async result => {
        if (result) {
          await this.revogoClient.banSessionUser(this.session.sessionId, userId, result.deleteEntities).toPromise();
          this.synchronize(false);
          this.snackbar.open("L'utilisateur a été banni.", undefined, {duration: 5000});
        }
      }
    )
  }


  public async doVote(event: ThreadVoteEvent, debounce: boolean = true) {
    this.liveThreadService.setVote(event.nodeId, event.sign);
    await this.revogoClient.sessionEntityVote(this.session.sessionId, event.nodeId, event.sign).toPromise().catch(
      err => this.snackbar.open(`Erreur lors de l'enrigstrement de votre vote. Vous pouvez réessayer.`, undefined, {panelClass: ['error-snackbar'], duration: 5000})
    );
    await this.synchronize(debounce);
  }


  public async processThreadEvent(event: ThreadEvent) {
    switch (event.kind) {
      case ThreadEventKind.Reply:
        const replyEvent = event as ThreadReplyEvent;
        this.postMessageModal(replyEvent.parentNodeId);
        break;
      case ThreadEventKind.Vote:
        const voteEvent = event as ThreadVoteEvent;
        await this.doVote(voteEvent);
        break
      case ThreadEventKind.UnspecifiedContextAction:
        const contextEvent = event as ThreadContextActionEvent;
        switch (contextEvent.specificEventKey) {
          case 'delete':
            this.deleteMessageModal(contextEvent.node.id);
            break
          case 'edit':
            this.editMessageModal(contextEvent.node.id, contextEvent.node.message);
            break;
          case 'banUser':
            this.banUserModal(contextEvent.node.ownerId);
        }
        break;
    }
  }

  public setSort($event: MatSelectChange) {
    this.liveThreadService.setSort($event.value);
  }
}
