import {Component, OnInit} from '@angular/core';
import {MindMapProblem, MindMapSolution, Session} from "../../../revogo-client/revogo-client.types";
import {ActivatedRoute, Router} from "@angular/router";
import {STATE} from "../../app-states";
import {RevogoClientService} from "../../../revogo-client/revogo-client.service";
import {OrganizationService} from "../../../auth/organization.service";
import {forkJoin, Observable} from "rxjs";
import {map, shareReplay} from "rxjs/operators";

export type MindMapRepository = {
  problems: { [key: string]: MindMapProblem },
  solutions: { [key: string]: MindMapSolution },
};

@Component({
  selector: 'app-session-list',
  templateUrl: './session-list.component.html',
  styleUrls: ['./session-list.component.sass']
})
export class SessionListComponent implements OnInit {
  public sessions!: Session[];
  public state = STATE;
  public mindMapRepository!: Observable<MindMapRepository>;
  public organizationId!: string;
  public canWrite!: boolean;

  constructor(
    private route: ActivatedRoute,
    private revogoClient: RevogoClientService,
    private organizationService: OrganizationService,
  ) {
    this.sessions = this.route.snapshot.data.sessions;
    this.sessions = this.sessions.sort((a, b) => {
      const getValue = (s: Session) => isNaN(s.creationDate.getTime()) ? 0 : Date.parse(s.creationDate.toISOString());
      return getValue(b) - getValue(a);
    });
  }

  async ngOnInit() {
    const organization = await this.organizationService.getSelectedOrganization().toPromise();
    if (organization !== null) {
      this.organizationId = organization.organizationId;

      const reducer = (list: { id: string }[]) => list.reduce((acc: { [key: string]: any }, obj) => {
        acc[obj.id] = obj;
        return acc;
      }, {});

      this.mindMapRepository = forkJoin({
        problems: this.revogoClient.getMindMapOrganizationProblems(this.organizationId).pipe(map(reducer)),
        solutions: this.revogoClient.getMindMapOrganizationSolutions(this.organizationId).pipe(map(reducer))
      }).pipe(shareReplay(1));
      this.canWrite = await this.organizationService.userHasRole(['manager']);
    }
  }
}
