import {NgModule} from "@angular/core";
import {RouterModule} from "@angular/router";
import {STATE} from "../app-states";
import {SessionResolve} from "../../revogo-client/revogo-client.resolvers";
import {PlayComponent} from "./play.component";
import {CommonModule} from "@angular/common";
import {PlayQcmComponent} from "./qcm/play-qcm.component";
import {MatButtonModule} from "@angular/material/button";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {PlayEndComponent} from "./play-end.component";
import {MatInputModule} from "@angular/material/input";
import {MatFormFieldModule} from "@angular/material/form-field";
import {PlayUnauthorizedComponent} from "./play-unauthorized.component";
import {PlayThreadComponent} from "./thread/play-thread.component";
import {MatIconModule} from "@angular/material/icon";
import {MatDividerModule} from "@angular/material/divider";
import {MessageThreadModule} from "../message-thread/message-thread.module";
import {PostThreadMessageModalComponent} from "./thread/post-thread-message-modal.component";
import {MatDialogModule} from "@angular/material/dialog";
import {MatTooltipModule} from "@angular/material/tooltip";
import {EditThreadMessageModalComponent} from "./thread/edit-thread-message-modal.component";
import {DeleteThreadMessageModalComponent} from "./thread/delete-thread-message-modal.component";
import {BanThreadUserModalComponent} from "./thread/ban-thread-user-modal.component";
import {MatCheckboxModule} from '@angular/material/checkbox';
import {ThreadHelpModalComponent} from "../message-thread/thread-help-modal.component";
import {MatSelectModule} from "@angular/material/select";
import {
  EditSolutionGenerationMessageModalComponent,
  PlaySolutionGenerationThread,
  PostSolutionGenerationMessageModalComponent
} from "./thread/play-solution-generation-thread.component";

export const PLAY_END_STATE = {
  path: STATE.PLAY_END,
  component: PlayEndComponent
};

export const PLAY_SESSION_STATE = {
  path: ':sessionId',
  component: PlayComponent,
  resolve: {session: SessionResolve},
}

export const PLAY_UNAUTHORIZED_STATE = {
  path: STATE.PLAY_UNAUTHORIZED,
  component: PlayUnauthorizedComponent,
}

export const PLAY_STATE = {
  path: STATE.PLAY,
  children: [
    PLAY_UNAUTHORIZED_STATE,
    PLAY_END_STATE,
    PLAY_SESSION_STATE,
  ]
};

@NgModule({
  declarations: [
    PlayQcmComponent,
    PlayThreadComponent,
    PlayComponent,
    PlayEndComponent,
    PlayUnauthorizedComponent,
    PostThreadMessageModalComponent,
    EditThreadMessageModalComponent,
    DeleteThreadMessageModalComponent,
    BanThreadUserModalComponent,
    ThreadHelpModalComponent,
    PlaySolutionGenerationThread,
    PostSolutionGenerationMessageModalComponent,
    EditSolutionGenerationMessageModalComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatInputModule,
        RouterModule.forChild([PLAY_STATE]),
        MatIconModule,
        MatDividerModule,
        MessageThreadModule,
        MatDialogModule,
        MatTooltipModule,
        MatCheckboxModule,
        MatSelectModule,
    ],
  providers: [],
})
export class PlayModule {

}
