import {Component, OnInit} from "@angular/core";
import {MindMapProblem} from "../../revogo-client/revogo-client.types";
import {OrganizationService} from "../../auth/organization.service";
import {STATE} from "../app-states";
import {StateRouterService} from "../../state-router/state-router.service";
import {Subject} from "rxjs";
import {RevogoClientService} from "../../revogo-client/revogo-client.service";
import {map, tap} from "rxjs/operators";
import {CreateProblemModalComponent} from "../mind-map/create-problem-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import { MatButtonToggleChange } from "@angular/material/button-toggle/button-toggle";


enum ProblemFilteringMode {
  All = 'all',
  Unresolved = 'unresolved',
  Resolved = 'resolved'
}

@Component({
  selector: 'app-problems-list',
  templateUrl: './problems-list.component.html',
  styleUrls: ['./problems-list.component.sass']
})
export class ProblemsListComponent implements OnInit {
  public problems!: MindMapProblem[];
  private problemsSubject = new Subject<MindMapProblem[]>();

  private organizationId!: string;

  public currentFilter: ProblemFilteringMode = ProblemFilteringMode.All;
  public loading: boolean = true;
  public sessionNames!: { [key: string]: string };
  public canWrite!: boolean;

  constructor(private organizationService: OrganizationService,
              private stateRouter: StateRouterService,
              private revogoClient: RevogoClientService,
              private dialog: MatDialog,
              private snackbar: MatSnackBar) {
  }

  private static problemSort(problems: MindMapProblem[]) {
    return problems.sort((a, b) => (b.creationDate.getTime() - a.creationDate.getTime()));
  }

  private setPipe() {

    const filterFactory = (filter: (problem:MindMapProblem) => boolean) => {
      return (problems: MindMapProblem[]) => problems.filter(filter);
    }

    const filters = {
      [ProblemFilteringMode.All.valueOf()]: filterFactory(() => true),
      [ProblemFilteringMode.Unresolved.valueOf()]: filterFactory(problem => problem.resolutionDate === null),
      [ProblemFilteringMode.Resolved.valueOf()]: filterFactory(problem => problem.resolutionDate !== null),
    };
    this.problemsSubject.pipe(map(filters[this.currentFilter])).pipe(map(ProblemsListComponent.problemSort)).subscribe(
      problems => {
        this.problems = problems;
        this.loading = false;
      });
  }

  private async updateProblems() {
    const problems = await this.revogoClient.getMindMapOrganizationProblems(this.organizationId).toPromise();
    this.problemsSubject.next(problems);
  }

  private async updateSessionNames() {
    const sessions = await this.revogoClient.getSessions(this.organizationId).toPromise();
    this.sessionNames = sessions.reduce((previous: { [key: string]: string }, current) => {
      previous[current.sessionId] = current.sessionName;
      return previous;
    }, {});
  }

  async ngOnInit() {
    this.canWrite = await this.organizationService.userHasRole(['manager']);
    this.setPipe();
    const organization = await this.organizationService.getSelectedOrganization().toPromise();
    if (organization === null) {
      this.stateRouter.nav(STATE.ORGANIZATION_SELECT);
    } else {
      this.organizationId = organization.organizationId;
      await Promise.all([this.updateProblems(), this.updateSessionNames()]);
    }
  }

  public newProblemModal() {
    const dialogRef = this.dialog.open(CreateProblemModalComponent, {panelClass: 'wide-modal'});
    dialogRef.afterClosed().subscribe(async result => {
      if (result !== null) {
        await this.revogoClient.createMindMapProblem(this.organizationId, {
          ...result,
        }).toPromise();
        await this.updateProblems();
        this.snackbar.open('Ce problème a bien été crée', undefined, {
          duration: 3000
        });
      }
    });
  }

  public async setFilter(e: MatButtonToggleChange) {
    this.currentFilter = e.value;
    this.problems = [];
    this.loading = true;
    this.setPipe();
    await this.updateProblems();
  }
}
