<div class="mx-4 mt-4">
  <div class="flex primary-text text-lg p-4">
    <div class="mt-4">
      Problèmes identifiés&nbsp;
      <span *ngIf="problems !== undefined">({{problems.length}})</span>
    </div>
    <div class="flex-grow"></div>
    <div *ngIf="canWrite">
      <button mat-raised-button color="primary" (click)="newProblemModal()">Nouveau problème</button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="flex">
    <div class="flex-grow"></div>
    <div class="max-w-screen-md flex-grow">
      <div class="m-4">
        <mat-button-toggle-group [value]="currentFilter" (change)="setFilter($event)">
          <mat-button-toggle value="all">Tous</mat-button-toggle>
          <mat-button-toggle value="resolved">Résolus</mat-button-toggle>
          <mat-button-toggle value="unresolved">Non résolus</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div *ngIf="loading" class="flex mt-8">
        <div class="flex-grow"></div>
        <mat-spinner></mat-spinner>
        <div class="flex-grow"></div>
      </div>
      <div class="mt-4" *ngIf="problems !== undefined && problems.length > 0; else noProblems;">
        <div class="">
          <div class="m-4 border bg-yellow-50 border-yellow-500 rounded-sm shadow-md" *ngFor="let problem of problems; let index = index">
            <div class="text-lg primary-text m-4 flex cursor-pointer" mat-ripple [routerLink]="'../'+problem.id">
              <div class="underline">
                {{problem.title}}
              </div>
              <div class="flex-grow"></div>
              <div>
                <span *ngIf="problem.resolutionDate === null; else solved;" class="text-lg error-text">Non résolu</span>
                <ng-template #solved>
                  <span class="text-lg primary-text">Résolu</span>
                </ng-template>
              </div>
            </div>
            <div *ngIf="problem.description" class="ml-4">
              <p class="leading-tight" *ngFor="let line of problem.description.split('\n')">
                {{line}}
              </p>
              <p class="text-gray-400 italic">{{problem.creationDate | date:'dd/MM/YYYY'}}</p>
            </div>
            <div *ngIf="problem.sessionId && sessionNames" class="w-full text-md bg-purple-100 p-2 cursor-pointer" mat-ripple [routerLink]="'/sessions/' + problem.sessionId + '/results/'">
              Identifié lors de la session : <span class="primary-text">{{sessionNames[problem.sessionId]}}</span>
            </div>
          </div>
        </div>
      </div>
      <ng-template #noProblems>
        <div class="primary-text" *ngIf="!loading">Aucun problème à afficher</div>
      </ng-template>
    </div>
    <div class="flex-grow"></div>
  </div>
</div>


