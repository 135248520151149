import {AbstractControl} from "@angular/forms";

export function validatePasswordRequirements(control: AbstractControl): { [key: string]: any } | null {
  let errors: { [key: string]: any } = {};
  let nErrors = 0;
  if (control.value.length < 8) {
    nErrors += 1;
    errors['passwordLength'] = 'Le mot de passe doit comporter au moins 8 caractères'
  }
  if (!/\d/.test(control.value)) {
    nErrors += 1;
    errors['digits'] = 'Le mot de passe doit comporter au moins un chiffre';
  }

  if (nErrors > 0) {
    return errors;
  } else {
    return null;
  }
}
