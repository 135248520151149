import { Component } from '@angular/core';
import {Router} from "@angular/router";


@Component({
  selector: 'app-sessions',
  template: `
    <div class="container">
      <app-header></app-header>
      <div id="app-content-container">
        <router-outlet></router-outlet>
      </div>
    </div>
  `,
  styleUrls: ['./sessions.component.sass']
})
export class SessionsComponent {

}
