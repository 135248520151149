import {Component} from "@angular/core";


@Component({
  selector: 'app-solutions',
  template: `
    <div class="container">
      <app-header></app-header>
      <div class="app-content-container">
        <router-outlet></router-outlet>
      </div>
    </div>
  `,
  styleUrls: ['./solutions.component.sass']
})
export class SolutionsComponent {
}
