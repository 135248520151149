<div [ngSwitch]="session.sessionType" class="shadow-md">
  <app-qcm-session-card [editable]="canWrite" [session]="session" *ngSwitchCase="sessionType.QCM"></app-qcm-session-card>
  <app-problem-analysis-session-card [editable]="canWrite" [mindMapRepository]="mindMapRepository" [session]="session" *ngSwitchCase="sessionType.ProblemAnalysis"></app-problem-analysis-session-card>
  <app-problem-analysis-session-card [editable]="canWrite" [mindMapRepository]="mindMapRepository" [session]="session" *ngSwitchCase="sessionType.SolutionGeneration"></app-problem-analysis-session-card>
  <div *ngSwitchDefault>
    <div class="card-content">
      Session de type inconnu: {{session.sessionType}}
    </div>
  </div>
</div>
