<div class="body-container">
  <div class="info-container">
    <div>
      Vos contributions ont été enregistrées.
    </div>
    <div class="vertical-spacer"></div>
    <div>
      Merci pour votre participation!
    </div>
    <div class="vertical-spacer"></div>
    <div class="tada">🎉</div>
    <div class="flex mt-8">
      <div class="flex-grow"></div>
      <button mat-icon-button color="primary" routerLink="/">
        <mat-icon>home</mat-icon>
      </button>
      <div class="flex-grow"></div>
    </div>
  </div>
</div>
