import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-delete-thread-message-modal',
  template: `
    <h2 mat-dialog-title>
      <h3>Etes-vous sûr(e) ?</h3>
    </h2>
    <mat-dialog-content class="mat-typography">
      <p>Vous allez supprimer ce message.</p>
      <p>Vous ne pourrez pas revenir en arrière</p>
    </mat-dialog-content>
    <mat-dialog-actions class="flex">
      <button mat-stroked-button color="primary" [mat-dialog-close]="false">
        Annuler
      </button>
      <div class="flex-grow"></div>
      <button mat-raised-button color="primary" [mat-dialog-close]="true">
        Confirmer
      </button>
    </mat-dialog-actions>
  `
})
export class DeleteThreadMessageModalComponent {}
