<div class="body-container">
  <div class="signup-container">
    <h3>Inscription</h3>
    <form [formGroup]="form" (submit)="signUp()" action="#">

      <div>
        <mat-form-field>
          <mat-label>Prénom</mat-label>
          <input matInput type="text" required formControlName="firstName">
        </mat-form-field>
      </div>

      <div>
        <mat-form-field>
          <mat-label>Nom</mat-label>
          <input matInput type="text" required formControlName="familyName">
        </mat-form-field>
      </div>

      <div>
        <mat-form-field>
          <mat-label>Adresse e-mail</mat-label>
          <input matInput type="text" required formControlName="email">
        </mat-form-field>
      </div>

      <div>
        <mat-form-field>
          <mat-label>Mot de passe</mat-label>
          <input matInput [type]="hide ? 'password' : 'text'" required formControlName="password">
          <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </mat-form-field>
        <div>
          <span>
            <mat-icon>help</mat-icon>
            Votre mot de passe doit comporter <span class="bold">au moins 8 caractères</span> et contenir <span class="bold">au moins un chiffre</span>
          </span>
        </div>
      </div>
      <div>
        <button mat-raised-button color="primary" [disabled]="form === undefined  || loading || !form.valid" type="submit">
          <mat-spinner *ngIf="loading; else signuptext" diameter="32"></mat-spinner>
          <ng-template #signuptext>
            <span>Inscription</span>
          </ng-template>
        </button>
      </div>
    </form>

    <div class="m-2 text-center text-sm">
      Vous avez déjà un compte ? <a class="color-darkgray" href="/login">Cliquez ici.</a>
    </div>

  </div>
</div>
