<mat-expansion-panel *ngIf="problems !== undefined && problems.length > 0" [disabled]="problems.length === 0">
  <mat-expansion-panel-header>
    <mat-panel-title class="text-lg">
      {{problems.length}} problème{{problems.length > 1 ? 's' : ''}} identifié{{problems.length > 1 ? 's' : ''}}
    </mat-panel-title>
  </mat-expansion-panel-header>

  <mat-nav-list role="list">
    <mat-list-item [routerLink]="problemLink(problem)" class="border rounded-sm my-2 flex bg-yellow-50 border-yellow-500" role="listitem" *ngFor="let problem of problems; let problemIndex = index">
      <div>
        {{problem.title}}
      </div>
      <div class="flex-grow"></div>
      <div *ngIf="problem.resolutionDate !== null; else problemUnresolved" class="primary-text">
        Résolu
      </div>
      <ng-template #problemUnresolved>
        <div class="error-text">
          Non résolu
        </div>
      </ng-template>
    </mat-list-item>
  </mat-nav-list>

</mat-expansion-panel>

<mat-expansion-panel *ngIf="solutions !== undefined && solutions.length > 0" [disabled]="solutions.length === 0">
  <mat-expansion-panel-header>
    <mat-panel-title class="text-lg">
      {{solutions.length}} solution{{solutions.length > 1 ? 's' : ''}} proposée{{solutions.length > 1 ? 's' : ''}}
    </mat-panel-title>
  </mat-expansion-panel-header>

  <mat-nav-list role="list">
    <mat-list-item [routerLink]="solutionLink(solution)" class="border rounded-sm my-2 flex bg-green-50 border-green-500" role="listitem" *ngFor="let solution of solutions; let solutionIndex = index">
      <div>
        {{solution.title}}
      </div>
      <div class="flex-grow"></div>
      <div *ngIf="solution.establishmentDate !== null" class="primary-text">
        Instaurée
      </div>
    </mat-list-item>
  </mat-nav-list>

</mat-expansion-panel>
