import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-ban-thread-user-modal',
  template: `
    <mat-dialog-content class="mat-typography">
       <p>Vous vous apprêtez à bannir cet utilisateur. Il ne pourra plus participer à cette discussion.</p>
       <p>Cette action ne peut pas être annulée.</p>

        <mat-checkbox [(ngModel)]="deleteEntities">
          Supprimer également les messages que cet utilisateur a posté.
        </mat-checkbox>
      <p> <mat-icon>warning</mat-icon> Les réponses à ces messages seront également supprimées si vous cochez cette case.</p>
    </mat-dialog-content>
    <mat-dialog-actions class="flex">
      <button mat-stroked-button color="primary" [mat-dialog-close]="null">
        Annuler
      </button>
      <div class="flex-grow"></div>
      <button mat-raised-button color="primary" [mat-dialog-close]="{deleteEntities: deleteEntities}">
        Confirmer
      </button>
    </mat-dialog-actions>
  `
})
export class BanThreadUserModalComponent  {
  public deleteEntities: boolean = false;
}
