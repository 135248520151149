import {Injectable} from "@angular/core";
import {
  CognitoUserPool,
  CognitoUser,
  CognitoUserSession,
  CookieStorage,
  AuthenticationDetails, CognitoRefreshToken, CognitoAccessToken, CognitoIdToken, UserData
} from "amazon-cognito-identity-js";
import {AppConfig, ConfigService} from "src/environments/config.service";
import {Router, UrlSerializer} from "@angular/router";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import * as uuid from 'uuid';
import {GuestAuthService} from "./guest-auth.service";
import {OrganizationService} from "./organization.service";

type AWSOAuthTokenResponse = {
  access_token: string,
  id_token: string,
  expires_in: number,
  refresh_token: string,
  token_type: string
};

export enum GlobalAuthMode {
  Account = "account",
  Guest = "guest"
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public userPool!: CognitoUserPool;
  private cookieStorage!: CookieStorage;
  private config!: AppConfig;
  private userData: { [key: string]: any } | undefined = undefined;
  private oAuthStateCacheKey = 'oauth_state';
  private idToken: string | null = null;
  public globalAuthMode: GlobalAuthMode = GlobalAuthMode.Account;

  constructor(
    private configService: ConfigService,
    private router: Router,
    private serializer: UrlSerializer,
    private guestAuthService: GuestAuthService,
    private http: HttpClient,
  ) {
    this.config = configService.getConfig();
    if (this.config.PRODUCTION === "true") {
      this.cookieStorage = new CookieStorage({domain: this.config.COOKIE_DOMAIN, secure: true});
    } else {
      this.cookieStorage = new CookieStorage({domain: window.location.hostname, secure: false});
    }

    if (this.guestAuthService.bound()) {
      this.setGlobalAuthMode(GlobalAuthMode.Guest);
    }
    const poolData = {
      Region: this.config.AWS_REGION,
      UserPoolId: this.config.COGNITO_USER_POOL_ID,
      ClientId: this.config.COGNITO_CLIENT_ID,
      Storage: this.cookieStorage
    };
    this.userPool = new CognitoUserPool(poolData);
  }

  public async syncSession() {
    if (this.currentUser() !== undefined) {
      await this.setUserDataFromIdToken();
    }
  }

  private getOAuthState(): string {
    const state = localStorage.getItem(this.oAuthStateCacheKey);
    if (state === null) {
      const state = uuid.v4();
      this.setOAuthState(state);
      return state;
    } else {
      return state;
    }
  }

  private setOAuthState(state: string) {
    localStorage.setItem(this.oAuthStateCacheKey, state);
  }

  public currentUser(): CognitoUser | undefined {
    const user = this.userPool.getCurrentUser();
    if (user === null) {
      return undefined;
    } else {
      return user;
    }
  }

  public isAuthenticated(): boolean {
    return this.currentUser() !== undefined;
  }

  public async signInWithPassword({username, password}: { username: string, password: string }): Promise<void> {
    const authData = {
      Username: username,
      Password: password
    };
    const authenticationDetails = new AuthenticationDetails(authData);
    const userData = {
      Username: username,
      Pool: this.userPool,
      Storage: this.cookieStorage
    };
    const cognitoUser = new CognitoUser(userData);

    this.userData = await new Promise<{[key:string]: any}>((resolve, reject) => {
      const loginHandlers = {
        onSuccess: function (result: unknown) {
          cognitoUser.getSession((err: Error | null, session: CognitoUserSession | null) => {
            if (err) { reject(err.name); }
            else if (session !== null) {
              resolve(session.getIdToken().decodePayload());
            } else {
              reject('unexpected undefined session object');
            }
          })
        },
        onFailure: function (err: Error) {
          reject(err.name);
        }
      }
      cognitoUser.authenticateUser(authenticationDetails, loginHandlers);
    });
  }

  public signOut(): void {
    const currentUser = this.currentUser()
    localStorage.removeItem(this.oAuthStateCacheKey);
    if (currentUser != null) {
      currentUser.signOut();
    }
  }

  public authorizeOAuthProvider(
    {providerName, redirectUri, scope}: { providerName: string, redirectUri: string, scope: string }
  ) {
    const urlTree = this.router.createUrlTree(['/oauth2/authorize'], {queryParams: {
        response_type: 'code',
        client_id: this.config.COGNITO_CLIENT_ID,
        redirect_uri: redirectUri,
        identity_provider: providerName,
        state: this.getOAuthState(),
        scope,
      }})
    window.location.href = this.config.COGNITO_POOL_URL + this.serializer.serialize(urlTree)
  }

  private static authTokensToCognitoSession(
    idToken: string,
    accessToken: string,
    refreshToken: string
  ): CognitoUserSession {
    const parsedIdToken = new CognitoIdToken({IdToken: idToken});
    console.log(parsedIdToken.getJwtToken());
    return new CognitoUserSession(
      {
        IdToken: parsedIdToken,
        AccessToken: new CognitoAccessToken({AccessToken: accessToken}),
        RefreshToken: new CognitoRefreshToken({RefreshToken: refreshToken}),
      }
    )
  }

  public async loginWithOAuthAuthorizationToken(authorizationCode: string, redirectUri: string): Promise<CognitoUser | unknown> {
    if (this.isAuthenticated()) {
      throw new Error("You can't exchange an OAuth authorization token when you are already authenticated.");
    } else {
      let body = new URLSearchParams();
      body.set('grant_type', 'authorization_code');
      body.set('code', authorizationCode);
      body.set('redirect_uri', redirectUri);
      body.set('client_id', this.config.COGNITO_CLIENT_ID);
      body.set('state', this.getOAuthState());


      return this.http.post<AWSOAuthTokenResponse>(
        this.config.COGNITO_POOL_URL + '/oauth2/token',
        body.toString(),
        {headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
          })}
      ).toPromise().then((tokens) => {
        this.userData = AuthService.parseIdToken(tokens.id_token);
        this.idToken = tokens.id_token;
        if (this.userData === undefined) {throw new Error('unexpected');}
        const username = this.userData["cognito:username"] as string;
        const userData = {
          Username: username,
          Pool: this.userPool,
          Storage: this.cookieStorage
        };
        const cognitoUser = new CognitoUser(userData);
        cognitoUser.setSignInUserSession(AuthService.authTokensToCognitoSession(tokens.id_token, tokens.access_token, tokens.refresh_token));
        return cognitoUser;
      }, error => error);
    }
  }

  private static parseIdToken(idToken: string): {[key:string]: any} {
    return JSON.parse(atob(idToken.split('.')[1]));
  }

  private async setUserDataFromIdToken(): Promise<void> {
    const user = this.currentUser();
    if (user === undefined) {
      throw new Error('no current user');
    } else {
      return new Promise((resolve, reject) => {
        user.getSession((error: Error | null, session: CognitoUserSession | null) => {
          if (session !== null) {
            this.userData = session.getIdToken().decodePayload();
            this.idToken = session.getIdToken().getJwtToken();
            resolve();
          } else {
            reject(error);
          }
        })
      });
    }
  }

  public getIdToken(): string | null {
    return this.idToken;
  }

  public getUserData(): {displayName: string, knownUser: boolean} & {[key: string]: any} {
    if (this.userData !== undefined && this.userData.family_name !== undefined && this.userData.family_name.length > 0) {
      return {
        ...this.userData,
        displayName: `${this.userData.given_name} ${this.userData.family_name}`,
        knownUser: true,
      }
    } else if (this.userData !== undefined && this.userData.given_name !== undefined && this.userData.given_name.length > 0) {
      return {
        ...this.userData,
        displayName: this.userData.given_name,
        knownUser: true,
      }
    } else {
      return {
        ...this.userData,
        displayName: 'Utilisateur Inconnu',
        knownUser: false,
      }
    }
  }

  public getAuthorizationHeader(): null | string {
    let value;
    if (this.globalAuthMode === GlobalAuthMode.Account) {
      value = this.getIdToken();
    } else {
      value = this.guestAuthService.getAuthorizationHeader();
    }
    // console.log(`Using auth header: ${value}`);
    if (this.config.PRODUCTION !== "true") {
      console.log(value);
    }
    return value;
  }

  public setGlobalAuthMode(mode: GlobalAuthMode) {
    // console.log(`Auth mode set to ${mode}`);
    this.globalAuthMode = mode;
  }

  public getUserId(): string | undefined {
    if (this.globalAuthMode === GlobalAuthMode.Guest) {
      const tokenId = this.guestAuthService.getTokenId();
      if (tokenId === null) {
        return undefined;
      } else {
        return `guest:${tokenId}`
      }
    } else {
      const userData = this.getUserData();
      if (userData === undefined) {
        return undefined;
      } else {
        return `cognito:${userData['cognito:username']}`;
      }
    }
  }
}
