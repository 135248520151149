import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {SessionCardComponent} from "./session-card.component";
import {RouterModule} from "@angular/router";
import {QcmSessionCard} from "./qcm-session-card.component";
import {SessionStartButtonComponent} from "./session-start-button.component";
import {MatButtonModule} from "@angular/material/button";
import {SessionCloseButtonComponent} from "./session-close-button.component";
import {MatDividerModule} from "@angular/material/divider";
import {ProblemAnalysisSessionCard} from "./problem-analysis-session-card.component";
import {MatRippleModule} from "@angular/material/core";

@NgModule({
  declarations: [
    SessionStartButtonComponent,
    SessionCloseButtonComponent,
    QcmSessionCard,
    ProblemAnalysisSessionCard,
    SessionCardComponent,
  ],
  exports: [QcmSessionCard, SessionCardComponent, SessionStartButtonComponent, SessionCloseButtonComponent],
  imports: [CommonModule, MatButtonModule, RouterModule, MatDividerModule, MatRippleModule]
})
export class SessionCardModule {
}
