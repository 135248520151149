import {RouterModule} from "@angular/router";
import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {STATE} from "../app-states";
import {UserResolve} from "../../auth/auth.resolvers";
import {
  AdminPanelAddSuperAdminModalComponent,
  AdminPanelComponent,
  AdminPanelHelpModalComponent,
  CreateOrganizationModalComponent
} from "./admin-panel.component";
import {HeaderModule} from "../header/header.module";
import {MatDividerModule} from "@angular/material/divider";
import {SelectedOrganizationResolve} from "../../auth/organization.resolvers";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatDialogModule} from "@angular/material/dialog";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatSelectModule} from "@angular/material/select";

export const ADMIN_PANEL_STATE = {
  path: STATE.ADMIN_PANEL,
  resolve: {
    selectedOrganization: SelectedOrganizationResolve,
    userData: UserResolve,
  },
  component: AdminPanelComponent
}

@NgModule({
  imports: [CommonModule, RouterModule, HeaderModule, MatDividerModule, MatButtonModule, MatIconModule, MatDialogModule, MatFormFieldModule, MatInputModule, FormsModule, MatProgressSpinnerModule, MatTooltipModule, MatSelectModule, ReactiveFormsModule],
  declarations: [AdminPanelComponent, CreateOrganizationModalComponent, AdminPanelHelpModalComponent, AdminPanelAddSuperAdminModalComponent],
  exports: [],
  providers: []
}) export class AdminModule {}
