import {ActivatedRouteSnapshot, Resolve, Router} from "@angular/router";
import {MindMapArgument, MindMapProblem, MindMapSolution, Session} from "./revogo-client.types";
import {RevogoClientService} from "./revogo-client.service";
import {OrganizationService} from "../auth/organization.service";
import {Injectable} from "@angular/core";
import {Location} from "@angular/common";

@Injectable()
export class OrganizationSessionsResolve implements Resolve<Session[]> {
  constructor(
    private revogoClient: RevogoClientService,
    private organizationService: OrganizationService
  ) {
  }

  async resolve(route: ActivatedRouteSnapshot) {

    const organization = await this.organizationService.getSelectedOrganization().toPromise();
    if (organization === null) {
      throw new Error("No organization selected")
    } else {
      const organizationId = organization.organizationId;
      return await this.revogoClient.getSessions(organizationId).toPromise();
    }


  }
}

@Injectable()
export class SessionResolve implements Resolve<Session> {
  constructor(
    private revogoClient: RevogoClientService,
    private location: Location,
    private router: Router,
  ) {
  }

  private static resolveSessionFromParentsSnapshots(route: ActivatedRouteSnapshot) {
    let node: ActivatedRouteSnapshot | null = route;
    let session = route.data.session;

    while (node !== null && session === undefined) {
      node = node.parent;
      if (node !== null) {
        session = node.data.session;
      }
    }
    return session;
  }

  async resolve(route: ActivatedRouteSnapshot) {
    const parentSession = SessionResolve.resolveSessionFromParentsSnapshots(route);
    if (parentSession !== undefined) {
      return parentSession;
    } else {
      let node: ActivatedRouteSnapshot | null = route;
      let sessionId = route.params.sessionId;
      while (node !== null && sessionId === undefined) {
        node = node.parent;
        if (node !== null) {
          sessionId = node.params.sessionId;
        }
      }
      if (sessionId === undefined) {
        throw new Error("could not find session Id in route or its parents");
      }

      return this.revogoClient.getSession(sessionId).toPromise().catch(err => {
        this.router.navigate(['/login'], {queryParams: {from: this.location.path()}});
      });
    }

  }
}

@Injectable()
export class ProblemResolve implements Resolve<MindMapProblem> {
  constructor(
    private revogoClient: RevogoClientService,
    private organizationService: OrganizationService,
  ) {
  }

  async resolve(route: ActivatedRouteSnapshot) {
    const organization = await this.organizationService.getSelectedOrganization().toPromise();
    if (organization !== null) {
      return this.revogoClient.getMindMapProblem(organization.organizationId, route.params.problemId).toPromise();
    } else {
      throw new Error('needs selected organization');
    }
  }
}

@Injectable()
export class SolutionResolve implements Resolve<MindMapSolution> {
  constructor(
    private revogoClient: RevogoClientService,
    private organizationService: OrganizationService
  ) {
  }

  async resolve(route: ActivatedRouteSnapshot) {
    const organization = await this.organizationService.getSelectedOrganization().toPromise();
    if (organization !== null) {
      return this.revogoClient.getMindMapSolution(organization.organizationId, route.params.solutionId).toPromise();
    } else {
      throw new Error('needs selected organization');
    }
  }
}

@Injectable()
export class ArgumentResolve implements Resolve<MindMapArgument> {
  constructor(
    private revogoClient: RevogoClientService,
    private organizationService: OrganizationService
  ) {
  }

  async resolve(route: ActivatedRouteSnapshot) {
    const organization = await this.organizationService.getSelectedOrganization().toPromise();
    if (organization !== null) {
      return this.revogoClient.getMindMapArgument(organization.organizationId, route.params.argumentId).toPromise();
    } else {
      throw new Error('needs selected organization');
    }
  }
}
