import {Component, Input, Output, EventEmitter, OnInit} from "@angular/core";
import {Session, SessionType} from "../../../revogo-client/revogo-client.types";
import {OrganizationService} from "../../../auth/organization.service";

@Component({
  selector: 'app-session-content',
  template: `
    <ng-container *ngIf="canWrite !== undefined" [ngSwitch]="session.sessionType">
      <app-qcm-session-content [canWrite]="canWrite" *ngSwitchCase="sessionType.QCM" [session]="session" (dirty)="setDirty($event)"></app-qcm-session-content>
      <app-problem-analysis-session-content [canWrite]="canWrite" *ngSwitchCase="sessionType.ProblemAnalysis" [session]="session" (dirty)="setDirty($event)"></app-problem-analysis-session-content>
      <app-problem-analysis-session-content [canWrite]="canWrite"  *ngSwitchCase="sessionType.SolutionGeneration" [session]="session" (dirty)="setDirty($event)"></app-problem-analysis-session-content>
    </ng-container>
  `,
})
export class SessionContentComponent implements OnInit {
  @Input()
  public session!: Session;
  public sessionType = SessionType;

  @Output()
  public dirty = new EventEmitter<boolean>();

  public canWrite!: boolean;

  constructor(private organizationService: OrganizationService) {}

  async ngOnInit() {
    this.canWrite = await this.organizationService.userHasRole(['manager']);
  }

  public setDirty(event: boolean) {
    this.dirty.emit(event);
  }
}
