import {Component, Input, Output, EventEmitter} from "@angular/core";
import {RevogoClientService} from "../../revogo-client/revogo-client.service";
import {ArgumentSign, MindMapArgument} from "../../revogo-client/revogo-client.types";
import {CreateArgumentModalComponent} from "./create-argument-modal.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-argument-explorer',
  template: `
    <div class="flex m-2" *ngIf="arguments !== undefined; else argumentsLoading">

      <div class="flex-grow w-1/2">
        <div class="flex">
          <div class="flex-grow"></div>
          <div class="text-green-500 text-lg pt-2">
            <span *ngIf="arguments.pro.length === 0; else argsCountPro">Aucun argument pour</span>
            <ng-template #argsCountPro>
              <span>{{arguments.pro.length}} argument{{arguments.pro.length > 1 ? 's' : ''}} pour</span>
            </ng-template>
          </div>
          <div class="flex-grow"></div>
        </div>
        <div *ngIf="editable" class="flex m-1">
          <div class="flex-grow"></div>
          <div>
            <button mat-stroked-button color="primary" (click)="addArgumentModal(argumentSign.Pro)">
              <mat-icon>add</mat-icon>
              Ajouter
            </button>
          </div>
          <div class="flex-grow"></div>
        </div>
        <div class="m-2">
          <div *ngFor="let argument of arguments.pro" class="my-2">
            <app-argument-card [argument]="argument" twClasses="border-green-500 bg-green-50"></app-argument-card>
          </div>
        </div>
      </div>


      <mat-divider vertical></mat-divider>

      <div class="flex-grow w-1/2">
        <div class="flex">
          <div class="flex-grow"></div>
          <div class="text-red-500 text-lg pt-2">
            <span *ngIf="arguments.against.length === 0; else argsCountAgainst">Aucun argument contre</span>
            <ng-template #argsCountAgainst>
              <span>{{arguments.against.length}} argument{{arguments.against.length > 1 ? 's' : ''}} contre</span>
            </ng-template>
          </div>
          <div class="flex-grow"></div>
        </div>
        <div *ngIf="editable" class="flex m-1">
          <div class="flex-grow"></div>
          <div>
            <button mat-stroked-button color="error" (click)="addArgumentModal(argumentSign.Against)">
              <mat-icon>add</mat-icon>
              Ajouter
            </button>
          </div>
          <div class="flex-grow"></div>
        </div>

        <div class="m-2">
          <div *ngFor="let argument of arguments.against" class="my-2">
            <app-argument-card [argument]="argument" twClasses="border-red-500 bg-red-50"></app-argument-card>
          </div>
        </div>
      </div>
    </div>
    <ng-template #argumentsLoading>
      <div class="flex m-4">
        <div class="flex-grow"></div>
        <mat-spinner></mat-spinner>
        <div class="flex-grow"></div>
      </div>
    </ng-template>`
})
export class ArgumentExplorerComponent {
  @Input()
  rootNodeId!: string;

  @Input()
  organizationId!: string;

  @Input()
  editable: boolean = false;

  @Output()
  empty = new EventEmitter<boolean>()

  public argumentSign = ArgumentSign;

  public arguments!: {
    pro: MindMapArgument[],
    against: MindMapArgument[]
  };

  constructor(private revogoClient: RevogoClientService,
              private dialog: MatDialog,
  ) {
  }

  async ngOnInit() {
    await this.getArguments();
    this.empty.emit(this.arguments.pro.length === 0 && this.arguments.against.length === 0);
  }

  private async getArguments() {
    function argumentSorter(a: MindMapArgument, b: MindMapArgument) {
      const weightCompare = (b.weight ?? 0) - (a.weight ?? 0);
      if (weightCompare !== 0) {
        return weightCompare;
      } else {
        return a.description.localeCompare(b.description);
      }
    }
    const args = await this.revogoClient.getMindMapNodeChildArguments(this.organizationId, this.rootNodeId).toPromise();
    this.arguments = {
      pro: args.filter(arg => arg.sign === ArgumentSign.Pro).sort(argumentSorter),
      against: args.filter(arg => arg.sign === ArgumentSign.Against).sort(argumentSorter),
    };
  }

  public addArgumentModal(sign: ArgumentSign) {
    const ref = this.dialog.open(CreateArgumentModalComponent, {panelClass: 'wide-modal'});
    ref.componentInstance.sign = sign;
    ref.afterClosed().subscribe(async result => {
      if (result !== null) {
        await this.revogoClient.createMindMapArgument(this.organizationId, {
          sign,
          description: result,
          target_node: this.rootNodeId,
        }).toPromise();
        await this.getArguments();
      }
    });
  }
}
