<div *ngIf="form !== undefined" class="new-session-container">
  <form [formGroup]="form">

    <div class="form-field-container">
      <mat-form-field>
        <mat-label>Intitulé</mat-label>
        <input type="text" matInput required formControlName="sessionName">
      </mat-form-field>
    </div>

    <div class="form-field-container">
      <mat-form-field>
        <mat-label>Type de session</mat-label>
        <mat-select formControlName="sessionType" (selectionChange)="adaptFormToSessionType()" required>
          <mat-option *ngFor="let type of sessionTypes" [value]="type">
            <app-session-type [type]="type"></app-session-type>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="form-field-container" *ngIf="form.controls.problemAnalysisStatement">
      <div class="text-gray-500 text-sm italic">
        Pour démarrer une session de résolution de problème à partir d'un problème connu de Revogo, rendez-vous sur la page de ce problème.
      </div>
      <mat-form-field>
        <mat-label>Définition du problème à analyser</mat-label>
        <textarea matInput required formControlName="problemAnalysisStatement" id="" cols="30" rows="10"></textarea>
      </mat-form-field>
    </div>

    <div class="form-field-container">
      <mat-form-field>
        <mat-label>Droit de participation</mat-label>
        <mat-select formControlName="participationMode" required (selectionChange)="adaptFormToParticipationMode()">
          <mat-option *ngFor="let mode of participationModes" [value]="mode">
            <app-session-participation-mode [mode]="mode"></app-session-participation-mode>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="form-field-container" *ngIf="form.controls.tokenCount">
      <mat-form-field>
        <mat-label>Nombre maximum de participants anonymes</mat-label>
        <input type="number" matInput required formControlName="tokenCount">
      </mat-form-field>
    </div>

    <div class="form-field-container">
      <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid || loading" (click)="submit()">
        Suivant
      </button>
    </div>

  </form>
</div>
