<div class="m-4">

  <div class="flex">
    <div class="m-1">
      <button mat-stroked-button color="primary" (click)="location.back()">Retour</button>
    </div>
    <div class="text-lg primary-text mt-2 ml-2">Problème</div>
  </div>

  <div class="my-2">
    <mat-divider></mat-divider>
  </div>

  <div *ngIf="problem" class="border bg-yellow-50 border-yellow-500 rounded-sm flex-col shadow-lg shadow-inner">
    <div class="flex m-2">
        <div class="flex-grow">
        <ng-container *ngIf="editMode === false; else titleInput">
          <div class="mx italic text-xs text-gray-500">Titre</div>
          <div class="mx-2 text-lg">
            {{problem.title}}
          </div>
        </ng-container>
        <ng-template #titleInput>
          <mat-form-field class="m-2 w-full">
            <mat-label>Titre</mat-label>
            <input class="w-full" matInput type="text" required [(ngModel)]="problem.title">
          </mat-form-field>
        </ng-template>
      </div>
      <div class="flex-grow"></div>
      <div class="text-lg mt-4 mr-2">
        <span *ngIf="problem.resolutionDate !== null" class="primary-text">
          Résolu
        </span>
        <span *ngIf="problem.resolutionDate === null" class="error-text">
          Non résolu
        </span>
      </div>
    </div>

    <mat-divider></mat-divider>
    <div class="m-2">
      <ng-container *ngIf="editMode === false; else editDescription">
        <div class="mx italic text-xs text-gray-500">Description</div>
        <div class="mx-2 text-md">
          <div *ngFor="let line of descriptionLines">
            {{line}}
          </div>
        </div>
      </ng-container>
      <ng-template #editDescription>
        <mat-form-field class="w-full">
          <mat-label>Description</mat-label>
          <textarea matInput type="text" required rows="10" [(ngModel)]="problem.description"></textarea>
        </mat-form-field>
      </ng-template>
    </div>
    <mat-divider></mat-divider>
    <div class="m-2">
      <div class="mx italic text-xs text-gray-500">Crée le {{problem.creationDate | date:'dd/MM/YYYY'}}</div>
    </div>
    <ng-container *ngIf="parentSession !== undefined">
      <mat-divider></mat-divider>
      <div class="bg-purple-50 cursor-pointer" mat-ripple routerLink="/sessions/{{problem.sessionId}}/results">
        <div class="p-2">
          <div class="mx italic text-xs text-gray-500">Identifié au cours de la session :</div>
          <div class="mx-2 text-lg primary-text">{{parentSession.sessionName}}</div>
        </div>
      </div>
    </ng-container>
  </div>

  <div *ngIf="problem && canWrite" class="flex my-2">
    <div *ngIf="problem.resolutionDate === null">
      <button mat-stroked-button color="primary" (click)="resolveProblemModal()">
        <mat-icon>done</mat-icon>
        Marquer comme résolu
      </button>
    </div>
    <div class="flex-grow"></div>
    <div class="mx-2">
      <button *ngIf="problem.resolutionDate === null && (potentialSolutions !== undefined && potentialSolutions.length === 0) && (childSessions !== undefined && childSessions.length === 0)" mat-stroked-button color="error" (click)="deleteProblemModal()">
        <mat-icon>delete</mat-icon>
        Supprimer
      </button>
    </div>
    <div *ngIf="problem.resolutionDate === null">
      <button *ngIf="editMode === false" mat-stroked-button color="primary" (click)="toggleEditMode()">
        <mat-icon>edit</mat-icon>
        Modifier
      </button>
      <button *ngIf="editMode === true" mat-stroked-button color="primary" (click)="toggleEditMode()">
        <mat-icon>save</mat-icon>
        Sauvegarder
      </button>
    </div>
  </div>

  <div *ngIf="canWrite" class="my-2 flex">
    <button mat-stroked-button color="primary" (click)="createSessionModal()">
      <mat-icon>add</mat-icon>
      Créer une session
    </button>
    <div class="flex-grow"></div>
  </div>

  <ng-container *ngIf="childSessions !== undefined && childSessions.length > 0">
    <mat-divider></mat-divider>
    <div class="m-2 mt-4">
      <div class="text-lg primary-text m-2">
        Sessions créées à partir de ce problème
      </div>
      <div class="border border-purple-500 bg-purple-50 mt-2 cursor-pointer" mat-ripple [routerLink]="'/sessions/'+session.sessionId+'/details'" *ngFor="let session of childSessions">
        <div class="m-2">{{session.sessionName}}</div>
      </div>
    </div>

  </ng-container>

  <mat-divider></mat-divider>



  <div class="p2">
    <div *ngIf="potentialSolutions !== undefined" class="flex">
      <div *ngIf="potentialSolutions.length > 0; else noSolutions" class="text-lg primary-text m-2 mt-4">{{potentialSolutions.length}} solution{{potentialSolutions.length > 1 ? 's' : ''}} proposée{{potentialSolutions.length > 1 ? 's' : ''}}</div>
      <ng-template #noSolutions>
        <div class="m-2 text-lg primary-text" *ngIf="potentialSolutions !== undefined">
          Aucune solution n'a été proposée pour le moment.
        </div>
      </ng-template>
      <div class="flex-grow"></div>
      <div *ngIf="canWrite" class="my-2">
        <button mat-raised-button color="primary" (click)="createSolutionModal()">Proposer une solution</button>
      </div>
    </div>
    <div *ngFor="let solution of potentialSolutions" mat-ripple [routerLink]="'/solutions/' + solution.id" class="cursor-pointer bg-green-50 border border-green-500 rounded-sm flex m-2">
      <div class="text-md m-2">{{solution.title}}</div>
      <div class="flex-grow"></div>
      <div *ngIf="solution.establishmentDate !== null" class="text-lg primary-text m-2">Instaurée</div>
    </div>
  </div>
</div>
