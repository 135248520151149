import { NgModule } from '@angular/core';
import { UserResolve } from './auth.resolvers';
import { AuthService } from './auth.service';
import {OrganizationService} from "./organization.service";
import {AuthorizedOrganizationsResolve, SelectedOrganizationResolve} from "./organization.resolvers";
import {GuestAuthService} from "./guest-auth.service";


@NgModule({
  providers: [
    GuestAuthService,
    AuthService,
    OrganizationService,
    UserResolve,
    AuthorizedOrganizationsResolve,
    SelectedOrganizationResolve,
  ],
})
export class AuthModule {}
