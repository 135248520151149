import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ConfigService} from "../environments/config.service";
import {Observable} from "rxjs";
import {
  MindMapArgument,
  MindMapProblem,
  MindMapSolution,
  Organization,
  Session,
  SessionEntity,
  SessionType, UserAuthorizations, UserOrganizationAuthorization
} from "./revogo-client.types";
import {map} from "rxjs/operators";

import {camelCase} from 'lodash';
import {AuthService} from "../auth/auth.service";

const camelizeKeys = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(v => camelizeKeys(v));
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [camelCase(key)]: camelizeKeys(obj[key]),
      }),
      {},
    );
  }
  return obj;
};

const snakeizeKeys = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(v => snakeizeKeys(v));
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [key.replace(/([A-Z])/g, "_$1").toLowerCase()]: snakeizeKeys(obj[key]),
      }),
      {},
    );
  }
  return obj;
}

@Injectable({
  providedIn: 'root',
})
export class RevogoClientService {
  baseUrl!: string;

  constructor(
    private _http: HttpClient,
    private _config: ConfigService,
    private authService: AuthService,
  ) {
    this.baseUrl = _config.getConfig().API_URL;
  }

  private buildAuthHeaders(): HttpHeaders {
    const authString = this.authService.getAuthorizationHeader();
    let headers: { [key: string]: string } = {
      'Access-Control-Allow-Origin': '*',
    };
    if (authString !== null) {
      headers['Authorization'] = authString;
    }
    return new HttpHeaders(headers);
  }

  public get(path: string): Observable<any> {
    // path must start with a slash
    return this._http.get<any>(`${this.baseUrl}${path}`,
      {headers: this.buildAuthHeaders()}
    ).pipe(
      map(object => camelizeKeys(object))
    );
  }

  public post(path: string, body: { [key: string]: any }): Observable<any> {
    // path must start with a slash
    return this._http.post<any>(
      `${this.baseUrl}${path}`, body, {headers: this.buildAuthHeaders()}
    ).pipe(map(object => camelizeKeys(object)));
  }

  public delete(path: string): Observable<any> {
    return this._http.delete(`${this.baseUrl}${path}`, {headers: this.buildAuthHeaders()}
    ).pipe(map(obj => camelizeKeys(obj)));
  }

  private static deserializeSession(session: any): Session {
    return {
      ...session,
      creationDate: new Date(session.creationDate),
      startDate: session.startDate ? new Date(session.startDate) : null,
      endDate: session.endDate ? new Date(session.endDate) : null,
      sessionType: session.sessionType as SessionType
    };
  }

  private static deserializeSessionEntity(sessionEntity: any): SessionEntity {
    return {
      ...sessionEntity,
      createdAt: new Date(sessionEntity.createdAt)
    };
  }

  private static deserializeMindMapProblem(problem: any): MindMapProblem {
    return {
      ...problem,
      creationDate: new Date(problem.creationDate),
      resolutionDate: problem.resolutionDate === null ? null : new Date(problem.resolutionDate),
    };
  }

  private static deserializeMindMapSolution(solution: any): MindMapSolution {
    return {
      ...solution,
      creationDate: new Date(solution.creationDate),
      establishmentDate: solution.establishmentDate === null ? null : new Date(solution.establishmentDate)
    };
  }

  private static deserializeMindMapArgument(argument: any): MindMapArgument {
    const obj = {
      ...argument,
      creationDate: new Date(argument.creationDate)
    };
    obj.editable = obj.editable ?? true;
    return obj;
  }

  public getAuthorizations(): Observable<UserAuthorizations> {
    return this.get('/authorizations').pipe(map(camelizeKeys));
  }

  public getSessions(organizationId: string): Observable<Session[]> {
    return this.get('/sessions/' + organizationId).pipe(
      map((sessions) => sessions.map(RevogoClientService.deserializeSession))
    );
  }

  public getMindMapNodeChildSessions(organizationId: string, nodeId: string): Observable<Session[]> {
    return this.get(`/mind_map/${organizationId}/node/${nodeId}/sessions`).pipe(map(sessions => sessions.map(RevogoClientService.deserializeSession)));
  }

  public getSession(sessionId: string): Observable<Session> {
    return this.get('/session/' + sessionId).pipe(
      map(RevogoClientService.deserializeSession)
    );
  }

  public createSession(payload: { [key: string]: any }): Observable<Session> {
    return this.post('/session', snakeizeKeys(payload)).pipe(map(RevogoClientService.deserializeSession));
  }

  public updateSession(sessionId: string, payload: { [key: string]: any }): Observable<Session> {
    return this.post(`/session/${sessionId}`, snakeizeKeys(payload)).pipe(map(RevogoClientService.deserializeSession));
  }

  public deleteSession(sessionId: string): Observable<any> {
    return this.delete(`/session/${sessionId}`);
  }

  public updateSessionDefinition(sessionId: string, payload: { [key: string]: any }): Observable<Session> {
    return this.post(`/session/${sessionId}/definition`, snakeizeKeys(payload)).pipe(map(RevogoClientService.deserializeSession));
  }

  public banSessionUser(sessionId: string, ownerReference: string, deleteEntities: boolean = false): Observable<any> {
    return this.post(`/session/${sessionId}/ban/${ownerReference}`, {delete_entities: deleteEntities});
  }

  public getOrganizations(): Observable<Organization[]> {
    return this.get('/organizations');
  }

  public startSession(sessionId: string): Observable<any> {
    return this.post(`/session/${sessionId}/start`, {});
  }

  public closeSession(sessionId: string): Observable<any> {
    return this.post(`/session/${sessionId}/close`, {});
  }

  public getUserSessionEntities(sessionId: string): Observable<SessionEntity[]> {
    return this.get(`/session/${sessionId}/entities/mine`).pipe(map(
      entities => entities.map(RevogoClientService.deserializeSessionEntity)
    ));
  }

  public getAllSessionEntities(sessionId: string): Observable<SessionEntity[]> {
    return this.get(`/session/${sessionId}/entities/all`).pipe(map(
      entities => entities.map(RevogoClientService.deserializeSessionEntity)
    ));
  }

  public getPublicSessionEntities(sessionId: string): Observable<SessionEntity[]> {
    return this.get(`/session/${sessionId}/entities/public`).pipe(map(
      entities => entities.map(RevogoClientService.deserializeSessionEntity)
    ));
  }

  public createSessionEntity(sessionId: string, body: { [key: string]: any }): Observable<SessionEntity> {
    return this.post(`/session/${sessionId}/entity`, snakeizeKeys(body)).pipe(map(RevogoClientService.deserializeSessionEntity));
  }

  public updateSessionEntity(sessionId: string, entityId: string, body: { [key: string]: any }): Observable<SessionEntity> {
    return this.post(`/session/${sessionId}/entity/${entityId}`, snakeizeKeys(body)).pipe(map(RevogoClientService.deserializeSessionEntity));
  }

  public sessionEntityVote(sessionId: string, entityId: string, sign: number) {
    return this.post(`/session/${sessionId}/entity/${entityId}/vote`, {sign});
  }

  public deleteSessionEntity(sessionId: string, entityId: string) {
    return this.delete(`/session/${sessionId}/entity/${entityId}`);
  }

  public cloneSession(sessionId: string, newSessionName: string | undefined): Observable<Session> {
    const body: { [key: string]: any } = {};
    if (newSessionName !== undefined) {
      body.session_name = newSessionName;
    }
    return this.post(`/session/${sessionId}/clone`, body).pipe(map(RevogoClientService.deserializeSession));
  }

  public getMindMapProblem(organizationId: string, problemId: string): Observable<MindMapProblem> {
    return this.get(`/mind_map/${organizationId}/problems/${problemId}`).pipe(map(RevogoClientService.deserializeMindMapProblem));
  }

  public getMindMapOrganizationProblems(organizationId: string): Observable<MindMapProblem[]> {
    return this.get(`/mind_map/${organizationId}/problems`).pipe(
      map(
        problems => problems.map(RevogoClientService.deserializeMindMapProblem)
      )
    );
  }

  public createMindMapProblem(organizationId: string, body: { [key: string]: any }): Observable<MindMapProblem> {
    return this.post(`/mind_map/${organizationId}/problems`, snakeizeKeys(body)).pipe(
      map(RevogoClientService.deserializeMindMapProblem)
    );
  }

  public updateMindMapProblem(organizationId: string, problemId: string, body: { [key: string]: any }): Observable<MindMapProblem> {
    return this.post(`/mind_map/${organizationId}/problems/${problemId}`, snakeizeKeys(body)).pipe(
      map(RevogoClientService.deserializeMindMapProblem)
    );
  }

  public getMindMapOrganizationSolutions(organizationId: string): Observable<MindMapSolution[]> {
    return this.get(`/mind_map/${organizationId}/solutions`).pipe(map(solutions => solutions.map(RevogoClientService.deserializeMindMapSolution)));
  }

  public deleteMindMapProblem(organizationId: string, problemId: string): Observable<void> {
    return this.delete(`/mind_map/${organizationId}/problems/${problemId}`);
  }

  public getMindMapProblemSolutions(organizationId: string, problemId: string): Observable<MindMapSolution[]> {
    return this.get(`/mind_map/${organizationId}/problems/${problemId}/solutions`).pipe(map(solutions => solutions.map(RevogoClientService.deserializeMindMapSolution)));
  }

  public getMindMapSolution(organizationId: string, solutionId: string): Observable<MindMapSolution> {
    return this.get(`/mind_map/${organizationId}/solutions/${solutionId}`).pipe(map(RevogoClientService.deserializeMindMapSolution));
  }

  public createMindMapSolution(organizationId: string, body: { [key: string]: any }): Observable<MindMapSolution> {
    return this.post(`/mind_map/${organizationId}/solutions`, snakeizeKeys(body)).pipe(map(RevogoClientService.deserializeMindMapSolution));
  }

  public updateMindMapSolution(organizationId: string, solutionId: string, body: { [key: string]: any }): Observable<MindMapSolution> {
    return this.post(`/mind_map/${organizationId}/solutions/${solutionId}`, snakeizeKeys(body)).pipe(map(RevogoClientService.deserializeMindMapSolution));
  }

  public deleteMindMapSolution(organizationId: string, solutionId: string): Observable<any> {
    return this.delete(`/mind_map/${organizationId}/solutions/${solutionId}`);
  }

  public getMindMapArgument(organizationId: string, argumentId: string): Observable<MindMapArgument> {
    return this.get(`/mind_map/${organizationId}/arguments/${argumentId}`).pipe(map(RevogoClientService.deserializeMindMapArgument));
  }

  public createMindMapArgument(organizationId: string, body: {[key: string]: any}): Observable<MindMapArgument> {
    return this.post(`/mind_map/${organizationId}/arguments`, snakeizeKeys(body)).pipe(map(RevogoClientService.deserializeMindMapArgument));
  }

  public updateMindMapArgument(organizationId: string, argumentId: string, body: {[key: string]: any}): Observable<MindMapArgument> {
    return this.post(`/mind_map/${organizationId}/arguments/${argumentId}`, snakeizeKeys(body)).pipe(map(RevogoClientService.deserializeMindMapArgument));
  }

  public deleteMindMapArgument(organizationId: string, argumentId: string): Observable<any> {
    return this.delete(`/mind_map/${organizationId}/arguments/${argumentId}`);
  }

  public getMindMapNodeChildArguments(organizationId: string, nodeId: string): Observable<MindMapArgument[]> {
    return this.get(`/mind_map/${organizationId}/node/${nodeId}/arguments`).pipe(map(solutions => solutions.map(RevogoClientService.deserializeMindMapArgument)));
  }

  public createOrganisation(organizationName: string): Observable<any> {
    return this.post(`/organizations`, {organization_name: organizationName});
  }

  public getOrganizationUserAuthorizations(organizationId: string): Observable<UserOrganizationAuthorization[]> {
    return this.get(`/authorizations/${organizationId}`);
  }

  public getUserAuthorizations(organizationId: string, userEmail: string): Observable<UserOrganizationAuthorization> {
    return this.get(`/authorizations/${organizationId}/users/${userEmail}`);
  }

  public upsertUserAuthorization(organizationId: string, userEmail: string, role: string): Observable<UserOrganizationAuthorization> {
    return this.post(`/authorizations/${organizationId}/users/${userEmail}`, {role});
  }

  public deleteUserAuthorization(organizationId: string, userEmail: string): Observable<any> {
    return this.delete(`/authorizations/${organizationId}/users/${userEmail}`);
  }

  public getSuperAdmins(organizationId: string): Observable<UserOrganizationAuthorization[]> {
    return this.get(`/authorizations/${organizationId}/superadmin`);
  }

  public upsertSuperAdmin(organizationId: string, userEmail: string): Observable<any> {
    return this.post('/authorizations/${organizationId}/superadmin', {user_email: userEmail});
  }
}
