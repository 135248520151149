import {Component, Input, Output, EventEmitter, OnInit} from "@angular/core";
import {ThreadNode} from "../../live-thread/live-thread.service";
import {ThreadEvent, ThreadEventKind} from "./thread.event";
import {MessageContextButtonDefinition} from "./thread-context-actions";
import {ThreadViewCacheService} from "./thread-view-state.service";

@Component({
  selector: 'app-thread-message-tree',
  template: `
    <div [ngStyle]="nestingLevel > 0 ? {'border-left': 'solid 1px'} : {}" class="border-gray-500">
      <div class=" rounded-sm">
        <div [ngClass]="node.highlighted ? 'border-2 rounded-md border-blue-400 bg-blue-50' : '' ">
          <div *ngIf="node.children.length > 0" class="text-sm cursor-pointer hover:underline ml-2 leading-3 mt-1 max-w-min" (click)="toggleCollapsed()">
            <span *ngIf="collapsed">+</span>
            <span class="text-gray-400" *ngIf="!collapsed">-</span>
          </div>
          <div class="flex">
            <div class="flex-grow whitespace-pre-line p-2" [innerHTML]="messageProcessor(node)">
            </div>
          </div>

          <div class="text-gray-500 italic text-sm m-2" *ngIf="node.ownerId.startsWith('cognito') || node.isMine">
            Par
            <span *ngIf="node.isMine; else notMyMessage" class="text-blue-500">moi</span>
            <ng-template #notMyMessage>
              <ng-container *ngIf="node.authorInfo !== undefined; else legacyAuthor">
                <span *ngIf="node.authorInfo.authorRole === 'sage'" matTooltip="Modérateur" class="text-green-500">{{node.authorInfo.authorDisplayName}}.</span>
                <span *ngIf="node.authorInfo.authorRole === 'player'" class="text-gray-500">{{node.authorInfo.authorDisplayName}}.</span>
              </ng-container>
              <ng-template #legacyAuthor>
                <span *ngIf="node.ownerId.startsWith('cognito')" class="primary-text">Modérateur</span>
              </ng-template>

            </ng-template>
          </div>
        </div>

        <mat-divider></mat-divider>

        <div class="flex p-2" *ngIf="mobileLayout">

          <div class="flex-grow"></div>

          <div [ngClass]="voteClass()" style="margin-top: 7px" class="mx-2 text-lg">
            {{node.voteScore > 0 ? '+' : ''}}{{node.voteScore}}
          </div>

          <ng-container *ngIf="showDefaultButtons">
            <mat-divider vertical></mat-divider>
            <div class="mx-2">
              <button mat-icon-button [ngClass]="node.myVote === 1 ? 'bg-green-400' : ''" (click)="emitVote(1)">
                <mat-icon>thumb_up</mat-icon>
              </button>
            </div>

            <mat-divider vertical></mat-divider>
            <div class="mx-2">
              <button mat-icon-button [ngClass]="node.myVote === -1 ? 'bg-red-500' : ''" (click)="emitVote(-1)">
                <mat-icon>thumb_down</mat-icon>
              </button>
            </div>


            <mat-divider vertical></mat-divider>
            <button mat-icon-button (click)="emitReply()">
              <mat-icon>reply</mat-icon>
            </button>
          </ng-container>


          <ng-container *ngIf="contextButtonDefinition && contextButtonDefinition.length > 0">
            <mat-divider vertical></mat-divider>
            <app-thread-context-actions [node]="node" (event)="emitContextSpecificEvent($event)" [buttonsDefinition]="contextButtonDefinition"></app-thread-context-actions>
          </ng-container>
        </div>

          <div *ngIf="!mobileLayout" class="flex p-2">
            <ng-container *ngIf="showDefaultButtons">
              <div class="mx-2">
                <button class="small-icon-button" mat-icon-button [ngClass]="node.myVote === 1 ? 'bg-green-400' : ''" (click)="emitVote(1)">
                  <mat-icon [inline]="true">thumb_up</mat-icon>
                </button>
              </div>

              <div [ngClass]="voteClass()" style="margin-top: 7px" class="mx-2 text-md">
                {{node.voteScore > 0 ? '+' : ''}}{{node.voteScore}}
              </div>

              <div class="mx-2">
                <button class="small-icon-button" mat-icon-button [ngClass]="node.myVote === -1 ? 'bg-red-500' : ''" (click)="emitVote(-1)">
                  <mat-icon [inline]="true">thumb_down</mat-icon>
                </button>
              </div>


              <mat-divider vertical></mat-divider>
              <div class="mx-2">
                <button class="small-button" mat-icon-button (click)="emitReply()">
                  <mat-icon [inline]="true">reply</mat-icon>
                  Répondre
                </button>
              </div>

            </ng-container>
            <ng-container *ngIf="!showDefaultButtons">
              <div [ngClass]="voteClass()" style="margin-top: 7px" class="mx-2 text-lg">
                {{node.voteScore > 0 ? '+' : ''}}{{node.voteScore}}
              </div>
            </ng-container>

            <div class="flex-grow"></div>

            <ng-container *ngIf="contextButtonDefinition && contextButtonDefinition.length > 0">
              <mat-divider vertical></mat-divider>
              <app-thread-context-actions [small]="true" [node]="node" (event)="emitContextSpecificEvent($event)" [buttonsDefinition]="contextButtonDefinition"></app-thread-context-actions>
            </ng-container>

          </div>

      </div>
      <mat-divider *ngIf="node.children.length"></mat-divider>
      <div *ngIf="!collapsed && node.children.length && (maxNestingLevel === undefined ||  nestingLevel < maxNestingLevel)" class="ml-4">
        <ng-container *ngFor="let child of node.children">
          <app-thread-message-tree [messageProcessor]="messageProcessor" [showDefaultButtons]="showDefaultButtons" [contextButtonDefinition]="contextButtonDefinition" [nestingLevel]="nestingLevel+1" [maxNestingLevel]="maxNestingLevel" (event)="forwardEvent($event)" [node]="child" ></app-thread-message-tree>
          <mat-divider></mat-divider>
        </ng-container>
      </div>
      <div *ngIf="node.children.length && maxNestingLevel !== undefined && nestingLevel >= maxNestingLevel" class="m-2">
        <button mat-raised-button color="primary" (click)="emitChangeRoot()">Voir {{node.children.length}} réponse{{node.children.length>1 ? 's' : ''}}</button>
      </div>
    </div>
  `
})
export class ThreadMessageTree implements OnInit {
  @Input()
  public node!: ThreadNode;

  @Input()
  public nestingLevel!: number;

  @Input()
  public maxNestingLevel!: number | undefined;

  @Input()
  public contextButtonDefinition!: MessageContextButtonDefinition[];

  @Output()
  public event = new EventEmitter<ThreadEvent>();

  @Input()
  public showDefaultButtons = true;

  @Input()
  public messageProcessor!: (node: ThreadNode) => string;

  public collapsed!: boolean;
  public mobileLayout!: boolean;

  constructor(private threadViewCacheService: ThreadViewCacheService) {}

  ngOnInit() {
    this.collapsed = this.threadViewCacheService.get(`message-collapsed:${this.node.id}`) ?? false;
    this.mobileLayout = window.screen.availWidth <= 760;
  }

  public voteClass() {
    const score = this.node.voteScore;
    if (score === 0) {
      return ["text-gray-500"];
    } else if (score > 0) {
      return ["text-green-400"];
    } else {
      return ["text-red-500"];
    }
  }

  public forwardEvent(event: ThreadEvent) {
    this.event.emit(event);
  }

  public emitReply() {
    this.event.emit({
      kind: ThreadEventKind.Reply,
      parentNodeId: this.node.id,
    });
  }

  public emitChangeRoot() {
    this.event.emit({
      kind: ThreadEventKind.ChangeRoot,
      rootNodeId: this.node.id,
    });
  }

  public emitVote(sign: number) {
    this.event.emit({
      kind: ThreadEventKind.Vote,
      nodeId: this.node.id,
      sign: sign,
    });
  }

  public emitContextSpecificEvent($eventKey: string) {
    this.event.emit({
      kind: ThreadEventKind.UnspecifiedContextAction,
      specificEventKey: $eventKey,
      node: this.node,
    });
  }


  public toggleCollapsed() {
    this.collapsed = !this.collapsed;
    this.threadViewCacheService.set(`message-collapsed:${this.node.id}`, this.collapsed);
  }
}
