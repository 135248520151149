<div class="card-content h-full">
  <div class="session-metadata dark-text">
    <div class="title" [routerLink]="'../'+session.sessionId+'/details'">{{session.sessionName}}</div>
    <div class="">{{session.sessionType}}</div>
    <div *ngIf="isValidQcm();else invalidDefinition">
      {{session.sessionDefinition.propositions.length}}
      proposition{{session.sessionDefinition.propositions.length > 1 ? 's' : ''}}
    </div>
    <div class="mt-1 text-md italic text-gray-500">
      Crée le {{session.creationDate | date:'dd/MM/YYYY'}}
    </div>
    <ng-template #invalidDefinition>
      <div class="error-text">
        La définition de cette session n'est pas valide.
      </div>
    </ng-template>
  </div>
  <ng-container *ngIf="editable">
    <mat-divider vertical></mat-divider>
    <div class="session-extract">
      <div [ngSwitch]="session.sessionState">
        <ng-container *ngSwitchCase="sessionState.Draft">
          <div class="primary-text">
            Brouillon
          </div>
          <app-session-start-button [session]="session" ></app-session-start-button>
        </ng-container>

        <ng-container *ngSwitchCase="sessionState.Open">
          <div class="primary-text">
            Ouvert à la connexion
          </div>
          <app-session-start-button [session]="session"></app-session-start-button>
        </ng-container>

        <ng-container *ngSwitchCase="sessionState.Started">
          <div class="primary-text">
            En cours
          </div>
          <app-session-close-button [session]="session"></app-session-close-button>
        </ng-container>

        <ng-container *ngSwitchCase="sessionState.Finished">
          <div class="m-2">
            <div class="primary-text">Terminée</div>
            <button mat-stroked-button color="primary" [routerLink]="'../' + session.sessionId + '/results'">Résultats</button>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
