import {Component, Input, OnInit, EventEmitter, Output, OnDestroy} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {ProblemAnalysisSession, Session} from "../../../../revogo-client/revogo-client.types";
import {SessionMindMapEntities} from "../session-details.component";
import {ThreadContextActionEvent, ThreadEvent, ThreadEventKind} from "../../../message-thread/thread.event";
import {LiveThreadService, ThreadNode, ThreadSortMode, ThreadView} from "../../../../live-thread/live-thread.service";
import {RevogoClientService} from "../../../../revogo-client/revogo-client.service";
import {MatDialog} from "@angular/material/dialog";
import {CreateProblemModalComponent} from "../../../mind-map/create-problem-modal.component";
import {OrganizationService} from "../../../../auth/organization.service";
import {MatSelectChange} from "@angular/material/select";

@Component({
  selector: 'app-problem-analysis-session-results',
  template: `
    <div class="m-2 p-2 border border-gray-500 rounded-sm whitespace-pre-line">
      {{session.sessionDefinition.statement}}
    </div>
    <mat-divider></mat-divider>
    <div class="m-2">
      <mat-form-field class="h-px h-32 message-thread-sort-select">
        <mat-label>Trier par</mat-label>
        <mat-select (selectionChange)="setSort($event)">
          <mat-option [value]="sortMode.RecentFirst">
            Plus récent
          </mat-option>
          <mat-option [value]="sortMode.BestScoreFirst">
            Meilleurs votes
          </mat-option>
          <mat-option [value]="sortMode.ControversialFirst">
            Controversé
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="m-2" *ngIf="threadPipe !== undefined">
      <app-message-thread [showDefaultButtons]="false" [thread]="threadPipe" (event)="handleThreadEvent($event)" [contextButtonDefinition]="contextButtonDefinition"></app-message-thread>
    </div>
    <div class="flex" *ngIf="loading">
      <div class="flex-grow"></div>
      <mat-spinner></mat-spinner>
      <div class="flex-grow"></div>
    </div>
  `
})
export class ProblemAnalysisSessionResultsComponent implements OnInit, OnDestroy {
  @Input()
  mindMapEntities!: Subject<SessionMindMapEntities>;

  @Input('session')
  _session!: Session;
  session!: ProblemAnalysisSession;

  @Input()
  public canWrite!: boolean;

  @Output()
  mindMapUpdate = new EventEmitter<boolean>();

  public sortMode = ThreadSortMode;

  public contextButtonDefinition = [
    {
      icon: 'note_add',
      eventKey: 'addProblem',
      label: 'Problème'
    }
  ];
  public threadPipe!: Observable<ThreadView | undefined>;
  public loading = true;
  public intervalId: any;
  public organizationId!: string;

  constructor(private liveThreadService: LiveThreadService,
              private revogoClient: RevogoClientService,
              private organizationService: OrganizationService,
              private dialog: MatDialog) {}

  async ngOnInit() {
    this.session = this._session as ProblemAnalysisSession;
    console.log(this.canWrite);
    if (!this.canWrite) {
      this.contextButtonDefinition = [];
    }
    const org = await this.organizationService.getSelectedOrganization().toPromise();
    if (org !== null) {
      this.organizationId = org.organizationId;
    }
    this.threadPipe = await this.liveThreadService.bind(this.session.sessionId);
    this.liveThreadService.setSort(ThreadSortMode.RecentFirst);
    await this.liveThreadService.synchronize();
    this.loading = false;
    this.intervalId = setInterval(async () => this.liveThreadService.synchronize(), 5000);
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  public openCreateProblemModal(node: ThreadNode) {
    const ref = this.dialog.open(CreateProblemModalComponent, {panelClass: 'wide-modal'});
    ref.componentInstance.setBody('', node.message);

    ref.afterClosed().subscribe(async (value) => {
      if (value !== null) {
        const body = {...value, session_id: this.session.sessionId};
        await this.revogoClient.createMindMapProblem(this.organizationId, body).toPromise();
        this.mindMapUpdate.emit(true);
      }
    });
  }

  public handleThreadEvent($event: ThreadEvent) {
    switch ($event.kind) {
      case (ThreadEventKind.UnspecifiedContextAction):
        const contextEvent = $event as ThreadContextActionEvent;
        switch (contextEvent.specificEventKey) {
          case ('addProblem'):
            this.openCreateProblemModal(contextEvent.node);
            break
        }
        break;
    }
  }

  public setSort($event: MatSelectChange) {
    this.liveThreadService.setSort($event.value);
  }
}
