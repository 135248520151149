import {Component, Input, OnInit} from "@angular/core";
import {Session, SessionType} from "../../../revogo-client/revogo-client.types";
import {MindMapRepository} from "../session-list/session-list.component";
import {Observable} from "rxjs";
import {OrganizationService} from "../../../auth/organization.service";


@Component({
  selector: 'app-session-card',
  templateUrl: './session-card.component.html',
  styleUrls: ['./session-card.component.sass']
})
export class SessionCardComponent implements OnInit {
  @Input()
  public session!: Session;
  @Input()
  public mindMapRepository!: Observable<MindMapRepository>;
  public sessionType = SessionType;
  public canWrite!: boolean;

  constructor(private organizationService: OrganizationService){}

  async ngOnInit() {
    this.canWrite = await this.organizationService.userHasRole(['manager']);
  }
}
