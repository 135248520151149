import {Component, OnInit} from "@angular/core";
import {STATE} from "../../app-states";
import {StateRouterService} from "../../../state-router/state-router.service";
import {Session} from "../../../revogo-client/revogo-client.types";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-session-page',
  template: `
    <div class="m-4 bg-purple-50 border border-purple-500 rounded-sm p-2 shadow-md" *ngIf="session">
      <div class="text-lg">
        Session : <span class="primary-text">{{session.sessionName}}</span>
      </div>
      <div class="mt-2 flex">
        <div class="mx-1" *ngIf="session.sessionState === 'started'">
          <button mat-raised-button color="primary" [routerLink]="'/play/'+session.sessionId">
            Participer
          </button>
        </div>
        <div class="mx-1">
          <button  mat-stroked-button color="primary" routerLink="./details">Définition</button>
        </div>
        <div class="mx-1">
          <button mat-stroked-button color="primary" routerLink="./results" [disabled]="session.sessionState !== 'started' && session.sessionState !== 'finished'">Résultats</button>
        </div>
      </div>
    </div>
    <router-outlet></router-outlet>
  `,
})
export class SessionPageComponent implements OnInit {
  session!: Session;

  constructor (private route: ActivatedRoute, private stateRouterService: StateRouterService) {}

  ngOnInit() {
    this.session = this.route.snapshot.data.session;
    const path = this.stateRouterService.path();
    if ([STATE.SESSIONS_DETAILS, STATE.SESSIONS_RESULTS].indexOf(path[path.length - 1] as STATE) === -1) {
      this.stateRouterService.nav(STATE.SESSIONS, STATE.SESSIONS_LIST);
    } else {
      this.session = this.route.snapshot.data.session;
    }
  }
}
