import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ConfigService} from "../environments/config.service";
import {StateRouterService} from "../state-router/state-router.service";
import {STATE} from "../app/app-states";

type GuestPlayerToken = {
  tokenId: string,
  sessionId: string,
};

type GuestAuthCache = {
  [key: string]: GuestPlayerToken
}

@Injectable(
  {providedIn: 'root'}
)
export class GuestAuthService {
  private tokenStorageKey = "guestToken";
  private cache!: GuestAuthCache;
  private boundInviteSlug: string | undefined = undefined;

  constructor(private http: HttpClient, private config: ConfigService) {
    `
      The cache stores a map of {invite_slug: stored_token}
      This is used to decide what to do when resolving an invite link
    `
    this.cache = this.getCache();
    this.saveCache();

    this.attemptSessionResume();
  }

  public attemptSessionResume() {
    const path = window.location.href.split('/');
    const playIndex = path.indexOf(STATE.PLAY);
    if (playIndex !== -1 && playIndex + 1 < path.length) {
      const sessionId = path[playIndex + 1].split('?')[0];
      console.log('trying to resume', sessionId);
      this.bindToExistingSessionToken(sessionId);
    }
  }

  public bound(): boolean {
    return this.boundInviteSlug !== undefined;
  }

  public bindToInvitation(inviteSlug: string) {
    this.boundInviteSlug = inviteSlug;
    console.log(`bound to ${inviteSlug}`);
  }

  public bindToExistingSessionToken(sessionId: string) {
    const cache = this.getCache();
    console.log(cache);
    for (let slug in cache) {
      if (cache.hasOwnProperty(slug)) {
        const token = cache[slug];
        if (token.sessionId === sessionId) {
          console.log('bound to session');
          return this.bindToInvitation(slug);
        }
      }
    }
  }

  public unbind() {
    this.boundInviteSlug = undefined;
  }

  private static encode(token: GuestPlayerToken): string {
    return btoa(JSON.stringify(token));
  }

  public clearCache() {
    console.log(`Cache cleared`);
    localStorage.removeItem(this.tokenStorageKey);
  }

  public saveCache() {
    // console.log(`Guest auth cache set with token: ${JSON.stringify(token)}`);
    localStorage.setItem(this.tokenStorageKey, JSON.stringify(this.cache));
  }

  public getCache(): GuestAuthCache {
    const cacheValue = localStorage.getItem(this.tokenStorageKey);
    if (cacheValue === null) {
      return {};
    } else {
      let decoded;
      try {
        decoded = JSON.parse(cacheValue);
      } catch (e) {
        return {};
      }
      if (typeof decoded !== "object") {
        return {}
      } else {
        for (var prop in decoded) {
          if (decoded.hasOwnProperty(prop)) {
            if (typeof decoded[prop].tokenId !== 'string' || typeof decoded[prop].sessionId !== 'string') {
              return {};
            }
          }
        }
        return decoded;
      }
    }
  }

  public getAuthorizationHeader(): string | null {
    if (this.boundInviteSlug === undefined) {
      return null;
    }
    const stored = this.getCache();
    const cachedToken = stored[this.boundInviteSlug];
    if (cachedToken === undefined) {
      return null;
    } else {
      return GuestAuthService.encode(cachedToken);
    }
  }

  public getSessionId(): string | null {
    if (this.boundInviteSlug === undefined) {
      return null;
    }
    const cache = this.getCache();
    const token = cache[this.boundInviteSlug];
    if (token !== undefined) {
      return token.sessionId;
    } else {
      return null;
    }
  }

  public getTokenId(): string | null {
    if (this.boundInviteSlug === undefined) {
      return null;
    }
    const cache = this.getCache();
    const token = cache[this.boundInviteSlug];
    if (token !== undefined) {
      return token.tokenId;
    } else {
      return null;
    }
  }

  public async resolveInvitation(inviteSlug: string): Promise<GuestPlayerToken> {
    const apiUrl = this.config.getConfig().API_URL;
    return await this.http.get<GuestPlayerToken>(
      `${apiUrl}/session_invite/${inviteSlug}`,
      {
        headers: new HttpHeaders({Authorization: btoa(JSON.stringify({}))})
      }).toPromise().then(token => {
        this.cache[inviteSlug] = token;
        this.saveCache();
        return token;
      });
  }
}
