<div *ngIf="session.sessionState !== 'started' && session.sessionState !== 'finished'; else content;">
  Impossible de montrer les résultats : la session n'a pas commencé.
</div>
<ng-template #content>
  <div *ngIf="session && results && !loading" class="m-8">
    <div class="m-4 text-lg primary-text">
      {{results.participantCount}} participants
    </div>
    <div *ngFor="let proposition of session.sessionDefinition.propositions; let pI = index"
         class="my-2 border border-gray-500 rounded-sm flex-col shadow">
      <div class="text-lg m-2 flex">
        <div>
          <span class="primary-text">{{pI + 1}}.&nbsp;</span>
          {{proposition.title}}
          <span class="primary-text">&nbsp;
            {{results.propositions[pI].participantCount}} réponses
            ({{100 * results.propositions[pI].participantCount / results.participantCount | number:'1.0-0'}}%)
          </span>
        </div>
        <div class="flex-grow"></div>
        <div *ngIf="canWrite">
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="createProblemModal(pI)">
              <mat-icon>note_add</mat-icon>
              <span>Problème</span>
            </button>
          </mat-menu>
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon class="cursor-pointer">more_vert</mat-icon>
          </button>

        </div>

      </div>
      <div>
        <div *ngFor="let answer of proposition.allowedAnswers; let aI = index"
             class="m-2 p-2 mx-4 flex-column border rounded-sm h-full">
          <div class="flex">
            <div>{{answer.label}} :</div>
            <div class="primary-text">
              &nbsp;{{results.propositions[pI].answers[aI].count}}
              vote{{results.propositions[pI].answers[aI].count > 1 ? 's' : ''}}
              ({{100 * results.propositions[pI].answers[aI].count / results.propositions[pI].participantCount | number:'1.0-0'}}
              %)
            </div>
          </div>

          <div class="ml-2 text-md italic" *ngIf="answer.type === 'FREE_TEXT'">
            <div *ngFor="let item of textAnswers[pI][aI]">
              <span>- {{item.text}} :&nbsp;</span>
              <span class="primary-text">{{item.count}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="loading" class="m-8">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
