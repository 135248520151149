<div id="session-list-outermost-container">
  <div class="session-list-header">
    <div class="page-title">Mes sessions</div>
    <div class="spacer"></div>
    <div *ngIf="canWrite">
      <button mat-raised-button color="primary" [routerLink]="'../' + state.SESSIONS_NEW">Nouvelle session</button>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="flex">
    <div class="flex-grow"></div>
    <div class="flex-grow max-w-screen-md" *ngIf="mindMapRepository !== undefined">
      <div class="session-cards-container" *ngIf="sessions.length > 0; else noSessions">
        <div class="session-card border rounded-sm bg-purple-50 border-purple-500 mb-4" *ngFor="let session of sessions">
          <app-session-card [session]="session" [mindMapRepository]="mindMapRepository"></app-session-card>
        </div>
      </div>
      <ng-template #noSessions>
        <div class="dark-text">Aucune session à afficher</div>
      </ng-template>
    </div>
    <div class="flex-grow"></div>
  </div>

</div>

