<div class="card-content h-full">
  <div class="session-metadata dark-text">
    <div class="title" [routerLink]="'../'+session.sessionId+'/details'">{{session.sessionName}}</div>
    <div>{{sessionTypes[session.sessionType]}}</div>
    <div *ngIf="isValidProblemAnalysisSession();else invalidDefinition">
      "{{session.sessionDefinition.statement | slice:0:70}}{{session.sessionDefinition.statement.length > 70 ? '...' : ''}}
      "
    </div>
    <div class="mt-1 text-md italic text-gray-500">
      Crée le {{session.creationDate | date:'dd/MM/YYYY'}}
    </div>
    <ng-template #invalidDefinition>
      <div class="error-text">
        La définition de cette session n'est pas valide.
      </div>
    </ng-template>
  </div>
  <ng-container *ngIf="editable">
    <mat-divider vertical></mat-divider>
    <div class="session-extract">
      <div [ngSwitch]="session.sessionState">
        <div *ngSwitchCase="sessionState.Draft" class="mx-2">
          <div class="primary-text">
            Brouillon
          </div>
          <app-session-start-button [session]="session"></app-session-start-button>
        </div>

        <div *ngSwitchCase="sessionState.Open" class="mx-2">
          <div class="primary-text">
            Ouvert à la connexion
          </div>
          <app-session-start-button [session]="session"></app-session-start-button>
        </div>

        <div class="mx-2" *ngSwitchCase="sessionState.Started">
          <div class="primary-text">
            En cours
          </div>
          <app-session-close-button [session]="session"></app-session-close-button>
        </div>

        <div class="mx-2" *ngSwitchCase="sessionState.Finished">
          <div class="primary-text">Terminée</div>
          <button mat-stroked-button color="primary" [routerLink]="'../' + session.sessionId + '/results'">Résultats
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<div *ngIf="session.parentMindMapNode !== undefined" class="bg-yellow-200 cursor-pointer" mat-ripple [routerLink]="parentProblem !== undefined ? '/problems/'+parentProblem.id : ''">
  <div class="m-2">
    Créée à partir de :
    <span *ngIf="parentProblem !== undefined" class="primary-text italic">
      {{parentProblem.title}}
    </span>
  </div>
</div>
