export enum STATE {
  ROOT = "",
  SESSIONS = "sessions",
  SESSIONS_LIST = "list",
  SESSIONS_DETAILS = "details",
  SESSIONS_NEW = "new",
  SESSIONS_RESULTS = "results",
  LOGIN = "login",
  SIGNUP = "signup",
  ORGANIZATION_SELECT = "organizations",
  JOIN = "join",
  PLAY = "play",
  PLAY_END = "end",
  PLAY_QCM = "qcm",
  PLAY_UNAUTHORIZED = "unauthorized",
  PROBLEMS = "problems",
  PROBLEMS_LIST = "list",
  SOLUTIONS = "solutions",
  ARGUMENTS = "arguments",
  CONFIRM_REGISTRATION = "confirm_registration",
  FORGOT_PASSWORD = "forgot_password",
  ADMIN_PANEL = "admin"
}

export function absStateUrl(path: (STATE | string)[]): string {
  let url = "";
  path.forEach(element => {
    url = url + "/" + element
  });
  return url;
}
