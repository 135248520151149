import {NgModule} from "@angular/core";
import {RevogoClientService} from "./revogo-client.service";
import {
  ArgumentResolve,
  OrganizationSessionsResolve,
  ProblemResolve,
  SessionResolve,
  SolutionResolve
} from "./revogo-client.resolvers";
import {QcmSessionService} from "./qcm-session.service";
import {QcmSessionResultsService} from "./qcm-session-results.service";

@NgModule({
  providers: [
    RevogoClientService,
    QcmSessionService,
    OrganizationSessionsResolve,
    SessionResolve,
    QcmSessionResultsService,
    ProblemResolve,
    SolutionResolve,
    ArgumentResolve,
  ],
})
export class RevogoClientModule {
}
