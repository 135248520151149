import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {STATE} from './app-states';
import {LOGIN_STATE} from './login/login.module';
import {CONFIRM_REGISTRATION_STATE, FORGOT_PASSWORD_STATE, SIGNUP_STATE} from './signup/signup.module';
import {ORGANIZATION_SELECT_STATE} from "./organizations/organizations.module";
import {SESSIONS_STATE} from "./sessions/sessions.module";
import {JOIN_STATE} from "./join/join.module";
import {PLAY_STATE} from "./play/play.module";
import {PROBLEMS_STATE} from "./problems/problems.module";
import {ADMIN_PANEL_STATE} from "./admin/admin.module";

const ROOT_STATE = {
  path: STATE.ROOT,
  pathMatch: "full",
  redirectTo: STATE.LOGIN
};

const routes: Routes = [
  ROOT_STATE,
  LOGIN_STATE,
  SIGNUP_STATE,
  CONFIRM_REGISTRATION_STATE,
  ORGANIZATION_SELECT_STATE,
  FORGOT_PASSWORD_STATE,
  SESSIONS_STATE,
  JOIN_STATE,
  PLAY_STATE,
  PROBLEMS_STATE,
  ADMIN_PANEL_STATE,
];

@NgModule({
  providers: [],
  imports: [RouterModule.forRoot(routes, {enableTracing: false, scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
