import {Component, OnInit} from "@angular/core";
import {Session, SessionType} from "../../revogo-client/revogo-client.types";
import {ActivatedRoute, Router} from "@angular/router";
import {StateRouterService} from "../../state-router/state-router.service";
import {RevogoClientService} from "../../revogo-client/revogo-client.service";
import {HttpErrorResponse} from "@angular/common/http";
import {GuestAuthService} from "../../auth/guest-auth.service";


@Component({
  selector: 'app-play',
  templateUrl: './play.component.html',
  styleUrls: ['./play.component.sass']
})
export class PlayComponent implements OnInit {
  public session!: Session;
  public sessionType = SessionType;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private revogoClient: RevogoClientService,
    private guestAuthService: GuestAuthService,
  ) {
    this.session = this.route.snapshot.data.session;
  }

  async ngOnInit() {
    console.log('play component init');
    this.guestAuthService.bindToExistingSessionToken(this.session.sessionId);
    await this.revogoClient.getUserSessionEntities(this.session.sessionId).toPromise().catch((error: HttpErrorResponse) => {
      console.log("ERROR IN PLAY REDIRECTING TO LOGIN");
      console.log(error);
      this.router.navigate(['/login'], {queryParams: {from: this.route.snapshot.params['sessionId']}});
    });
  }
}
