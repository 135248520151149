import {Component, Input, OnInit} from "@angular/core";
import {Session, SessionType} from "../../../revogo-client/revogo-client.types";
import {ActivatedRoute} from "@angular/router";
import {Subject} from "rxjs";
import {SessionMindMapEntities} from "./session-details.component";
import {OrganizationService} from "../../../auth/organization.service";
import {RevogoClientService} from "../../../revogo-client/revogo-client.service";

@Component({
  selector: 'app-session-results',
  template: `
    <div class="m-4" *ngIf="session.anonymousInvitation.tokenCount > 0 && session.claimedTokenCount !== undefined">
      {{session.claimedTokenCount}} / {{session.anonymousInvitation.tokenCount}} jetons ont été utilisés
    </div>
    <div class="mx-4 border border-gray-500 rounded-sm">
      <app-session-mind-map-entities [mindMapEntities]="mindMapEntities" [session]="session"></app-session-mind-map-entities>
    </div>
    <ng-container *ngIf="canWrite !== undefined" [ngSwitch]="session.sessionType">
      <app-qcm-session-results [canWrite]="canWrite" (mindMapUpdate)="refreshMindMapEntities()" [mindMapEntities]="mindMapEntities" *ngSwitchCase="sessionType.QCM" [session]="session"></app-qcm-session-results>
      <app-problem-analysis-session-results [canWrite]="canWrite" (mindMapUpdate)="refreshMindMapEntities()" [mindMapEntities]="mindMapEntities" *ngSwitchCase="sessionType.ProblemAnalysis" [session]="session"></app-problem-analysis-session-results>
      <app-problem-analysis-session-results [canWrite]="canWrite" (mindMapUpdate)="refreshMindMapEntities()" [mindMapEntities]="mindMapEntities" *ngSwitchCase="sessionType.SolutionGeneration" [session]="session"></app-problem-analysis-session-results>
    </ng-container>
  `
})
export class SessionResultsComponent implements OnInit {
  public session!: Session;

  public mindMapEntities = new Subject<SessionMindMapEntities>();
  public sessionType = SessionType;
  public canWrite!: boolean;

  constructor (private route: ActivatedRoute,
               private organizationService: OrganizationService,
               private revogoClient: RevogoClientService) {}

  async ngOnInit() {
    this.session = this.route.snapshot.data.session;
    this.canWrite = await this.organizationService.userHasRole(['manager']);
    await this.refreshMindMapEntities();
  }

  public async refreshMindMapEntities() {
    const organization = await this.organizationService.getSelectedOrganization().toPromise();
    if (organization === null) {
      this.mindMapEntities.next({problems: [], solutions: []});
    } else {
      const problems = await this.revogoClient.getMindMapOrganizationProblems(organization.organizationId).toPromise();
      const solutions = await this.revogoClient.getMindMapOrganizationSolutions(organization.organizationId).toPromise();
      this.mindMapEntities.next({
        problems: problems.filter(problem => problem.sessionId === this.session.sessionId),
        solutions: solutions.filter(solution => solution.sessionId === this.session.sessionId)
      });
    }
  }
}
