<div class="m-4">
  <button mat-stroked-button class="text-lg primary-text" (click)="confirmQuit()" >Retour</button>
</div>
<div class="m-4">
  <div id="session-metadata-block">
    <app-session-metadata [canWrite]="canWrite" [session]="session"></app-session-metadata>
  </div>
  <div class="my-4">
    <mat-divider></mat-divider>
  </div>
  <div id="session-content-block">
    <app-session-content (dirty)="setDirty($event)" [session]="session"></app-session-content>
  </div>
</div>
