import {Component, Input, OnInit, EventEmitter, Output} from "@angular/core";
import {
  QcmPropositionAnswerType,
  QcmSession,
  QcmSessionResults,
  Session,
  SessionState
} from "../../../../revogo-client/revogo-client.types";
import {QcmSessionResultsService} from "../../../../revogo-client/qcm-session-results.service";
import {CreateProblemModalComponent} from "../../../mind-map/create-problem-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {RevogoClientService} from "../../../../revogo-client/revogo-client.service";
import {OrganizationService} from "../../../../auth/organization.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {SessionMindMapEntities} from "../session-details.component";
import {Subject} from "rxjs";

@Component({
  selector: 'app-qcm-session-results',
  templateUrl: './qcm-session-results.component.html',
  styleUrls: ['./qcm-session-results.component.sass']
})
export class QcmSessionResultsComponent implements OnInit {
  @Input('session')
  _session!: Session;

  @Input()
  mindMapEntities!: Subject<SessionMindMapEntities>;

  @Input()
  public canWrite!: boolean;

  @Output()
  mindMapUpdate = new EventEmitter<boolean>();

  public session!: QcmSession;
  public results: QcmSessionResults | undefined = undefined;
  public textAnswers!: { [key: number]: { [key: number]: { text: string, count: number }[] } };
  public loading!: boolean;

  private intervalId!: any;

  constructor(private resultsService: QcmSessionResultsService,
              private dialog: MatDialog,
              private revogoClient: RevogoClientService,
              private organizationService: OrganizationService,
              private snackbar: MatSnackBar) {
  }

  async ngOnInit() {
    this.session = this._session as QcmSession;
    if (this.session.sessionState === SessionState.Started || this.session.sessionState === SessionState.Finished) {
      this.loading = true;
      await this.gatherResults();
      this.intervalId = setInterval(async () => await this.gatherResults(), 10000);
    }
  }

  public async gatherResults() {
    this.results = await this.resultsService.getSessionResults(this.session.sessionId);
    if (this.results !== undefined) {
      this.textAnswers = {};
      this.results.propositions.forEach((proposition, pI) => {
        const propData: { [key: number]: { text: string, count: number }[] } = {};
        proposition.answers.forEach((answer, aI) => {
          let answerData: { text: string, count: number }[] = [];
          for (let key in answer.text) {
            if (answer.text.hasOwnProperty(key)) {
              answerData.push({text: key, count: answer.text[key]});
            }
          }
          answerData = answerData.sort((a, b) => (b.count - a.count));
          propData[aI] = answerData;
        });
        this.textAnswers[pI] = propData;
      });
    }
    this.loading = false;
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  public createProblemModal(propositionIndex: number) {
    if (this.results !== undefined) {
      const descriptionParts: string[] = [];
      this.results.propositions[propositionIndex].answers.forEach((answerResults, answerIndex) => {
        descriptionParts.push(`- ${answerResults.count} participant${answerResults.count > 1 ? 's' : ''} ont répondu "${this.session.sessionDefinition.propositions[propositionIndex].allowedAnswers[answerIndex].label}"`);
      });

      const dialogRef = this.dialog.open(CreateProblemModalComponent, {panelClass: ['wide-modal']});
      dialogRef.afterClosed().subscribe(async result => {
        if (result !== null) {
          const organization = await this.organizationService.getSelectedOrganization().toPromise();
          if (organization !== null) {
            await this.revogoClient.createMindMapProblem(organization.organizationId, {
              session_id: this.session.sessionId,
              ...result,
            }).toPromise();
            this.snackbar.open('Ce problème a bien été crée', undefined, {
              duration: 3000
            });
            this.mindMapUpdate.emit(true);
          }
        }
      });
      dialogRef.componentInstance.setBody('', `A la proposition "${this.session.sessionDefinition.propositions[propositionIndex].title}"\n${descriptionParts.join('\n')}`);
    }
  }

}
