import {Component, OnInit} from "@angular/core";
import {Location} from '@angular/common';
import {ActivatedRoute} from "@angular/router";
import {MindMapProblem, MindMapSolution} from "../../revogo-client/revogo-client.types";
import {RevogoClientService} from "../../revogo-client/revogo-client.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {GenericConfirmationModalComponent} from "../../modals/generic-confirmation-modal.component";
import {STATE} from "../app-states";
import {StateRouterService} from "../../state-router/state-router.service";
import {OrganizationService} from "../../auth/organization.service";

@Component({
  selector: 'app-solution-details',
  template: `
    <div class="m-2 mx-8">
      <div class="flex m-2">
        <button mat-stroked-button (click)="location.back()" color="primary">Retour</button>
        <div class="text-xl primary-text m-2">Solution</div>
      </div>
      <mat-divider></mat-divider>
      <div class="m-2 border rounded-sm border-green-500 bg-green-50 shadow-lg shadow-inner">
        <div class="m-2 px-2 w-full">
          <ng-container *ngIf="!editMode; else titleEdit">
            <div class="mx italic text-xs text-gray-500">Titre</div>
            <div class="mx-2 text-lg">
              {{solution.title}}
            </div>
          </ng-container>
          <ng-template #titleEdit>
            <mat-form-field class="w-full">
              <mat-label>Titre</mat-label>
              <input class="w-full" type="text" matInput [(ngModel)]="solution.title">
            </mat-form-field>
          </ng-template>
        </div>
        <mat-divider></mat-divider>
        <div class="m-2 px-2 w-full">
          <ng-container *ngIf="!editMode; else descriptionEdit">
            <div class="mx italic text-xs text-gray-500">Description</div>
            <div class="mx-2">
              {{solution.description}}
            </div>
          </ng-container>
          <ng-template #descriptionEdit>
            <mat-form-field class="w-full">
              <mat-label>Description</mat-label>
              <textarea matInput cols="60" rows="10" [(ngModel)]="solution.description"></textarea>
            </mat-form-field>
          </ng-template>

        </div>
        <mat-divider></mat-divider>
        <div class="m-2">
          <div class="mx italic text-xs text-gray-500">Créée le {{solution.creationDate | date:dateFormat}}</div>
          <div class="mx italic text-xs text-gray-500" *ngIf="solution.establishmentDate === null; else establishmentDateField">
            Cette solution n'a pas encore été appliquée
          </div>
          <ng-template #establishmentDateField>
            <div class="mx italic text-xs text-gray-500">
              Marquée comme étant mise en oeuvre le {{solution.establishmentDate | date:dateFormat}}
            </div>
          </ng-template>
        </div>
        <ng-container *ngIf="parentProblem !== undefined; else problemLoading">
          <mat-divider></mat-divider>
          <div class="bg-yellow-100 py-2 cursor-pointer" mat-ripple [routerLink]="'/problems/'+parentProblem.id">
            <div class="px-2 italic text-xs text-gray-500">
              À partir du problème
            </div>
            <div class="px-4 text-lg">
              {{parentProblem.title}}
            </div>
          </div>
        </ng-container>

        <ng-template #problemLoading>
          <div class="flex p-2 bg-yellow-100">
            <div class="flex-grow"></div>
            <mat-spinner [diameter]="32"></mat-spinner>
            <div class="flex-grow"></div>
          </div>
        </ng-template>
      </div>

      <div class="mx-2">
        <div class="my-2 flex">
          <ng-container *ngIf="editable">
            <button mat-stroked-button color="primary" (click)="markAppliedModal()">
              <mat-icon>done</mat-icon>
              Marquer comme appliquée
            </button>
            <div class="flex-grow"></div>
            <button *ngIf="!editMode; else saveChangesButton" mat-stroked-button color="primary" (click)="toggleEditMode()">
              <mat-icon>edit</mat-icon>
              Modifier
            </button>
            <ng-template #saveChangesButton>
                <button mat-raised-button color="primary" (click)="toggleEditMode()">
                  <mat-icon>save</mat-icon>
                  Sauvegarder
                </button>
            </ng-template>
          </ng-container>
        </div>
        <div class="my-2 flex" *ngIf="canWrite">
          <button mat-stroked-button color="error" (click)="deleteModal()">
            <mat-icon>delete</mat-icon>
            Supprimer
          </button>
        </div>
      </div>


      <mat-divider></mat-divider>

      <app-argument-explorer [editable]="editable" [rootNodeId]="'solution:'+solution.id" [organizationId]="solution.organizationId"></app-argument-explorer>

    </div>
  `
}) export class SolutionDetailsComponent implements OnInit {
  public solution!: MindMapSolution;
  public editable!: boolean;
  public dateFormat = 'dd/MM/YYYY à HH:mm'
  public parentProblem!: MindMapProblem;
  public editMode = false;
  public canWrite!: boolean;


  constructor (
    private route: ActivatedRoute,
    public location: Location,
    private revogoClient: RevogoClientService,
    private snackbar: MatSnackBar,
    private dialog: MatDialog,
    private stateRouter: StateRouterService,
    private organizationService: OrganizationService,
  ) {}

  async ngOnInit() {
    this.canWrite = await this.organizationService.userHasRole(['manager']);
    this.solution = this.route.snapshot.data.solution;
    this.updateState();
    await this.getParentProblem();
  }

  private updateState() {
    this.editable = (this.solution.establishmentDate === null || this.solution.establishmentDate === undefined) && this.canWrite;
  }

  async getParentProblem() {
    this.parentProblem = await this.revogoClient.getMindMapProblem(this.solution.organizationId, this.solution.problemId).toPromise();
  }

  public async toggleEditMode() {
    if (this.editMode) {
      await this.revogoClient.updateMindMapSolution(this.solution.organizationId, this.solution.id, {
        title: this.solution.title,
        description: this.solution.description,
      }).toPromise();
      this.snackbar.open('Vos modifications ont été sauvegardées', undefined, {duration: 3000});
    }
    this.editMode = !this.editMode;
  }

  public async markAppliedModal() {
    const ref = this.dialog.open(GenericConfirmationModalComponent, {panelClass: 'wide-modal'});
    ref.componentInstance.title = 'Marquer la solution comme étant mise en oeuvre ?';
    ref.componentInstance.textLines = [
      'Cela signifie que vous avez pris les actions nécéssaires pour appliquer cette solution dans votre organisation.',
      'Une fois ce choix confirmé, vous ne pourrez plus modifier cette solution ni les arguments qui lui sont liés.',
      'Ces données resteront consultables. Veillez à bien détailler la mise en oeuvre de cette solution dans sa description.'
    ];
    ref.afterClosed().subscribe(async confirmed => {
      if (confirmed) {
        const result = await this.revogoClient.updateMindMapSolution(this.solution.organizationId, this.solution.id, {
          establishmentDate: 'now'
        }).toPromise();
        this.snackbar.open('Vos modifications ont été sauvegardées', undefined, {duration: 3000});
        this.solution = result;
        this.updateState();
      }
    });
  }

  public async deleteModal() {
    const ref = this.dialog.open(GenericConfirmationModalComponent, {panelClass: 'wide-modal'});
    ref.componentInstance.title = 'Supprimer cette solution ?'
    ref.componentInstance.textLines = [
      'Cela supprimera également tout les arguments liés et leurs enfants.',
      'Vous ne pourrez pas annuler cette action.'
    ];
    ref.afterClosed().subscribe(async confirmed => {
      if (confirmed) {
        await this.revogoClient.deleteMindMapSolution(this.solution.organizationId, this.solution.id).toPromise();
        this.snackbar.open('Cette solution a bien été supprimée', undefined, {duration: 3000});
        await this.stateRouter.nav(STATE.PROBLEMS, this.solution.problemId);
      }
    });
  }
}
