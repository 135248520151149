import {Component, OnInit} from '@angular/core';
import {AuthService} from 'src/auth/auth.service';
import {GuestAuthService} from "../auth/guest-auth.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements OnInit {
  constructor(private authService: AuthService) {
  }

  async ngOnInit() {
    await this.authService.syncSession();
  }
}
