import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {QcmPropositionAnswerType} from "../../revogo-client/revogo-client.types";

@Component({
  selector: 'app-qcm-answer-type',
  template: `{{displayName}}`
})
export class QcmAnswerTypeComponent implements OnInit, OnChanges {
  @Input()
  public type!: QcmPropositionAnswerType
  public displayName!: string;

  ngOnInit() {
    this.ngOnChanges();
  }

  ngOnChanges() {
    this.displayName = {
      [QcmPropositionAnswerType.Choice]: 'Choix',
      [QcmPropositionAnswerType.FreeText]: 'Texte libre'
    }[this.type];
  }
}
