import {Component, Inject, Input, OnInit} from "@angular/core";
import {SessionParticipationMode, SessionType} from "../../revogo-client/revogo-client.types";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MatSelectChange} from "@angular/material/select";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'create-session-from-node-modal',
  template: `
    <h2 mat-dialog-title>Créer un session</h2>
    <mat-dialog-content class="mat-typography"></mat-dialog-content>

      <div class="m-4">
        <mat-form-field>
          <mat-label>Type de session</mat-label>
          <mat-select [(ngModel)]="selectedSessionType" (selectionChange)="buildForm($event)">
            <mat-option *ngFor="let sessionType of allowedSessionTypes" [value]="sessionType">
                {{this.sessionTypeDisplayName[sessionType]}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    <form [formGroup]="form">
      <div class="m-4">
        <mat-form-field class="w-full">
          <mat-label>Nom de la session</mat-label>
          <textarea formControlName="sessionName" matInput rows="2"></textarea>
        </mat-form-field>
      </div>

      <div class="m-4">
        <mat-form-field class="w-full">
          <mat-label>Mode de participation</mat-label>
          <mat-select formControlName="participationMode">
            <mat-option *ngFor="let allowedParticipationMode of allowedParticipationModes" [value]="allowedParticipationMode">
                {{participationModeDisplayName[allowedParticipationMode]}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="m-4" *ngIf="form.controls.problemStatement !== undefined">
        <mat-form-field class="w-full">
          <mat-label>Description du problème</mat-label>
          <textarea rows="10" matInput formControlName="problemStatement"></textarea>
        </mat-form-field>
      </div>

      <p>Une fois créée, vous serez redirigé vers la page de la session.</p>
      <p>Vous pourrez modifier son contenu et ses réglages.</p>

    </form>
    <mat-dialog-actions class="flex">
      <button mat-stroked-button color="primary" [mat-dialog-close]="null">Annuler</button>
      <div class="flex-grow"></div>
      <button mat-raised-button color="primary" [disabled]="!form.valid" [mat-dialog-close]="payload()">Confirmer</button>
    </mat-dialog-actions>
  `
}) export class CreateSessionFromNodeModalComponent implements OnInit {
  parentNodeType!: string;
  prefill!: {[key: string]: string};

  public sessionTypeDisplayName = {
    [SessionType.QCM]: 'QCM',
    [SessionType.ProblemAnalysis]: 'Approfondir un problème',
    [SessionType.SolutionGeneration]: 'Chercher des solutions'
  };
  public allowedSessionTypes!: SessionType[];
  public selectedSessionType!: SessionType;
  public allowedParticipationModes = [SessionParticipationMode.AnonymousInvitation, SessionParticipationMode.AuthenticatedUsers];
  public participationModeDisplayName = {
    [SessionParticipationMode.AnonymousInvitation]: 'Invitation anonyme',
    [SessionParticipationMode.AuthenticatedUsers]: 'Utilisateurs enregistrés',
  };
  public form!: FormGroup;

  constructor(public dialogRef: MatDialogRef<CreateSessionFromNodeModalComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {parentNodeType: string, prefill: {[key: string]: string}}) {
    this.prefill = data.prefill;
    this.parentNodeType = data.parentNodeType;
  }

  ngOnInit() {
    const allowedSessionTypesByNodeType: {[key: string]: SessionType[]} = {
      'problem': [
        SessionType.ProblemAnalysis,
        SessionType.SolutionGeneration,
        SessionType.QCM,
      ],
      'solution': [
        SessionType.QCM,
      ],
    };
    this.allowedSessionTypes = allowedSessionTypesByNodeType[this.parentNodeType];
    this.selectedSessionType = this.allowedSessionTypes[0];
    this.buildForm();
  }

  public buildForm($event: MatSelectChange | undefined=undefined) {
    const selectedType = $event?.value ?? this.selectedSessionType;
    this.form = new FormGroup({
      sessionName: new FormControl(this.prefill.sessionName ?? '', Validators.required),
      participationMode: new FormControl(SessionParticipationMode.AnonymousInvitation, Validators.required),
    })
    switch (selectedType) {
      case SessionType.ProblemAnalysis:
      case SessionType.SolutionGeneration:
        this.form.addControl('problemStatement', new FormControl(this.prefill.problemStatement ?? '', Validators.required));
        break
    }
  }

  private formData(): {[key: string]: string} {
    const data: {[key: string]: string} = {};
    for (const field in this.form.controls) {
      data[field] = this.form.controls[field].value;
    }
    data['sessionType'] = this.selectedSessionType;
    return data
  }

  public payload(): {[key: string]: any} {
    const {sessionName, sessionType, problemStatement, participationMode} = this.formData();
    const sessionDefinition: {[key: string]: any} = {};
    switch (sessionType) {
      case SessionType.QCM:
        sessionDefinition.propositions = [];
        break;
      case SessionType.ProblemAnalysis:
      case SessionType.SolutionGeneration:
        sessionDefinition.statement = problemStatement;
    }
    let anonymousInvitation;
    if (participationMode === SessionParticipationMode.AnonymousInvitation) {
      anonymousInvitation = {
        tokenCount: 10
      };
    } else {
      anonymousInvitation = {
        tokenCount: 0,
      }
    }
    return {
      sessionName,
      sessionType,
      sessionDefinition,
      participationMode,
      anonymousInvitation,
    };
  }
}
