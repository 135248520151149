<div class="body-container">
  <div class="selector-container">
    <h1>Bienvenue {{userData.given_name}}</h1>
    <div *ngIf="authorizedOrganizations.length > 0; else noOrganizations">
      <h2>Agissez au sein de:</h2>
      <div class="button-container" *ngFor="let org of authorizedOrganizations; let index = index">
        <button mat-raised-button color="primary" (click)="selectOrganization(index)">
          {{org.organizationName}}
        </button>
      </div>
    </div>
    <ng-template #noOrganizations>
      <h2>Vous n'êtes relié à aucune organisation pour le moment.</h2>
      <p class="text-center">
        Contacter la personne qui gère votre compte Revogo et communiquez lui l'addresse email avec
        laquelle vous vous êtes enregistré afin que l'on puisse vous donner les droits nécéssaires.
      </p>

      <div class="text-center">
        <a class="cursor-pointer text-darkgray text-sm hover:underline" (click)="signOut()">Se déconnecter</a>
      </div>

    </ng-template>
  </div>
</div>
