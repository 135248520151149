import {Component, Input} from "@angular/core";
import {ArgumentSign} from "../../revogo-client/revogo-client.types";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-create-argument-modal',
  template: `
    <h3 mat-dialog-title>Ajouter un argument {{sign === argumentSign.Pro ? 'pour' : 'contre'}}</h3>
    <mat-dialog-content class="mat-typography">
      <div class="m-2">
        <form [formGroup]="form">
          <mat-form-field class="w-full my-2">
            <mat-label>Description</mat-label>
            <textarea matInput class="w-full" rows="10" formControlName="description"></textarea>
          </mat-form-field>
        </form>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions class="flex">
      <button mat-stroked-button color="primary" [mat-dialog-close]="null">Annuler</button>
      <div class="flex-grow"></div>
      <button mat-raised-button color="primary" [disabled]="!form.valid" [mat-dialog-close]="form.controls.description.value">Confirmer</button>
    </mat-dialog-actions>
  `
}) export class CreateArgumentModalComponent {
  public form = new FormGroup({
    'description': new FormControl('', Validators.required)
  });

  @Input()
  public sign!: number;

  public argumentSign = ArgumentSign;
}
