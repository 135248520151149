import {Component, Input, OnInit, Output, EventEmitter} from "@angular/core";
import {
  QcmPropositionAnswerType,
  QcmSession,
  Session,
  SessionState
} from "../../../../revogo-client/revogo-client.types";
import {RevogoClientService} from "../../../../revogo-client/revogo-client.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import { MatCheckboxChange } from "@angular/material/checkbox";

@Component({
  selector: 'app-qcm-session-content',
  templateUrl: './qcm-session-content.component.html',
  styleUrls: ['./qcm-session-content.component.sass']
})
export class QcmSessionContentComponent implements OnInit {
  @Input('session')
  public _session!: Session;
  public session!: QcmSession;
  public editable!: boolean;
  public sessionState = SessionState;
  public answerTypes = Object.values(QcmPropositionAnswerType);

  @Input()
  public canWrite!: boolean;

  @Output()
  public dirty = new EventEmitter<boolean>();

  constructor(private revogoClient: RevogoClientService,
              private snackbar: MatSnackBar) {

  }

  async ngOnInit() {
    this.session = this._session as QcmSession;
    this.editable = this.session.sessionState === SessionState.Draft && this.canWrite;
  }

  public async updateSessionDefinition(body: { [key: string]: any } | undefined = undefined) {
    if (body === undefined) {
      body = this.session.sessionDefinition;
    }
    await this.revogoClient.updateSessionDefinition(this.session.sessionId, body).toPromise().then(
      () => {
        this.snackbar.open('Vos modifications ont été sauvegardées', undefined, {
          duration: 2000
        });
      }
    );
    this.dirty.emit(false);
  }

  public setDirty() {
    this.dirty.emit(true);
  }

  public pushProposition() {
    this.session.sessionDefinition.propositions.push({
      title: "Proposition sans titre",
      allowedAnswers: []
    });
    this.setDirty();
  }

  public deleteProposition(propositionIndex: number) {
    this.session.sessionDefinition.propositions.splice(propositionIndex, 1);
    this.setDirty();
  }

  public deleteAnswer(propositionIndex: number, answerIndex: number) {
    this.session.sessionDefinition.propositions[propositionIndex].allowedAnswers.splice(answerIndex, 1);
    this.setDirty();
  }

  public pushAllowedAnswer(propositionIndex: number) {
    this.session.sessionDefinition.propositions[propositionIndex].allowedAnswers.push({
      type: QcmPropositionAnswerType.Choice,
      label: 'Réponse à définir'
    });
    this.setDirty();
  }

  public swapPropositions(indexA: number, indexB: number) {
    const propositions = this.session.sessionDefinition.propositions;
    if (!(indexA < 0 || indexA >= propositions.length || indexB < 0 || indexB > propositions.length)) {
      const a = propositions[indexA], b = propositions[indexB];
      propositions[indexA] = b;
      propositions[indexB] = a;
      this.session.sessionDefinition.propositions = propositions;
      this.setDirty();
    }
  }

  public setAnswerType(propositionIndex: number, answerIndex: number, $event: MatCheckboxChange) {
    if ($event.checked) {
      this.session.sessionDefinition.propositions[propositionIndex].allowedAnswers[answerIndex].type = QcmPropositionAnswerType.FreeText;
    } else {
      this.session.sessionDefinition.propositions[propositionIndex].allowedAnswers[answerIndex].type = QcmPropositionAnswerType.Choice;
    }
  }
}
