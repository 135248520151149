import {Component, Input} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-create-solution-modal',
  template: `
    <h2 mat-dialog-title>Proposer une solution</h2>
    <mat-dialog-content class="mat-typography">
      <form [formGroup]="form">
        <mat-form-field class="w-full my-2">
          <mat-label>Titre de la solution</mat-label>
          <input class="w-full" matInput type="text" formControlName="title">
        </mat-form-field>
        <mat-form-field class="w-full my-2">
          <mat-label>Description</mat-label>
          <textarea rows="10" class="w-full" matInput formControlName="description"></textarea>
        </mat-form-field>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions class="flex">
      <button mat-stroked-button color="primary" [mat-dialog-close]="null">
        Annuler
      </button>
      <div class="flex-grow"></div>
      <button mat-raised-button color="primary" [mat-dialog-close]="solutionBody()" [disabled]="!form.valid">
        Confirmer
      </button>
    </mat-dialog-actions>
  `
})
export class CreateSolutionModalComponent {
  @Input()
  public parentProblemId!: string;

  public form = new FormGroup({
    'title': new FormControl('', Validators.required),
    'description': new FormControl('', Validators.required)
  });

  public setParentProblem(parentProblemId: string) {
    this.parentProblemId = parentProblemId;
  }

  public solutionBody() {
    return {
      title: this.form.controls.title.value,
      description: this.form.controls.description.value,
      problem_id: this.parentProblemId
    }
  }
}
