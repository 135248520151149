import {NgModule} from "@angular/core";
import {MessageThreadComponent} from "./message-thread.component";
import {ThreadMessageTree} from "./thread-message-tree.component";
import {MatDividerModule} from "@angular/material/divider";
import {CommonModule} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {ThreadContextActionComponent} from "./thread-context-actions";
import {MatMenuModule} from "@angular/material/menu";
import {MatRippleModule} from "@angular/material/core";
import {MatTooltipModule} from "@angular/material/tooltip";
import {ThreadViewComponent} from "./thread-view.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {ThreadViewCacheService} from "./thread-view-state.service";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

@NgModule({
  imports: [
    CommonModule,
    MatDividerModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatRippleModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatSelectModule,
    BrowserAnimationsModule,
  ],
  declarations: [
    MessageThreadComponent,
    ThreadMessageTree,
    ThreadContextActionComponent,
    ThreadViewComponent,
  ],
  exports: [
    MessageThreadComponent,
    ThreadViewComponent
  ],
  providers: [ThreadViewCacheService],
})
export class MessageThreadModule {}
