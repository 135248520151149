import {Component, Input, OnInit} from "@angular/core";
import {Session, SessionParticipationMode} from "../../../revogo-client/revogo-client.types";
import {Subject} from "rxjs";
import {RevogoClientService} from "../../../revogo-client/revogo-client.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {StateRouterService} from "../../../state-router/state-router.service";
import {STATE} from "../../app-states";
import {Router} from "@angular/router";

@Component({
  selector: 'app-session-metadata',
  templateUrl: './session-metadata.component.html',
  styleUrls: ['./session-metadata.component.sass']
})
export class SessionMetadataComponent implements OnInit {
  @Input()
  public session!: Session;
  public participationMode = SessionParticipationMode;
  public domain!: string;
  public editable!: boolean;
  public dateFormat = 'dd/MM/YYYY HH:mm';

  @Input()
  public canWrite!: boolean;

  constructor(private revogoClient: RevogoClientService,
              private snackbar: MatSnackBar,
              private dialog: MatDialog,
              private router: Router,
              private stateRouter: StateRouterService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

  }

  ngOnInit() {
    this.domain = window.location.href.split('/')[2];
    this.editable = this.session.sessionState === 'draft';
  }

  public cloneModal() {
    const dialogRef = this.dialog.open(CloneSessionModalComponent);
    dialogRef.afterClosed().subscribe(async result => {
      if (result !== null && result !== undefined) {
        const clonedSession = await this.revogoClient.cloneSession(this.session.sessionId, result).toPromise();
        this.snackbar.open('Session dupliquée avec succès', undefined, {duration: 5000});
        this.stateRouter.nav(STATE.SESSIONS, clonedSession.sessionId);
      }
    });
  }

  async updateMetadata() {
    const payload: { [key: string]: any } = {session_name: this.session.sessionName};
    if (this.session.anonymousInvitation.tokenCount !== undefined) {
      payload['anonymous_invitation'] = {
        token_count: this.session.anonymousInvitation.tokenCount
      }
    }
    await this.revogoClient.updateSession(this.session.sessionId, payload).toPromise().then(() => {
      this.snackbar.open('Modifications sauvegardées', undefined, {duration: 2000});
    });
  }

  public deleteModal() {
    const ref = this.dialog.open(DeleteSessionModalComponent);
    ref.afterClosed().subscribe(async result => {
      if (result === true) {
        await this.revogoClient.deleteSession(this.session.sessionId).toPromise();
        await this.stateRouter.nav(STATE.SESSIONS, STATE.SESSIONS_LIST);
      }
    });
  }
}

@Component({
  selector: 'app-delete-session-modal',
  template: `
    <h2>Êtes vous sûr de vouloir supprimer cette session ?</h2>
    <mat-dialog-content>
      <p class="text-center">
        Elle sera supprimée définitivement.
      </p>
    </mat-dialog-content>
    <mat-dialog-actions class="flex">
      <button mat-stroked-button color="primary" [mat-dialog-close]="false">Annuler</button>
      <div class="flex-grow"></div>
      <button mat-raised-button color="primary" [mat-dialog-close]="true">Confirmer</button>
    </mat-dialog-actions>
  `
})
export class DeleteSessionModalComponent {}

@Component({
    selector: 'app-clone-session-modal',
    template: `
      <h2 mat-dialog-title>Êtes vous sûr de vouloir dupliquer cette session ?</h2>
      <mat-dialog-content class="mat-typography">
        <p>Vous serez redirigé vers une nouvelle session en brouillon identique à celle-ci.</p>
        <mat-form-field class="w-full">
          <mat-label>Nom de la nouvelle session</mat-label>
          <input class="w-full" type="text" matInput [(ngModel)]="sessionName" required>
        </mat-form-field>
      </mat-dialog-content>
      <mat-dialog-actions class="flex">
        <button mat-stroked-button color="primary" [mat-dialog-close]="null">Annuler</button>
        <div class="flex-grow"></div>
        <button mat-raised-button color="primary" [mat-dialog-close]="sessionName">Confirmer</button>
      </mat-dialog-actions>
    `
})
export class CloneSessionModalComponent {
  @Input()
  sessionName: string = '';
}
