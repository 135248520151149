import {Component, Input, Output, EventEmitter, OnInit} from "@angular/core";
import {LiveThreadService, ThreadNode} from "../../live-thread/live-thread.service";

export type MessageContextButtonDefinition = {
  icon: string,
  label: string,
  eventKey: string,
  displayCondition?: (node: ThreadNode, service: LiveThreadService) => boolean,
};

@Component(
  {
    selector: 'app-thread-context-actions',
    template: `
      <ng-container *ngIf="buttonsDefinition.length > 0">
        <button [ngClass]="small === true ? 'small-icon-button' : ''" mat-icon-button [matMenuTriggerFor]="menu" [disabled]="!anyToDisplay">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <ng-container *ngFor="let buttonDef of buttonsDefinition">
            <div (click)="emit(buttonDef.eventKey)" mat-ripple>
              <button *ngIf="buttonDef.displayCondition === undefined || buttonDef.displayCondition(node, liveThreadService)" mat-icon-button>
                <mat-icon class="m-1">{{buttonDef.icon}}</mat-icon>
                <span class="m-1">{{buttonDef.label}}</span>
              </button>
            </div>

          </ng-container>

        </mat-menu>
      </ng-container>

    `
  }
)
export class ThreadContextActionComponent implements OnInit {
  @Input()
  buttonsDefinition!: MessageContextButtonDefinition[];

  @Input()
  node!: ThreadNode;

  @Input()
  small!: boolean;

  @Output()
  event = new EventEmitter<string>();

  public anyToDisplay!: boolean;

  constructor(public liveThreadService: LiveThreadService) {}

  public emit(eventKey: string) {
    this.event.emit(eventKey);
  }

  ngOnInit() {
    this.anyToDisplay = this.buttonsDefinition.reduce((acc: boolean, curr) =>
      acc || curr.displayCondition === undefined || curr.displayCondition(this.node, this.liveThreadService)
    , false);
  }
}
