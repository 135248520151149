import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {STATE} from "../app-states";
import {SelectedOrganizationResolve} from "../../auth/organization.resolvers";
import {UserResolve} from "../../auth/auth.resolvers";
import {ArgumentsComponent} from "./arguments.component";
import {ArgumentResolve} from "../../revogo-client/revogo-client.resolvers";
import {MatDividerModule} from "@angular/material/divider";
import {MatIconModule} from "@angular/material/icon";
import {ArgumentDetailsComponent} from "./argument-details.component";
import {MatButtonModule} from "@angular/material/button";
import {MatRippleModule} from "@angular/material/core";
import {MindMapModule} from "../mind-map/mind-map.module";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatTooltipModule} from "@angular/material/tooltip";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {MatInputModule} from "@angular/material/input";
import {HeaderModule} from "../header/header.module";

const ARGUMENTS_DETAILS_STATE = {
  path: ':argumentId',
  component: ArgumentDetailsComponent,
  resolve: {
    argument: ArgumentResolve,
  }
}

const ARGUMENTS_STATE = {
  path: STATE.ARGUMENTS,
  component: ArgumentsComponent,
  resolve: {
    selectedOrganization: SelectedOrganizationResolve,
    userData: UserResolve,
  },
  children: [
    ARGUMENTS_DETAILS_STATE
  ]
}


@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild([ARGUMENTS_STATE]),
        MatDividerModule,
        MatIconModule,
        MatButtonModule,
        MatRippleModule,
        MindMapModule,
        MatProgressSpinnerModule,
        BrowserAnimationsModule,
        MatTooltipModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        FormsModule,
        MatInputModule,
        HeaderModule,
    ],
  declarations: [
    ArgumentsComponent,
    ArgumentDetailsComponent,
  ],
  providers: [ArgumentResolve]
}) export class ArgumentsModule {}
