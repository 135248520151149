import {Component, Input} from "@angular/core";
import {MindMapArgument} from "../../revogo-client/revogo-client.types";

@Component({
  selector: 'app-argument-card',
  template: `
    <div class="w-full rounded-sm border shadow-lg shadow-inner cursor-pointer {{twClasses}}" mat-ripple [routerLink]="'/arguments/'+argument.id">
      <div class="flex m-2">
        <div class="mx-1" [ngClass]="argument.weight === null || argument.weight === undefined ? 'text-gray-500' : ''">
          <span>{{argument.weight !== null && argument.weight !== undefined ? argument.weight : 0}}</span>
        </div>
        <mat-divider vertical></mat-divider>
        <div class="mx-1 flex-grow">
          {{argument.description}}
        </div>
      </div>
    </div>
  `
}) export class ArgumentCardComponent {
  @Input()
  argument!: MindMapArgument;

  @Input()
  twClasses: string = '';
}
