import {Component, OnInit} from "@angular/core";
import {RevogoClientService} from "../../revogo-client/revogo-client.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ArgumentSign, MindMapArgument, MindMapSolution} from "../../revogo-client/revogo-client.types";
import {Location} from '@angular/common';
import {GenericConfirmationModalComponent} from "../../modals/generic-confirmation-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {STATE} from "../app-states";
import {StateRouterService} from "../../state-router/state-router.service";
import {OrganizationService} from "../../auth/organization.service";

@Component({
  selector: 'app-argument-details',
  template: `
    <div class="m-2 mx-8">
      <div class="flex m-2">
        <button mat-stroked-button (click)="location.back()" color="primary">Retour</button>
        <div class="text-xl primary-text m-2">Argument</div>
      </div>
      <mat-divider></mat-divider>
      <div class="m-2 border rounded-sm border-blue-500 bg-blue-50 shadow-lg shadow-inner">
        <div *ngIf="parent.solution !== undefined" mat-ripple [routerLink]="'/solutions/'+parent.solution.id" class="cursor-pointer">
          <div class="m-2 text-gray-500 text-sm">
            Argument {{argument.sign === argumentSign.Pro ? 'pour ' : 'contre '}} la proposition de solution :
          </div>
          <div class="text-lg mx-4 my-2" matTooltipPosition="below" matTooltipClass="intrusive-tooltip" [matTooltipDisabled]="parent.solution.description === undefined || parent.solution.description === null" [matTooltip]="parent.solution.description || ''">
            {{parent.solution.title}}
          </div>
        </div>
        <div *ngIf="parent.argument !== undefined" mat-ripple [routerLink]="'/arguments/'+parent.argument.id" class="cursor-pointer">
          <div class="m-2 text-gray-500 text-sm">
            Argument pour {{argument.sign === argumentSign.Pro ? 'appuyer ' : 'rejeter '}} :
          </div>
          <div class="text-lg mx-4 my-2">
            {{parent.argument.description}}
          </div>
        </div>
        <div *ngIf="parent.argument === undefined && parent.solution === undefined" class="flex m-2">
          <div class="flex-grow"></div>
          <mat-spinner [diameter]="32"></mat-spinner>
          <div class="flex-grow"></div>
        </div>
        <mat-divider></mat-divider>
        <div [ngClass]="argument.sign === 1 ? 'bg-green-100' : 'bg-red-100' ">
          <div class="m-2 mx-4 text-center text-lg" *ngIf="!editMode">
            <span *ngIf="argument.sign === 1" class="text-green-400">Pour :</span>
            <span *ngIf="argument.sign === -1" class="text-red-400">Contre :</span>
            {{argument.description}}
          </div>
          <div class="m-2 mx-4 text-lg w-full" *ngIf="editMode">
            <div>
              <mat-form-field *ngIf="emptyChildView">
                <mat-select [(ngModel)]="argument.sign">
                  <mat-option [value]="1">Pour</mat-option>
                  <mat-option [value]="-1">Contre</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <mat-divider></mat-divider>
            <div>
              <mat-form-field class="w-full">
                <mat-label>Description</mat-label>
                <textarea matInput class="w-full" cols="30" rows="10" [(ngModel)]="argument.description"></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="m-2 flex" *ngIf="canWrite">
        <button mat-stroked-button color="error" (click)="deleteModal()">
          <mat-icon>delete</mat-icon>
          Supprimer
        </button>
        <div class="flex-grow"></div>
        <button mat-stroked-button color="primary" *ngIf="!editMode" (click)="toggleEditMode()">
          <mat-icon>edit</mat-icon>
          Modifier
        </button>
        <button mat-raised-button color="primary" *ngIf="editMode" (click)="toggleEditMode()">
          <mat-icon>save</mat-icon>
          Sauvegarder
        </button>
      </div>
      <mat-divider></mat-divider>
      <app-argument-explorer (empty)="setEmpty($event)" [editable]="argument.editable && canWrite" [rootNodeId]="'argument:'+argument.id" [organizationId]="argument.organizationId"></app-argument-explorer>
    </div>
  `,
})
export class ArgumentDetailsComponent implements OnInit {
  public argumentSign = ArgumentSign;
  public argument!: MindMapArgument;
  public parent!: {
    solution: MindMapSolution | undefined,
    argument: MindMapArgument | undefined,
  }
  public editMode = false;
  public emptyChildView = false;
  public canWrite!: boolean;

  constructor(private revogoClient: RevogoClientService,
              private route: ActivatedRoute,
              private router: Router,
              public location: Location,
              private dialog: MatDialog,
              private snackbar: MatSnackBar,
              private stateRouter: StateRouterService,
              private organizationService: OrganizationService) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  async ngOnInit() {
    this.argument = this.route.snapshot.data.argument;
    this.canWrite = await this.organizationService.userHasRole(['manager']) && this.argument.editable;
    await this.getParent();
  }

  private async getParent() {
    const parentNodeKind = this.argument.targetNode.split(':')[0];
    const parentNodeId = this.argument.targetNode.split(':')[1];
    this.parent = {
      solution: undefined,
      argument: undefined,
    }
    if (parentNodeKind === 'solution') {
      this.parent.solution = await this.revogoClient.getMindMapSolution(this.argument.organizationId, parentNodeId).toPromise();
    } else if (parentNodeKind === 'argument') {
      this.parent.argument = await this.revogoClient.getMindMapArgument(this.argument.organizationId, parentNodeId).toPromise();
    }
  }

  public async toggleEditMode() {
    if (this.editMode) {
      await this.revogoClient.updateMindMapArgument(this.argument.organizationId, this.argument.id, {
        description: this.argument.description,
        sign: this.argument.sign,
      }).toPromise();
      this.snackbar.open('Vos modifications ont été sauvegardées.', undefined, {duration: 3000});
    }
    this.editMode = !this.editMode;
  }

  public async deleteModal() {
    const ref = this.dialog.open(GenericConfirmationModalComponent, {panelClass: 'wide-modal'});
    ref.componentInstance.title = 'Supprimer cet argument ?';
    ref.componentInstance.textLines = [
      'Cela entrainera la suppression de tout les arguments enfants.',
      'Vous ne pourrez pas annuler cette action.'
    ];
    ref.afterClosed().subscribe(async confirmed => {
      if (confirmed) {
        await this.revogoClient.deleteMindMapArgument(this.argument.organizationId, this.argument.id).toPromise();
        this.snackbar.open('Cet argument a été supprimé', undefined, {duration: 3000});
        if (this.parent.solution) {
          await this.stateRouter.nav(STATE.SOLUTIONS, this.parent.solution.id);
        } else if (this.parent.argument) {
          await this.stateRouter.nav(STATE.ARGUMENTS, this.parent.argument.id);
        }
      }
    });
  }

  public setEmpty($event: boolean) {
    this.emptyChildView = $event;
  }

}
