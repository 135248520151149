import {Injectable} from "@angular/core";
import {RevogoClientService} from "../revogo-client/revogo-client.service";
import {
  SessionEntityType,
  SessionType,
  ThreadMessageEntity,
  UserAuthorizations
} from "../revogo-client/revogo-client.types";
import {Observable, Subject} from "rxjs";
import {AuthService} from "../auth/auth.service";

export enum UserVote {
  Up = 1,
  None = 0,
  Down = -1
}

export type ThreadNode = {
  id: string,
  message: string,
  voteScore: number,
  ownerId: string,
  children: ThreadNode[],
  myVote: UserVote,
  creationDate: Date,
  isMine: boolean,
  isRoot: boolean,
  authorInfo?: {
    ownerReference: string,
    authorDisplayName: string,
    authorRole: string,
  },
  sortScores: {
    controversy: number
  },
  mindMapAnnotations?: {
    kind: string,
    sign?: number
  },
  highlighted: boolean,
}

export type ThreadView = ThreadNode[];

export enum ThreadSortMode {
  None,
  RecentFirst,
  BestScoreFirst,
  ControversialFirst,
}

type EntityTree = {
  entity: ThreadMessageEntity,
  children: EntityTree[],
};


type ThreadBindingState = {
  sessionId: string;
  organizationId: string;
  userId: string | undefined;
  rootNodeId: string | undefined;
  sort: ThreadSortMode;
  highlighter: (node: ThreadNode) => boolean;
};


@Injectable({providedIn: 'root'})
export class LiveThreadService {
  private state: ThreadBindingState | undefined = undefined;
  private threadUpdateEmitter = new Subject<ThreadView | undefined>();

  private threadView: ThreadView | undefined = undefined;
  private entityById: { [key: string]: ThreadMessageEntity } = {};
  private userAuthorizations!: UserAuthorizations | undefined;
  private rawEntities!: ThreadMessageEntity[];


  constructor(private revogoClient: RevogoClientService,
              private authService: AuthService) {
  }

  public async bind(sessionId: string, userId: string | undefined = undefined): Promise<Observable<ThreadView | undefined>> {
    if (this.state !== undefined) {
      this.unbind();
    }
    this.userAuthorizations = await this.revogoClient.getAuthorizations().toPromise();
    const organizationId = (await this.revogoClient.getSession(sessionId).toPromise()).organizationId;
    this.state = {
      sessionId,
      organizationId,
      userId,
      rootNodeId: undefined,
      sort: ThreadSortMode.BestScoreFirst,
      highlighter: () => false,
    };
    this.threadView = [];
    return this.threadUpdateEmitter.asObservable();
  }

  public setSort(sortMode: ThreadSortMode) {
    if (this.state !== undefined && this.threadView !== undefined) {
      this.state.sort = sortMode;
      this.sortMessages(this.threadView);
      this.emitThreadView();
    } else {
      throw new Error('Live thread service is not bound');
    }
  }

  public setHighlighter(highlighter: (node: ThreadNode) => boolean) {
    if (this.state !== undefined && this.threadView !== undefined) {
      this.state.highlighter = highlighter;
      this.highlightMessages(this.threadView);
    }
  }

  public setRoot(rootNodeId: string | undefined) {
    if (this.state !== undefined && this.threadView !== undefined) {
      this.state.rootNodeId = rootNodeId;
    } else {
      throw new Error('Live thread service is not bound');
    }
  }

  private applyEntities(entities: ThreadMessageEntity[]) {
    // This function consumes the raw thread entities.
    // It uses them to update the ThreadView tree according to the state settings: (sort, user)

    if (this.state === undefined || this.threadView === undefined) {
      throw new Error("live thread service is not bound");
    }
    this.rawEntities = entities;
    this.updateEntityRegistry(this.rawEntities);
    const entityTree = this.buildEntityTree(this.rawEntities);
    const threadView = this.buildThreadView(entityTree);

    const subview = this.changeRoot(threadView);

    this.sortMessages(subview);
    this.highlightMessages(subview);
    this.threadView = subview;
  }

  private highlightMessages(threadView: ThreadView) {
    const highlighter = (node: ThreadNode) => {
      node.highlighted = !!this.state?.highlighter(node);
      node.children.forEach(highlighter);
    }
    if (this.state !== undefined) {
      threadView.forEach(highlighter);
    }
  }

  private updateEntityRegistry(entities: ThreadMessageEntity[]) {
    entities.forEach(entity => {
      this.entityById[entity.entityId] = entity;
    });
  }

  private buildEntityTree(entities: ThreadMessageEntity[]): EntityTree[] {
    const nodeMap: { [key: string]: EntityTree } = {};
    const roots: EntityTree[] = [];

    entities.forEach(entity => {
      nodeMap[entity.entityId] = {entity, children: []};
    });
    entities.forEach(entity => {
      const node = nodeMap[entity.entityId];
      if (node.entity.entityData.parentMessageId !== undefined) {
        nodeMap[node.entity.entityData.parentMessageId].children.push(node);
      } else {
        roots.push(node);
      }
    });
    return roots;
  }

  private findNode(id: string): ThreadNode | undefined {
    if (this.threadView === undefined) {
      console.error('cannot find node, threadview does not exist');
      return undefined;
    }
    const walker = (id: string, node: ThreadNode) => {
      if (node.id === id) {
        return node;
      } else if (node.children.length > 0) {
        let found = undefined;
        node.children.forEach(node => {
          const result = walker(id, node);
          if (result !== undefined) {
            found = result;
          }
        });
        return found;
      } else {
        return undefined;
      }
    }
    let found = undefined;
    this.threadView.forEach(node => {
      const result = walker(id, node);
      if (result !== undefined) {
        found = result;
      }
    });
    return found;
  }

  private changeRoot(thread: ThreadView): ThreadView {
    if (this.state !== undefined) {
      const rootNodeId = this.state.rootNodeId;
      if (rootNodeId !== undefined) {
        const found = this.findNode(rootNodeId);
        return found !== undefined ? [found] : thread;
      } else {
        return thread;
      }
    } else {
      return thread;
    }
  }

  private buildThreadView(entityTree: EntityTree[]): ThreadView {
    const state = this.state;
    if (state === undefined) {
      throw new Error('cant build thread view without being bound');
    } else {
      return entityTree.map(
        treeNode => {
          const votes = treeNode.entity.entityData.votes;
          const me = this.state?.userId;
          let myVote = 0;
          if (me && votes.pro.indexOf(me) !== -1) {
            myVote = 1;
          } else if (me && votes.against.indexOf(me) !== -1) {
            myVote = -1;
          }
          return {
            id: treeNode.entity.entityId,
            message: treeNode.entity.entityData.content,
            voteScore: votes.pro.length - votes.against.length,
            ownerId: treeNode.entity.ownerReference,
            myVote,
            children: this.buildThreadView(treeNode.children),
            creationDate: treeNode.entity.createdAt,
            isMine: treeNode.entity.ownerReference === state.userId,
            authorInfo: treeNode.entity.entityData.authorInfo,
            sortScores: {
              controversy: LiveThreadService.computeControversyScore(votes.pro.length, votes.against.length)
            },
            mindMapAnnotations: treeNode.entity.entityData.mindMapAnnotations,
            isRoot: treeNode.entity.entityData.parentMessageId === undefined,
            highlighted: false,
          };
        }
      );
    }
  }

  private static computeControversyScore(voteCountA: number, voteCountB: number): number {
    // Intuition based score.
    // The goal was to have a high score if a lot of people upvote and downvote the comment.
    // And small score if people mostly agree or disagree.

    let bigger, smaller;
    if (voteCountA > voteCountB) {
      bigger = voteCountA;
      smaller = voteCountB;
    } else {
      bigger = voteCountB;
      smaller = voteCountA
    }
    return ((smaller + 1) / (bigger + 1)) * (bigger + smaller);
  }

  private recursiveTreeSort(nodes: ThreadView, compareFunc: (a: ThreadNode, b: ThreadNode) => number) {
    nodes.forEach(node => {
      this.recursiveTreeSort(node.children, compareFunc);
    });
    nodes.sort(compareFunc);
  }

  private sortMessages(thread: ThreadView) {
    function sortFactory(cmp: (a: ThreadNode, b: ThreadNode) => number) {
      function sorter(a: ThreadNode, b: ThreadNode) {
        const diff = cmp(a, b);
        if (diff === 0) {
          return a.message.localeCompare(b.message);
        }
        return diff;
      }
      return sorter;
    }
    if (this.state !== undefined) {
      switch (this.state.sort) {
        case ThreadSortMode.None:
          this.recursiveTreeSort(thread, sortFactory((a, b) => 0));
          break;
        case ThreadSortMode.RecentFirst:
          this.recursiveTreeSort(thread, sortFactory((a, b) => {
            const ad = this.entityById[a.id].createdAt, bd = this.entityById[b.id].createdAt;
            return bd.getTime() - ad.getTime();
          }));
          break;
        case ThreadSortMode.ControversialFirst:
          this.recursiveTreeSort(thread, sortFactory((a, b) =>
            b.sortScores.controversy - a.sortScores.controversy
          ));
          break;
        case ThreadSortMode.BestScoreFirst:
          this.recursiveTreeSort(thread,sortFactory((a, b) => b.voteScore - a.voteScore));
          break;
        default:
          throw new Error('unsupported message sort mode');
      }
    }
  }

  public unbind() {
    this.state = undefined;
    this.threadView = undefined;
    this.entityById = {};
    this.emitThreadView();
  }

  public async synchronize() {
    if (this.state !== undefined) {
      const entities = (await this.revogoClient.getPublicSessionEntities(this.state.sessionId).toPromise()) as ThreadMessageEntity[];
      this.applyEntities(entities);
      this.emitThreadView();
    } else {
      throw new Error('Live thread is not bound to a session');
    }
  }

  public resetAddedMessage() {
    this.applyEntities(this.rawEntities);
    this.emitThreadView();
  }

  public synchronousAddMessage(
      content: string,
      parentMessageId: string | undefined,
      mindMapAnnotations: {kind: string, sign?: number} | undefined) {

    if (this.state !== undefined) {
      const userId = this.state.userId as string;
      const myDisplayName = this.authService.getUserData().displayName;
      const threadMessage = {
        sessionId: this.state.sessionId,
        entityId: 'this-entity-does-not-exist',
        sessionType: SessionType.ProblemAnalysis,
        ownerReference: userId,
        entityType: SessionEntityType.ThreadMessage,
        createdAt: new Date(),
        entityData: {
          parentMessageId,
          content,
          mindMapAnnotations,
          votes: {
            pro: [userId],
            against: [],
          },
          authorInfo: {
            ownerReference: userId,
            authorDisplayName: myDisplayName,
            authorRole: 'player',
          }
        }
      } as ThreadMessageEntity;
      this.applyEntities(this.rawEntities.concat([threadMessage]));
      this.emitThreadView();
    }
  }

  public setVote(nodeId: string, sign: number) {
    const node = this.findNode(nodeId);
    if (node !== undefined) {
      const before = node.myVote;
      if (node.myVote === sign) {
        node.myVote = 0;
      } else {
        node.myVote = sign;
      }
      const after = node.myVote;
      const diff = (after - before);
      node.voteScore += diff;
      this.emitThreadView();
    }
  }

  private emitThreadView() {
    this.threadUpdateEmitter.next(this.threadView);
  }

  public amSage(): boolean {
    const state = this.state;
    if (this.userAuthorizations === undefined || state === undefined) {
      return false;
    } else {
      let foundSage = false;
      this.userAuthorizations.roles.forEach(role => {
        if (role.organizationId === '*' && role.services.indexOf('sage') !== -1) {
          foundSage = true;
        } else if (role.organizationId === state.organizationId && role.services.indexOf('sage') !== -1) {
          foundSage = true;
        }
      });
      return foundSage;
    }
  }
}
