import {Component, OnInit} from "@angular/core";
import {
  MindMapProblem,
  MindMapSolution,
  Session,
} from "../../revogo-client/revogo-client.types";
import {ActivatedRoute} from "@angular/router";
import {OrganizationService} from "../../auth/organization.service";
import {RevogoClientService} from "../../revogo-client/revogo-client.service";
import {StateRouterService} from "../../state-router/state-router.service";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {CreateSolutionModalComponent} from "../mind-map/create-solution-modal.component";
import {STATE} from "../app-states";
import {CreateSessionFromNodeModalComponent} from "../mind-map/create-session-from-node-modal.component";
import {GenericConfirmationModalComponent} from "../../modals/generic-confirmation-modal.component";
import {Location} from '@angular/common';


@Component({
  selector: 'app-problem-details',
  templateUrl: './problem-details.component.html',
})
export class ProblemDetailsComponent implements OnInit {
  public problem!: MindMapProblem;
  public parentSession!: Session | undefined;
  public descriptionLines!: string[];
  public potentialSolutions!: MindMapSolution[];
  public editMode: boolean = false;
  public childSessions!: Session[];
  public canWrite!: boolean;

  private organizationId!: string;

  constructor (private route: ActivatedRoute,
               private organizationService: OrganizationService,
               private revogoClient: RevogoClientService,
               private stateRouter: StateRouterService,
               private dialog: MatDialog,
               private snackbar: MatSnackBar,
               public location: Location) {}

  async ngOnInit() {
    this.canWrite = await this.organizationService.userHasRole(['manager']);
    const organization = await this.organizationService.getSelectedOrganization().toPromise();
    if (organization !== null) {
      this.organizationId = organization.organizationId;
    }
    this.updateProblemState(this.route.snapshot.data.problem);
    if (this.problem.sessionId) {
      this.parentSession = await this.revogoClient.getSession(this.problem.sessionId).toPromise();
    } else {
      this.parentSession = undefined;
    }
    await Promise.all([this.getSolutions(), this.getChildSessions()]);
  }

  private async getSolutions() {
    this.potentialSolutions = await this.revogoClient.getMindMapProblemSolutions(this.organizationId, this.problem.id).toPromise();
  }

  private async getChildSessions() {
    this.childSessions = await this.revogoClient.getMindMapNodeChildSessions(this.organizationId, `problem:${this.problem.id}`).toPromise();
  }

  private updateProblemState(problem: MindMapProblem) {
    this.problem = problem;
    this.descriptionLines = this.problem.description ? this.problem.description.split('\n') : [];
  }

  private async markResolved() {
    const updated = await this.revogoClient.updateMindMapProblem(this.organizationId, this.problem.id, {
      resolution_date: "true"
    }).toPromise();
    await this.updateProblemState(updated);
  }

  public async resolveProblemModal() {
    const ref = this.dialog.open(GenericConfirmationModalComponent);
    ref.componentInstance.title = 'Marquer le problème comme étant résolu ?';
    ref.componentInstance.textLines = [
      'Cette action est irréversible.',
      'Vous ne pourrez plus modifier ni le titre ni la description du problème.'
    ];
    ref.afterClosed().subscribe(async result => {
      if (result === true) {
        await this.markResolved();
      }
    });
  }

  private async saveProblem() {
    const updated = await this.revogoClient.updateMindMapProblem(this.organizationId, this.problem.id, {
      title: this.problem.title,
      description: this.problem.description
    }).toPromise();
    await this.updateProblemState(updated);
    this.snackbar.open('Modifications sauvegardées avec succès', undefined, {duration: 3000});
  }

  public async toggleEditMode() {
    this.editMode = !this.editMode;
    if (!this.editMode) {
      await this.saveProblem();
    }
  }

  public createSolutionModal() {
    const ref = this.dialog.open(CreateSolutionModalComponent, {panelClass: 'wide-modal'});
    ref.componentInstance.setParentProblem(this.problem.id);

    ref.afterClosed().subscribe(async result => {
      if (result) {
        await this.revogoClient.createMindMapSolution(this.organizationId, result).toPromise();
        await this.getSolutions();
      }
    });
  }

  public createSessionModal() {
    const ref = this.dialog.open(CreateSessionFromNodeModalComponent, {
      panelClass: 'wide-modal',
      data: {
        parentNodeType: 'problem',
        prefill: {
          sessionName: `Analyse : ${this.problem.title}`,
          problemStatement: this.problem.description
        }
      }
    });

    ref.afterClosed().subscribe(async content => {
      if (content !== null && content !== undefined) {
        const payload = {
          ...content,
          organizationId: this.organizationId,
          parentMindMapNode: `problem:${this.problem.id}`,
        }
        const session = await this.revogoClient.createSession(payload).toPromise();
        await this.stateRouter.nav(STATE.SESSIONS, session.sessionId, STATE.SESSIONS_DETAILS);
      }
    });
  }

  public deleteProblemModal() {
    const ref = this.dialog.open(GenericConfirmationModalComponent, {panelClass: 'wide-modal'});
    ref.componentInstance.title = 'Supprimer ce problème ?';
    ref.componentInstance.textLines = [
      'Êtes-vous sûr(e) de vouloir supprimer ce problème ?',
      'Vous ne pourrez pas annuler cette action.'
    ];
    ref.afterClosed().subscribe(async result => {
      if (result === true) {
        await this.revogoClient.deleteMindMapProblem(this.organizationId, this.problem.id).toPromise();
        await this.stateRouter.nav(STATE.PROBLEMS, STATE.PROBLEMS_LIST);
      }
    });
  }
}
