import { Component, OnInit } from '@angular/core';
import {OrganizationService} from "../../auth/organization.service";
import {ActivatedRoute} from "@angular/router";
import {StateRouterService} from "../../state-router/state-router.service";
import {STATE} from "../app-states";
import {Organization} from "../../revogo-client/revogo-client.types";
import {AuthService} from "../../auth/auth.service";

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.sass']
})
export class OrganizationsComponent implements OnInit {
  public authorizedOrganizations: Organization[];
  public userData!: {[key:string]: any};

  constructor(
    private route: ActivatedRoute,
    private router: StateRouterService,
    private organizationService: OrganizationService,
    private authService: AuthService,
  ) {
    this.authorizedOrganizations = this.route.snapshot.data.authorizedOrganizations;
    this.userData = this.route.snapshot.data.userData;
  }


  async ngOnInit() {
    const selectedOrganization = await this.organizationService.getSelectedOrganization().toPromise();
    if (selectedOrganization !== null) {
      this.navToSessions();
    }
  }

  public selectOrganization(index: number) {
    const organization = this.authorizedOrganizations[index];
    this.organizationService.setSelectedOrganization(organization);
    this.navToSessions();
  }

  private navToSessions() {
      this.router.nav(STATE.SESSIONS, STATE.SESSIONS_LIST);
  }

  public signOut() {
    this.organizationService.clearSelected();
    this.authService.signOut();
    this.router.nav(STATE.LOGIN);
  }
}
