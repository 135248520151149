import {ThreadNode} from "../../live-thread/live-thread.service";

export enum ThreadEventKind {
  Reply,
  ChangeRoot,
  Vote,
  UnspecifiedContextAction,
}

type _ThreadEventBase = {
  kind: ThreadEventKind,
}

export type ThreadReplyEvent = _ThreadEventBase & {
  parentNodeId?: string,
};

export type ThreadChangeRootEvent = _ThreadEventBase & {
  rootNodeId: string
}

export type ThreadVoteEvent = _ThreadEventBase & {
  nodeId: string,
  sign: number,
}

export type ThreadContextActionEvent = _ThreadEventBase & {
  node: ThreadNode,
  specificEventKey: string,
}

export type ThreadEvent = ThreadReplyEvent | ThreadChangeRootEvent | ThreadVoteEvent | ThreadContextActionEvent;
