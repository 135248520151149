import {Component, Input, OnDestroy, OnInit, Output, EventEmitter} from "@angular/core";
import {Observable, Subscription} from "rxjs";
import {ThreadNode, ThreadView} from "../../live-thread/live-thread.service";
import {ThreadEvent} from "./thread.event";
import {MessageContextButtonDefinition} from "./thread-context-actions";

@Component({
  selector: 'app-message-thread',
  template: `
    <ng-container *ngIf="thread">
      <ng-container *ngFor="let node of thread; let index = index">
        <div class="my-3">
          <div class="border border-gray-500 rounded-md shadow-md pb bg-white">
            <app-thread-message-tree
              (event)="forwardEvent($event)"
              [showDefaultButtons]="showDefaultButtons"
              [node]="node"
              [nestingLevel]="0"
              [maxNestingLevel]="maxNestingLevel"
              [contextButtonDefinition]="contextButtonDefinition"
              [messageProcessor]="messageProcessor"
            >
            </app-thread-message-tree>
          </div>
        </div>
      </ng-container>
    </ng-container>
  `,
})
export class MessageThreadComponent implements OnInit, OnDestroy {

  /*
    This allows to distinguish "my messages" from the rest.
    This gives the component a natural Point of view of the thread.
    This is what allows comprehensive messaging and voting behavior.

    Undefined means we display the messages in a neutral manner.
    It also means that all write events are disabled.
   */
  @Input()
  userId!: string | undefined;

  @Input()
  maxNestingLevel!: number | undefined;

  @Input()
  messageProcessor: ((node: ThreadNode) => string) = node => node.message;

  /*
    Allows visualization of sub-thread
   */
  @Input()
  rootNodeId!: string | undefined;

  /*
    Observable that pipes the whole thread received from the MessageThreadService.
   */
  @Input("thread")
  threadPipe!: Observable<ThreadView | undefined>;

  /*
    Context specific actions that are shown under the burger menu of a message
   */
  @Input()
  contextButtonDefinition!: MessageContextButtonDefinition[];

  @Input()
  showDefaultButtons = true;

  /*
    All events triggered by actions on a message (vote, update, delete, reply)
    And any other context specific action
   */
  @Output()
  public event = new EventEmitter<ThreadEvent>();

  public thread!: ThreadView;
  private subscription!: Subscription;

  ngOnInit() {
    this.subscription = this.threadPipe.subscribe(thread => {
      if (thread !== undefined) {
        this.thread = thread;
      } else {
        this.subscription.unsubscribe();
      }
    });
    if (this.messageProcessor === undefined) {
      this.messageProcessor = (node: ThreadNode) => node.message;
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public forwardEvent(event: ThreadEvent) {
    this.event.emit(event);
  }
}
