import {RouterModule} from "@angular/router";
import {CommonModule} from "@angular/common";
import {MindMapModule} from "../mind-map/mind-map.module";
import {NgModule} from "@angular/core";
import {STATE} from "../app-states";
import {ProblemsComponent} from "./problems.component";
import {MatDividerModule} from "@angular/material/divider";
import {MatIconModule} from "@angular/material/icon";
import {SelectedOrganizationResolve} from "../../auth/organization.resolvers";
import {UserResolve} from "../../auth/auth.resolvers";
import {MatRippleModule} from "@angular/material/core";
import {ProblemsListComponent} from "./problems-list.component";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {ProblemResolve} from "../../revogo-client/revogo-client.resolvers";
import {
  ProblemDetailsComponent,
} from "./problem-details.component";
import {MatDialogModule} from "@angular/material/dialog";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {FormsModule} from "@angular/forms";
import {HeaderModule} from "../header/header.module";


export const PROBLEMS_DETAILS_STATE = {
  path: ':problemId',
  component: ProblemDetailsComponent,
  resolve: {
    problem: ProblemResolve,
  }
};


export const PROBLEMS_LIST_STATE = {
  path: STATE.PROBLEMS_LIST,
  component: ProblemsListComponent
};

export const PROBLEMS_STATE = {
  path: STATE.PROBLEMS,
  component: ProblemsComponent,
  resolve: {
    selectedOrganization: SelectedOrganizationResolve,
    userData: UserResolve
  },
  children: [
    PROBLEMS_LIST_STATE,
    PROBLEMS_DETAILS_STATE,
  ]
}

@NgModule({
    imports: [
        CommonModule,
        MindMapModule,
        MatDividerModule,
        MatIconModule,
        MatRippleModule,
        RouterModule.forChild([PROBLEMS_STATE]),
        MatButtonModule,
        MatProgressSpinnerModule,
        MatButtonToggleModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        HeaderModule
    ],
  declarations: [
    ProblemsComponent,
    ProblemsListComponent,
    ProblemDetailsComponent,
  ],
  providers: [UserResolve, SelectedOrganizationResolve, ProblemResolve],
})
export class ProblemsModule {
}
