import {Component, Input, OnInit} from "@angular/core";
import {Session} from "../../../revogo-client/revogo-client.types";
import {RevogoClientService} from "../../../revogo-client/revogo-client.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {StateRouterService} from "../../../state-router/state-router.service";
import {STATE} from "../../app-states";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-session-start-button',
  templateUrl: './session-start-button.component.html',
  styleUrls: ['./session-start-button.component.sass']
})
export class SessionStartButtonComponent implements OnInit {
  @Input()
  session!: Session;

  @Input()
  redirect!: boolean;

  constructor(private revogoClient: RevogoClientService,
              private stateRouter: StateRouterService,
              private _snackBar: MatSnackBar,
              private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    if (this.redirect === undefined) {
      this.redirect = true;
    }
  }


  private errorSnack(msg: string) {
    this._snackBar.open(msg, undefined, {
      duration: 5000,
      panelClass: ['error-snackbar']
    })
  }

  public openModal() {
    const dialogRef = this.dialog.open(StartSessionModalComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.startSession();
      }
    });
  }

  public startSession() {
    this.revogoClient.startSession(this.session.sessionId).toPromise().then(
      () => {
        this._snackBar.open("Les participants peuvent maintenant contribuer", undefined, {duration: 5000});
        if (this.redirect) {
          this.stateRouter.nav(STATE.SESSIONS, this.session.sessionId, STATE.SESSIONS_RESULTS);
        }
      }
    ).catch(err => {
      switch (err.code) {
        case 400:
          this.errorSnack("La session est déjà en cours");
          break;
        case 403:
        case 401:
          this.errorSnack("Vous n'aveez pas les droits nécéssaires pour effectuer cette opération.")
          break;
        case 404:
          this.errorSnack("Cette session n'existe pas")
          break;
        default:
          this.errorSnack(`Erreur inconnue: ${err}`);
      }
    });
  }
}

@Component({
  selector: 'app-session-start-modal',
  template: `
    <h2 mat-dialog-title>Êtes-vous sûr(e) de vouloir démarrer cette session ?</h2>
    <mat-dialog-content class="mat-typography">
      <p>Les participants pourront se connecter et contribuer.</p>
      <p>Vous ne pourrez plus modifier la session</p>
      <p>Vous ne pouvez pas annuler cette action.</p>
    </mat-dialog-content>
    <mat-dialog-actions class="flex">
      <button mat-stroked-button [mat-dialog-close]="false">Annuler</button>
      <div class="flex-grow"></div>
      <button mat-raised-button color="primary" [mat-dialog-close]="true">Confirmer</button>
    </mat-dialog-actions>
  `
})
export class StartSessionModalComponent {}
