import {STATE} from "../app-states";
import {RouterModule} from "@angular/router";
import {NgModule} from "@angular/core";
import {JoinComponent} from "./join.component";
import {CommonModule} from "@angular/common";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";

export const JOIN_STATE = {
  path: `${STATE.JOIN}/:inviteSlug`,
  component: JoinComponent,
};


@NgModule({
  providers: [],
  declarations: [JoinComponent],
  imports: [CommonModule, MatProgressSpinnerModule, RouterModule.forChild([JOIN_STATE])]
})
export class JoinModule {

}
