import {Component, Input, OnInit} from "@angular/core";
import {SessionState} from "../../revogo-client/revogo-client.types";

@Component({
  selector: 'app-session-state',
  template: `{{displayName}}`,
})
export class SessionStateComponent implements OnInit {
  @Input()
  public state!: SessionState;
  public displayName!: string;

  ngOnInit() {
    this.displayName = {
      [SessionState.Draft]: 'Brouillon',
      [SessionState.Open]: 'Ouverte',
      [SessionState.Started]: 'En cours',
      [SessionState.Finished]: 'Terminée',
    }[this.state];
  }
}
