import { NgModule } from "@angular/core";

import { SessionsComponent } from "./sessions.component";
import {MatInputModule} from '@angular/material/input';
import { MatButtonModule } from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import { UserResolve } from "src/auth/auth.resolvers";
import {STATE} from "../app-states";
import {SelectedOrganizationResolve} from "../../auth/organization.resolvers";
import {SessionListComponent} from "./session-list/session-list.component";
import {OrganizationSessionsResolve, SessionResolve} from "../../revogo-client/revogo-client.resolvers";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {SessionCardModule} from "./session-card/session-card.module";
import {SessionNewComponent} from "./session-new/session-new.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {SaveChangesDialogComponent, SessionDetailsComponent} from "./session-details/session-details.component";
import {
  CloneSessionModalComponent,
  DeleteSessionModalComponent,
  SessionMetadataComponent
} from "./session-details/session-metadata.component";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatDividerModule} from "@angular/material/divider";
import {SessionTypeComponent} from "./session-type.component";
import {SessionParticipationModeComponent} from "./session-participation-mode.component";
import {SessionStateComponent} from "./session-state.component";
import {SessionContentComponent} from "./session-details/session-content.component";
import {
  QcmSessionContentComponent
} from "./session-details/session-content/qcm-session-content.component";
import { QcmAnswerTypeComponent } from "./qcm-answer-type.component";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatDialogModule} from "@angular/material/dialog";
import {CloseSessionModalComponent} from "./session-card/session-close-button.component";
import {StartSessionModalComponent} from "./session-card/session-start-button.component";
import {MatTableModule} from "@angular/material/table";
import {MindMapModule} from "../mind-map/mind-map.module";
import {SessionMindMapEntitiesComponent} from "./session-details/session-mind-map-entities/session-mind-map-entities.component";
import {MatListModule} from "@angular/material/list";
import {MatRippleModule} from "@angular/material/core";
import {QcmSessionResultsComponent} from "./session-details/session-results/qcm-session-results.component";
import {SessionResultsComponent} from "./session-details/session-results.component";
import {SessionPageComponent} from "./session-details/session-page.component";
import {MatTabsModule} from '@angular/material/tabs';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {ProblemAnalysisSessionContentComponent} from "./session-details/session-content/problem-analysis-session-content.component";
import {MatMenuModule} from '@angular/material/menu';
import {ProblemAnalysisSessionResultsComponent} from "./session-details/session-results/problem-analysis-session-results.component";
import {MessageThreadModule} from "../message-thread/message-thread.module";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {HeaderModule} from "../header/header.module";


const SESSIONS_RESULTS_STATE = {
  path: STATE.SESSIONS_RESULTS,
  component: SessionResultsComponent,
  resolve: {
    session: SessionResolve
  }
}


const SESSIONS_DETAILS_STATE = {
  path: STATE.SESSIONS_DETAILS,
  component: SessionDetailsComponent,
  resolve: {
    session: SessionResolve,
  }
};

const SESSIONS_ITEM_STATE =  {
  path: ':sessionId',
  component: SessionPageComponent,
  resolve: {
    session: SessionResolve,
  },
  children: [
    SESSIONS_DETAILS_STATE,
    SESSIONS_RESULTS_STATE,
  ]
}

const SESSIONS_LIST_STATE = {
  path: STATE.SESSIONS_LIST,
  component: SessionListComponent,
  resolve: {
    sessions: OrganizationSessionsResolve
  }
}

const SESSIONS_NEW_STATE = {
  path: STATE.SESSIONS_NEW,
  component: SessionNewComponent,
  resolve: {
    organization: SelectedOrganizationResolve,
  }
};

export const SESSIONS_STATE = {
  path: STATE.SESSIONS,
  component: SessionsComponent,
  resolve: {
    selectedOrganization: SelectedOrganizationResolve,
    userData: UserResolve
  },
  children: [
    SESSIONS_NEW_STATE,
    SESSIONS_LIST_STATE,
    SESSIONS_ITEM_STATE,
  ],
};

@NgModule({
  declarations: [
    SessionTypeComponent,
    SessionParticipationModeComponent,
    SessionContentComponent,
    SessionStateComponent,
    SessionsComponent,
    SessionListComponent,
    SessionNewComponent,
    SessionDetailsComponent,
    SessionMetadataComponent,
    SessionResultsComponent,
    QcmSessionResultsComponent,
    QcmSessionContentComponent,
    QcmAnswerTypeComponent,
    CloseSessionModalComponent,
    StartSessionModalComponent,
    SaveChangesDialogComponent,
    CloneSessionModalComponent,
    SessionMindMapEntitiesComponent,
    SessionPageComponent,
    ProblemAnalysisSessionContentComponent,
    ProblemAnalysisSessionResultsComponent,
    DeleteSessionModalComponent,
  ],
    imports: [
        SessionCardModule,
        CommonModule,
        RouterModule.forChild([SESSIONS_STATE]),
        MindMapModule,
        MatInputModule,
        MatButtonModule,
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatExpansionModule,
        MatDividerModule,
        MatTooltipModule,
        MatDialogModule,
        MatTableModule,
        MatListModule,
        MatRippleModule,
        MatTabsModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        MessageThreadModule,
        MatCheckboxModule,
        HeaderModule
    ],
  providers: [UserResolve, SelectedOrganizationResolve, OrganizationSessionsResolve],
    exports: [
        SessionTypeComponent,
        SessionParticipationModeComponent,
    ]
})
export class SessionsModule {}
