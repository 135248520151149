import { Injectable } from "@angular/core";
import {Router} from "@angular/router";
import {absStateUrl, STATE} from "../app/app-states";


@Injectable({
  providedIn: 'root',
})
export class StateRouterService {
  constructor(private router: Router) {}

  public makePath(...states: (STATE | string)[]): string {
    return absStateUrl(states);
  }

  public nav(...states: (STATE | string)[]) {
    return this.router.navigate([this.makePath(...states)]);
  }

  public url() {
    return this.router.url;
  }

  public path() {
    const url = this.url();
    return url.split('/');
  }
}
