import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {
  QcmProposition,
  QcmPropositionAnswerType,
  QcmSession,
  Session
} from "../../../revogo-client/revogo-client.types";
import {QcmSessionService} from "../../../revogo-client/qcm-session.service";
import {STATE} from "../../app-states";
import {StateRouterService} from "../../../state-router/state-router.service";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-play-qcm',
  templateUrl: './play-qcm.component.html',
  styleUrls: ['./play-qcm.component.sass']
})
export class PlayQcmComponent implements OnInit, OnDestroy {
  @Input("session")
  public _session!: Session;
  public session!: QcmSession;

  public propositions!: QcmProposition[];
  public pI: number = 0; // Proposition index. Abbreviated for template readability
  public qcmPropositionAnswerType = QcmPropositionAnswerType;
  public form!: FormGroup;
  public ready = false;

  constructor(private qcmSessionService: QcmSessionService,
              private stateRouter: StateRouterService,
              private formBuilder: FormBuilder) {
  }

  async ngOnInit() {
    this.session = this._session as QcmSession;
    this.propositions = this.session.sessionDefinition.propositions;
    await this.qcmSessionService.bindToSession(this.session.sessionId);
    this.updateForm();
    if (this.qcmSessionService.validated) {
      await this.stateRouter.nav(STATE.PLAY, STATE.PLAY_END);
    }
    this.ready = true;
  }

  async ngOnDestroy() {
    await this.qcmSessionService.unbind();
  }

  public updateForm() {
    const storedAnswer = this.qcmSessionService.getAnswer(this.pI);
    const freeTextControls: { [key: string]: string } = {};

    const allowedAnswers = this.propositions[this.pI].allowedAnswers;

    allowedAnswers.forEach((answer, index) => {
      if (answer.type === QcmPropositionAnswerType.FreeText) {
        freeTextControls[`freeText${index}`] = '';
      }
    });

    if (storedAnswer !== undefined) {
      if (allowedAnswers[storedAnswer.index].type === QcmPropositionAnswerType.FreeText
        && storedAnswer.content !== undefined) {
        freeTextControls[`freeText${storedAnswer.index}`] = storedAnswer.content;
      }
      this.form = this.formBuilder.group({answer: [storedAnswer.index], ...freeTextControls});
    } else {
      this.form = this.formBuilder.group({answer: [], ...freeTextControls});
    }
  }

  public previous() {
    this.ready = false;
    if (this.pI > 0) {
      this.pI -= 1;
      this.updateForm();
    }
    this.ready = true;
  }

  public async next() {
    this.ready = false;
    if (this.pI < this.propositions.length) {
      if (!this.form.pristine) {
        const answerIndex = this.form.controls.answer.value;
        if (this.propositions[this.pI].allowedAnswers[answerIndex].type === QcmPropositionAnswerType.FreeText) {
          this.qcmSessionService.setAnswer(this.pI, this.form.controls.answer.value, this.form.controls[`freeText${answerIndex}`].value);
        } else {
          this.qcmSessionService.setAnswer(this.pI, this.form.controls.answer.value);
        }
        await this.qcmSessionService.saveState();
      }
      this.pI += 1;
    } if (this.pI === this.propositions.length) {
      this.qcmSessionService.validate();
      await this.qcmSessionService.saveState();
      await this.stateRouter.nav(STATE.PLAY, STATE.PLAY_END);
    } else {
      this.updateForm();
    }
    this.ready = true;
  }

  public freeTextIsFilled(): boolean {
    const answerIndex = this.form.controls.answer.value;
    if (answerIndex === null) {
      return  false;
    }
    else if (this.propositions[this.pI].allowedAnswers[answerIndex].type === QcmPropositionAnswerType.FreeText) {
      return this.form.controls[`freeText${answerIndex}`].value.trim().length > 0;
    } else {
      return true;
    }
  }
}
