import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class UserResolve implements Resolve<{[key: string]: any}> {

  constructor(private authService: AuthService) {}

  async resolve(route: ActivatedRouteSnapshot) {
    if (!this.authService.isAuthenticated()) {
      throw new Error('Not authenticated');
    } else {
      await this.authService.syncSession();
      return this.authService.getUserData();
    }
  }
}
