import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {StateRouterService} from "../../state-router/state-router.service";
import {AuthService} from "../../auth/auth.service";
import {STATE} from "../app-states";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
const AmazonCognitoIdentity = require('amazon-cognito-identity-js');

@Component({
  selector: 'app-confirm-registration',
  template: `
    <div class="body-container">
      <div class="verification-code-container">
        <h3>Nous avons besoin de vérifier votre addresse email</h3>
        <h4>Veuillez renseigner le code secret que vous avez reçu par email</h4>

        <form [formGroup]="form" (submit)="confirmRegistration()" action="#">
          <div>
            <mat-form-field>
              <mat-label>Code de vérification</mat-label>
              <input matInput type="text" required formControlName="verificationCode">
            </mat-form-field>
          </div>
          <div>
            <button mat-raised-button color="primary" [disabled]="form === undefined || !form.valid" type="submit">
              Confirmer
            </button>
          </div>
        </form>


      </div>
    </div>
  `,
  styleUrls: ['./confirm-registration.component.sass']
})
export class ConfirmRegistrationComponent implements OnInit {
  public email!: string;
  public form!: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private stateRouterService: StateRouterService,
    private authService: AuthService,
    private snackbar: MatSnackBar) {
  }

  ngOnInit() {
    const username = this.route.snapshot.queryParams['u'];
    if (username === undefined) {
      this.stateRouterService.nav(STATE.LOGIN);
    } else {
      this.email = username;
      this.form = new FormGroup({
        verificationCode: new FormControl('', Validators.required),
      });
    }
  }

  public confirmRegistration() {
    const userData = {
      Username: this.email,
      Pool: this.authService.userPool,
    };
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    cognitoUser.confirmRegistration(this.form.controls.verificationCode.value, true, (err: any, result: any) => {
      if (err) {
        if (err.name === 'ExpiredCodeException') {
          this.snackbar.open('Ce code a expiré. Nous vous en renvoyons un autre.', undefined, {panelClass: ['error-snackbar'], duration: 10000});
          cognitoUser.resendConfirmationCode((err: any, result: any) => {
            if (err) {
              this.snackbar.open("Nous n'avons pas pu vous renvoyer un code de confirmation. Vous pouvez essayer de passer par l'écran d'inscription ou bien contacter un administrateur.", undefined, {panelClass: ['error-snackbar'], duration: 10000})
            }
          });
        } else if (err.name === 'NotAuthorizedException') {
          this.snackbar.open("Votre email à déja été verifié. Vous allez être redirigé vers l'écran de connexion.", undefined, {duration: 3000});
          setTimeout(() => {this.stateRouterService.nav(STATE.LOGIN);}, 3000);
        } else if (err.name === 'CodeMismatchException') {
          this.snackbar.open('Code incorrect.', undefined, {panelClass: ['error-snackbar'], duration: 5000});
        } else {
          console.log(err);
          this.snackbar.open(`Erreur de vérification "${err.name}": ${err.message}`, undefined, {panelClass: ['error-snackbar'], duration: 10000});
        }
      } else if (result === 'SUCCESS') {
        this.snackbar.open('Votre adresse email a été vérifiée. Vous allez être redirigé vers l\'écran de connexion.');
        setTimeout(() => {this.stateRouterService.nav(STATE.LOGIN);}, 3000);
      }
    });
  }
}
