import {RouterModule} from "@angular/router";
import {UserResolve} from "../../auth/auth.resolvers";
import {SelectedOrganizationResolve} from "../../auth/organization.resolvers";
import {CommonModule} from "@angular/common";
import {NgModule} from "@angular/core";
import {STATE} from "../app-states";
import {SolutionResolve} from "../../revogo-client/revogo-client.resolvers";
import {SolutionsComponent} from "./solutions.component";
import {MatRippleModule} from "@angular/material/core";
import {MatDividerModule} from "@angular/material/divider";
import {MatIconModule} from "@angular/material/icon";
import {SolutionDetailsComponent} from "./solution-details.component";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MindMapModule} from "../mind-map/mind-map.module";
import {MatFormFieldModule} from "@angular/material/form-field";
import {FormsModule} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {MatDialogModule} from "@angular/material/dialog";
import {HeaderModule} from "../header/header.module";

export const SOLUTIONS_DETAILS_STATE = {
  path: ':solutionId',
  component: SolutionDetailsComponent,
  resolve: {
    solution: SolutionResolve,
  }
}

export const SOLUTIONS_STATE = {
  path: STATE.SOLUTIONS,
  component: SolutionsComponent,
  resolve: {
    selectedOrganization: SelectedOrganizationResolve,
    userData: UserResolve,
  },
  children: [
    SOLUTIONS_DETAILS_STATE
  ]
}

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild([SOLUTIONS_STATE]),
        MatRippleModule,
        MatDividerModule,
        MatIconModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MindMapModule,
        MatFormFieldModule,
        FormsModule,
        MatInputModule,
        MatDialogModule,
        HeaderModule,
    ],
  declarations: [SolutionsComponent, SolutionDetailsComponent],
  exports: [],
  providers: [UserResolve, SelectedOrganizationResolve, SolutionResolve]
})
export class SolutionsModule {
}
