<div class="body-container">
  <div class="info-container">
    <h3>Bienvenue</h3>

    <div *ngIf="messages.length" id="join-session-message">
      <div *ngFor="let message of messages">
        {{message}}
      </div>
    </div>
    <mat-spinner *ngIf="loading;" diameter="56"></mat-spinner>

  </div>
</div>
