import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-edit-thread-message-modal',
  template: `
    <mat-dialog-content class="mat-typography">
      <form [formGroup]="form">
        <mat-form-field class="w-full my-2">
          <mat-label>Votre réponse</mat-label>
          <textarea rows="10" class="w-full" matInput formControlName="message"></textarea>
        </mat-form-field>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions class="flex">
      <button mat-stroked-button color="primary" [mat-dialog-close]="null">
        Annuler
      </button>
      <div class="flex-grow"></div>
      <button mat-raised-button color="primary" [mat-dialog-close]="form.controls.message.value" [disabled]="!form.valid || !form.dirty">
        Valider
      </button>
    </mat-dialog-actions>
  `
})
export class EditThreadMessageModalComponent implements OnInit {
  public form!: FormGroup;
  private _message: string = '';

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      message: new FormControl(this._message, Validators.required)
    });
  }

  public set message(msg: string) {
    this._message = msg;
    if (this.form !== undefined) {
      this.form.controls.message.setValue(msg);

    }
  }
}
