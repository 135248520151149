import {Component, Input, OnInit} from "@angular/core";
import {OrganizationService} from "../../auth/organization.service";
import {MatDialog} from "@angular/material/dialog";
import {RevogoClientService} from "../../revogo-client/revogo-client.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Organization, UserData, UserOrganizationAuthorization} from "../../revogo-client/revogo-client.types";
import {HttpErrorResponse} from "@angular/common/http";
import {GenericConfirmationModalComponent} from "../../modals/generic-confirmation-modal.component";
import {FormControl, FormGroup, Validators} from "@angular/forms";

type RoleData = {
  displayName: string,
  info: string
};

type RoleFormModel = {
  role: string | undefined,
  dirty: boolean,
  updating: boolean,
  user: UserData,
}

@Component({
  selector: 'app-admin-panel',
  template: `
    <div class="container">
      <app-header></app-header>
      <div class="app-content-container">
        <div class="m-2 text-lg primary-text">
          Administration
        </div>
        <div class="m-2 flex">
          <div>
            <span *ngIf="superadmin"
                  matTooltip="Un grand pouvoir implique de grandes responsabilités. Il est facile de casser quelque chose depuis cette page.">
            Vous êtes super-administrateur. Vous êtes propriétaire de cette application et avez donc tout les droits sur ses utilisateurs.
          </span>
            <span *ngIf="!superadmin && orgAdmin">
            Vous êtes administrateur de l'organisation selectionnée. Vous pouvez gérer les droits de vos collaborateurs au sein de cette organisation.
          </span>
          </div>
          <div class="flex-grow">
          </div>
          <div class="mx-2" *ngIf="superadmin">
            <button mat-stroked-button color="warn" (click)="addSuperAdminModal()">
              <mat-icon>star</mat-icon>
              Super-administrateurs
            </button>
          </div>
          <div class="mx-2" *ngIf="superadmin">
            <button mat-raised-button color="primary" (click)="createOrganisationModal()">
              <mat-icon>corporate_fare</mat-icon>
              Créer une nouvelle organisation
            </button>
          </div>
          <div class="mx-2">
            <button mat-stroked-button color="primary" (click)="helpModal()">
              <mat-icon color="primary">help</mat-icon>
              Aide
            </button>
          </div>
        </div>

        <mat-divider class="m-2"></mat-divider>
        <div class="m-2" *ngIf="orgAdmin && userOrganizationAuthorizations !== undefined">
          <div class="text-lg primary-text">
            {{organization.organizationName}} comporte {{userOrganizationAuthorizations.length}}
            collaborateur{{userOrganizationAuthorizations.length > 1 ? 's' : ''}}
            enregistré{{userOrganizationAuthorizations.length > 1 ? 's' : ''}}
          </div>

          <div class="m-2">
            <button mat-stroked-button color="primary" (click)="setAddingUser()">Ajouter un utilisateur
              à {{organization.organizationName}}</button>
          </div>

          <div class="m-2 w-full" *ngIf="addingUser">
            <div class="my-2">
              Saisissez l'adresse e-mail avec laquelle votre collaborateur s'est inscrit.
            </div>
            <mat-form-field class="w-full">
              <mat-label>Adresse e-mail de l'utilisateur</mat-label>
              <input type="text" matInput class="w-full" [(ngModel)]="newUserEmail">
            </mat-form-field>
            <button mat-raised-button color="primary" [disabled]="!newUserEmail" (click)="addUserByEmail()">
              <mat-icon>done</mat-icon>
              Confirmer
            </button>
          </div>

          <mat-divider></mat-divider>

          <ng-container *ngFor="let authorization of userOrganizationAuthorizations" class="m-4">
            <div class="m-4 flex">
              <div class="mt-2">
                <mat-icon>face</mat-icon>
              </div>
              <div class="mt-2 mx-2 primary-text" matTooltip="{{authorization.user.email}}">
                {{authorization.user.givenName}} {{authorization.user.familyName}}
              </div>
              <div class="mx-2">
                <mat-form-field>
                  <mat-label>Rôle</mat-label>
                  <mat-select [(ngModel)]="userRoles[authorization.userId].role"
                              (selectionChange)="setRoleChanged(authorization.userId)">
                    <mat-option *ngFor="let role of usableRoles"
                                [value]="role">{{roleData[role].displayName}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="userRoles[authorization.userId].dirty && !userRoles[authorization.userId].updating"
                   class="mx-2 mt-2">
                <button mat-mini-fab color="primary" matTooltip="Enregistrer les modifications"
                        (click)="saveRoleUpdate(authorization.userId)">
                  <mat-icon>done</mat-icon>
                </button>
              </div>
              <div *ngIf="userRoles[authorization.userId].updating" class="mx-2 mt-2">
                <mat-spinner [diameter]="32"></mat-spinner>
              </div>
              <div class="flex-grow"></div>
              <div class="mx-2 mt-2">
                <button mat-icon-button color="error" (click)="deleteAuthorizationModal(authorization.userId)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
            </div>
            <mat-divider class="mx-4"></mat-divider>
          </ng-container>
        </div>
        <div class="m-2 flex" *ngIf="userOrganizationAuthorizations === undefined">
          <div class="flex-grow"></div>
          <mat-spinner></mat-spinner>
          <div class="flex-grow"></div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./admin-panel.component.sass']
})
export class AdminPanelComponent implements OnInit {
  public superadmin!: boolean;
  public orgAdmin!: boolean;
  public organization!: Organization;
  public userOrganizationAuthorizations!: UserOrganizationAuthorization[];
  public usableRoles = ["administrator", "manager", "sage", "reader", "player"];
  public roleData: { [key: string]: RoleData } = {
    administrator: {
      displayName: "Administrateur",
      info: "L'administrateur d'une organisation peut ajouter / enlever des collaborateurs et gérer leurs droits."
    },
    manager: {
      displayName: "Manager",
      info: "Le manager peut gérer des sessions, créer / modifier des problèmes, des solutions et des arguments."
    },
    reader: {
      displayName: "Lecteur",
      info: "Le lecteur à le droit de consulter les données de Revogo dans son organisation (sessions, problèmes, solutions, arguments)"
    },
    sage: {
      displayName: "Sage",
      info: "Le sage à le droit de modérer les sessions. Il possède également les droits de lecture."
    },
    player: {
      displayName: "Joueur",
      info: "Le joueur à le droit de participer à des sessions en tant qu'utilisateur authentifié. Rien de plus."
    }
  };

  public userRoles!: { [key: string]: RoleFormModel };
  public addingUser = false;
  public newUserEmail = '';

  constructor(
    private organizationService: OrganizationService,
    private dialog: MatDialog,
    private revogoClient: RevogoClientService,
    private snackbar: MatSnackBar) {
  }

  async ngOnInit() {
    const organization = await this.organizationService.getSelectedOrganization().toPromise();
    if (organization !== null) {
      this.organization = organization;
    }
    this.superadmin = await this.organizationService.userHasRole(['superadmin']);
    this.orgAdmin = await this.organizationService.userHasRole(['administrator']);
    this.userOrganizationAuthorizations = await this.revogoClient.getOrganizationUserAuthorizations(this.organization.organizationId).toPromise();
    this.updateMappings();
  }

  public updateMappings() {
    const roleMappings: { [key: string]: RoleFormModel } = {};
    this.userOrganizationAuthorizations.forEach(auth => {
      roleMappings[auth.userId] = {role: auth.roles[0], dirty: false, updating: false, user: auth.user};
    });
    this.userRoles = roleMappings;
  }

  public setRoleChanged(userId: string) {
    this.userRoles[userId].dirty = true;
  }

  public async saveRoleUpdate(userId: string) {
    const userEmail = this.userRoles[userId].user.email;
    const role = this.userRoles[userId].role;
    if (role === undefined) {
      this.updateMappings();
    } else {
      this.userRoles[userId].updating = true;
      await this.revogoClient.upsertUserAuthorization(this.organization.organizationId, userEmail, role).toPromise();
      this.userOrganizationAuthorizations = await this.revogoClient.getOrganizationUserAuthorizations(this.organization.organizationId).toPromise();
      this.updateMappings();
    }
  }

  public setAddingUser() {
    this.addingUser = true;
    this.newUserEmail = '';
  }

  public async addUserByEmail() {
    const auth = await this.revogoClient.getUserAuthorizations(this.organization.organizationId, this.newUserEmail).toPromise().catch(
      (error: HttpErrorResponse) => {
        if (error.status === 404) {
          this.snackbar.open("Nous ne connaissons pas d'utilisateur inscrit avec cette adresse.", undefined, {
            panelClass: ['error-snackbar'],
            duration: 10000
          });
        } else {
          this.snackbar.open(`Erreur inconnue: ${error.message}`);
        }
      }
    );
    if (auth !== undefined) {
      this.snackbar.open("Utilisateur trouvé. Vous pouvez maintenant lui assigner un role.", undefined, {duration: 10000});
      this.addingUser = false;
      this.newUserEmail = '';
      this.userOrganizationAuthorizations.unshift(auth);
      this.updateMappings();

    }
  }

  public createOrganisationModal() {
    const ref = this.dialog.open(CreateOrganizationModalComponent);

    ref.afterClosed().subscribe(async result => {
      if (result) {
        await this.revogoClient.createOrganisation(result.organizationName).toPromise().then(
          () => {
            this.snackbar.open("Organisation créée avec succès. Vous pouvez y accéder depuis le menu de votre profil.", undefined, {duration: 5000});
          }
        ).catch(
          () => {
            this.snackbar.open("Erreur lors de la création. Contactez un administrateur.", undefined, {
              panelClass: ['error-snackbar'],
              duration: 20000
            });
          }
        );
      }
    });
  }

  public deleteAuthorizationModal(userId: string) {
    const user = this.userRoles[userId].user;
    const ref = this.dialog.open(GenericConfirmationModalComponent, {panelClass: 'wide-modal'});
    ref.componentInstance.title = `Supprimer les autorisations de cet utilisateur au sein de ${this.organization.organizationName} ?`;
    ref.componentInstance.textLines = [
      `${user.givenName} ${user.familyName} (${user.email}) n'aura plus aucun droit sur cette organisation.`,
      "Vous pourrez toujours lui redonner des autorisations plus tard."
    ];

    ref.afterClosed().subscribe(async confirmed => {
      if (confirmed) {
        await this.revogoClient.deleteUserAuthorization(this.organization.organizationId, user.email).toPromise();
        this.userOrganizationAuthorizations = await this.revogoClient.getOrganizationUserAuthorizations(this.organization.organizationId).toPromise();
        this.updateMappings();
      }
    });
  }

  public helpModal() {
    const ref = this.dialog.open(AdminPanelHelpModalComponent, {panelClass: 'wide-modal'});

    ref.componentInstance.roles = this.usableRoles;
    ref.componentInstance.roleData = this.roleData;
  }

  public async addSuperAdminModal() {
    const admins = await this.revogoClient.getSuperAdmins(this.organization.organizationId).toPromise();
    const ref = this.dialog.open(AdminPanelAddSuperAdminModalComponent, {panelClass: 'wide-modal'});

    ref.componentInstance.superAdministrators = admins;

    ref.afterClosed().subscribe(async (confirmedUserEmail: string | undefined) => {
      if (confirmedUserEmail !== undefined) {
        await this.revogoClient.upsertSuperAdmin(this.organization.organizationId, confirmedUserEmail).toPromise().then(() => {
          this.snackbar.open('Super-administrateur ajouté avec succès.', undefined, {duration: 10000});
        }).catch((err: HttpErrorResponse) => {
          if (err.status === 404) {
            this.snackbar.open("Nous ne connaissons pas d'utilisateur avec cet email.", undefined, {duration: 5000, panelClass: ['error-snackbar']});
          } else {
            this.snackbar.open("Erreur inconnue. Le super-administrateur n'a pas été ajouté.", undefined, {duration: 5000, panelClass: ['error-snackbar']});
          }
        });
      }
    });
  }
}

@Component({
  selector: 'app-create-organization-modal',
  template: `
    <mat-dialog-content>
      <div class="m-2 text-center">
        Vous vous apprêtez à créer une nouvelle organisation. En tant que super administrateur, vous aurez ensuite la
        possibilité de la selectionner et d'y ajouter des utilisateurs.
      </div>
      <div class="m-2 w-full">
        <mat-form-field class="w-full">
          <mat-label>Nom de l'organisation</mat-label>
          <input class="w-full" type="text" matInput [(ngModel)]="organizationName" required>
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions class="flex">
      <div>
        <button mat-stroked-button color="error" [mat-dialog-close]="undefined">Annuler</button>
      </div>
      <div class="flex-grow"></div>
      <div>
        <button mat-raised-button color="primary" [mat-dialog-close]="{organizationName: organizationName}">Confirmer
        </button>
      </div>
    </mat-dialog-actions>
  `
})
export class CreateOrganizationModalComponent {
  public organizationName: string = '';
}

@Component({
  selector: 'app-admin-panel-add-superadmin-modal',
  template: `
    <mat-dialog-content>
        <div class="m-2 text-center">
            Les super-administrateurs ont le contrôle complet de l'application. N'ajoutez que les personnes en qui vous avez entièrement confiance.
        </div>
      <div class="m-2 text-center">
        Pour des raisons de sécurité, il n'est pas possible de supprimer un super-administrateur par cette interface : un acteur mal intentionné pourrait prendre le contrôle exclusif de l'application.
      </div>
      <div class="m-2 text-center">
        Vous pouvez ajouter un super-administrateur en saisissant ci-dessous l'adresse email avec laquelle la personne s'est inscrit.
      </div>
      <div class="m-4 w-full">
        <form [formGroup]="form">
          <mat-form-field class="w-full pr-4">
            <mat-label>Adresse email</mat-label>
            <input formControlName="userEmail" matInput type="email" class="w-full">
          </mat-form-field>
        </form>
      </div>

      <div class="m-2">
        Super-administrateurs connus :
        <div class="overflow-y-auto">
          <div *ngIf="superAdministrators.length === 0">(Aucun)</div>
          <div class="m-2 p-2 border rounded-sm border-gray-200" *ngFor="let admin of superAdministrators">
            {{admin.user.givenName}} {{admin.user.familyName}} ({{admin.user.email}})
          </div>
        </div>
      </div>

    </mat-dialog-content>
    <mat-dialog-actions class="flex">
        <div>
          <button mat-stroked-button color="primary" [mat-dialog-close]="undefined">
            Fermer
          </button>
        </div>
        <div class="flex-grow"></div>
        <div>
          <button mat-stroked-button color="warn" [mat-dialog-close]="form.controls.userEmail.value" [disabled]="!form.valid">
            Ajouter
          </button>
        </div>
    </mat-dialog-actions>
  `
})
export class AdminPanelAddSuperAdminModalComponent implements OnInit {
  @Input()
  public superAdministrators!: UserOrganizationAuthorization[];

  public form!: FormGroup;

  ngOnInit() {
    this.form = new FormGroup({
      userEmail: new FormControl('', [Validators.required, Validators.email]),
    })
  }
}

@Component({
  selector: 'app-admin-panel-help-modal',
  template: `
    <mat-dialog-content>
      <h2 mat-dialog-title class="text-center">Administration</h2>
      <div class="m-2 text-center">
        Cette page vous permet d'administrer les permissions des utilisateurs de votre organisation.
      </div>
      <div class="m-2 text-center">
        Seuls les utilisateurs que vous ajoutez sur cette page et les super-administrateurs auront la possibilité de consulter et de modifier les informations stockées par revogo.
      </div>
      <div class="m-2">
        <div class="font-bold">Information sur les rôles</div>
        <div>Les rôles sont hiérarchisé. C'est à dire que chaque rôle donne les permissions des rôles inférieurs en plus des permissions qui lui sont propre.</div>
        <div *ngFor="let role of roles" class="m-2">
          <span class="font-bold">{{roleData[role].displayName}}</span> : <span>{{roleData[role].info}}</span>
        </div>

        <div class="m-4 text center font-bold flex">
          <mat-icon color="error">report_problem</mat-icon>
          <span class="mt-1">
            Si vous retirez votre rôle d'administrateur, vous ne pourrez pas vous le redonner par vous même !
          </span>
        </div>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions class="flex">
      <div class="flex-grow"></div>
      <div>
        <button mat-raised-button color="primary" [mat-dialog-close]="null">Ok</button>
      </div>
      <div class="flex-grow"></div>
    </mat-dialog-actions>
  `
})
export class AdminPanelHelpModalComponent {
  @Input()
  roles!: string[];

  @Input()
  roleData!: {[key: string]: RoleData};
}
