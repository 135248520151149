import {Injectable} from "@angular/core";
import {RevogoClientService} from "./revogo-client.service";
import {
  QcmProposition,
  QcmPropositionAnswerType,
  QcmSession,
  QcmSessionEntity,
  QcmSessionEntityAnswer
} from "./revogo-client.types";
import {Subject} from "rxjs";

type QcmSessionState = {
  validated: boolean,
  answers: QcmSessionEntityAnswer[]
};

const defaultState: QcmSessionState = {
  validated: false,
  answers: []
};

@Injectable({providedIn: 'root'})
export class QcmSessionService {
  private sessionId: string | undefined;
  private entityId: string | undefined;
  private sessionPropositions: QcmProposition[] | undefined = undefined;
  private _state: QcmSessionState = defaultState;
  private sessionBindingObserver = new Subject<boolean>();

  constructor(private revogoClient: RevogoClientService) {

  }

  public async bindToSession(sessionId: string) {
    if (this.sessionId !== sessionId) {
      this.unbind();
      const session = await this.revogoClient.getSession(sessionId).toPromise() as QcmSession;
      this.sessionId = session.sessionId;
      this.sessionPropositions = session.sessionDefinition.propositions;
      const sessionEntities = await this.revogoClient.getUserSessionEntities(sessionId).toPromise();
      if (sessionEntities.length === 1) {
        const entity = (sessionEntities[0] as QcmSessionEntity)
        this.entityId = entity.entityId;
        this.state = {
          validated: entity.entityData.validated,
          answers: entity.entityData.answers
        };
      } else if (sessionEntities.length > 1) {
        throw new Error("Invalid Session Entities states: multiple entities for QCM session");
      }
      this.sessionBindingObserver.next(true);
    }
  }

  public async bound() {
    // Resolves when the service is bound to a session
    if (this.sessionPropositions !== undefined && this.sessionId !== undefined) {
      return;
    } else {
      return new Promise<void>((resolve, reject) => {
        this.sessionBindingObserver.subscribe(
          e => {
            if (e === true) {
              resolve();
            }
          }
        );
      });
    }
  }

  public async saveState() {
    if (this.sessionId) {
      if (!this.entityId) {
        const created = await this.revogoClient.createSessionEntity(this.sessionId, this.entityPayload).toPromise();
        this.entityId = created.entityId;
      } else {
        await this.revogoClient.updateSessionEntity(this.sessionId, this.entityId, this.entityPayload).toPromise();
      }
    } else {
      throw new Error("Can't save state before session service is bound to a session.");
    }

  }

  public unbind() {
    this.entityId = undefined;
    this.sessionId = undefined;
    this._state = defaultState;
    this.sessionBindingObserver.next(false);
  }

  public setAnswer(propositionIndex: number, answerIndex: number, content: unknown = undefined) {
    if (this.sessionPropositions === undefined) {
      throw new Error("Service is not bound to a session");
    } else if (propositionIndex >= this.sessionPropositions.length) {
      throw new Error("Proposition is out of bounds");
    } else if (propositionIndex > this.state.answers.length) {
      throw new Error("Previous propositions must be answered first");
    } else if (answerIndex >= this.sessionPropositions[propositionIndex].allowedAnswers.length) {
      throw new Error(`Answer index "${answerIndex}" is out of bounds for proposition "${propositionIndex}"`);
    } else if (this.sessionPropositions[propositionIndex].allowedAnswers[answerIndex].type === QcmPropositionAnswerType.Choice) {
      // Choice answer is stored by its index
      const currentAnswers = this.state.answers;
      currentAnswers[propositionIndex] = {index: answerIndex};
      this.state = {
        validated: false,
        answers: currentAnswers
      }
    } else {
      // Free text answer is stored by index and the text string
      if (typeof content !== "string") {
        throw new Error("Free text answer must be a string")
      } else {
        const currentAnswers = this.state.answers;
        currentAnswers[propositionIndex] = {index: answerIndex, content};
        this.state = {
          validated: false,
          answers: currentAnswers
        }
      }
    }
  }

  public getAnswer(propositionIndex: number): QcmSessionEntityAnswer | undefined {
    if (this.sessionPropositions === undefined) {
      throw new Error("Service is not bound to a session");
    } else if (propositionIndex >= this.sessionPropositions.length) {
      throw new Error("Proposition is out of bounds");
    } else if (propositionIndex >= this.state.answers.length) {
      return undefined;
    } else {
      return this.state.answers[propositionIndex];
    }
  }

  public validate() {
    this.state = {
      validated: true,
      answers: this.state.answers
    };
  }

  public get validated(): boolean | undefined {
    if (this.sessionPropositions === undefined) {
      return undefined;
    } else {
      return this.state.validated;
    }
  }

  private set state(state: QcmSessionState) {
    if (this._state.validated) {
      throw new Error("Can't mutate validated QCM.");
    } else {
      this._state = state;
    }
  }

  private get state() {
    return this._state;
  };

  private get entityPayload() {
    return {entity_data: this.state};
  }


  public propositionCount(): number {
    if (this.sessionPropositions === undefined) {
      return 0;
    } else {
      return this.sessionPropositions.length;
    }
  }
}
