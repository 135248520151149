import {Component, Input, OnInit} from "@angular/core";
import {SessionParticipationMode, SessionType} from "../../revogo-client/revogo-client.types";

@Component({
  selector: 'app-session-participation-mode',
  template: `{{displayName}}`,
})
export class SessionParticipationModeComponent implements OnInit {
  @Input()
  public mode!: SessionParticipationMode;
  public displayName!: string;

  ngOnInit() {
    this.displayName = {
      [SessionParticipationMode.AnonymousInvitation]: 'Invitation anonyme',
      [SessionParticipationMode.AuthenticatedUsers]: 'Utilisateurs enregistrés',
    }[this.mode];
  }
}
