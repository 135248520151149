import {Component, Input, OnDestroy, OnInit, Output, EventEmitter} from "@angular/core";
import {ProblemAnalysisSession, ThreadMessageEntity} from "../../revogo-client/revogo-client.types";
import {interval, Observable, Subject} from "rxjs";
import {LiveThreadService, ThreadNode, ThreadSortMode, ThreadView} from "../../live-thread/live-thread.service";
import {RevogoClientService} from "../../revogo-client/revogo-client.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {
  ThreadChangeRootEvent,
  ThreadEvent,
  ThreadEventKind,
} from "./thread.event";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService, GlobalAuthMode} from "../../auth/auth.service";
import {debounce} from "rxjs/operators";
import {MatSelectChange} from "@angular/material/select";
import {ThreadHelpModalComponent} from "./thread-help-modal.component";
import {MessageContextButtonDefinition} from "./thread-context-actions";
import {ThreadViewCacheService} from "./thread-view-state.service";

type ThreadSession = ProblemAnalysisSession;

@Component({
  selector: 'app-thread-view',
  template: `
    <div class="top-0 header text-white text-center text-md w-full" style="background-color: #56979F;">
      <div class="p-2" *ngIf="session">{{session.sessionName}}</div>
    </div>

    <div class="bg-gray-100 h-full w-full m-0 pt-1">
      <div class="border-2 border-green-600 shadow-lg rounded-md p-1 mx-1 mb-1 lg:mx-16 lg:my-4 bg-white">
        <div class="whitespace-pre-line">{{session.sessionDefinition.statement}}</div>
      </div>
      <div class="flex max-w-full">

        <div class="flex p-2">
          <button mat-raised-button color="primary" (click)="rootMessageEvent()">
            <mat-icon>reply</mat-icon>
            Répondre
          </button>
        </div>

        <mat-divider vertical></mat-divider>

        <div class="mx-1">
          <mat-form-field class="h-px h-32 message-thread-sort-select">
            <mat-label>Trier par</mat-label>
            <mat-select (selectionChange)="setSort($event)">
              <mat-option [value]="sortMode.RecentFirst">
                Plus récent
              </mat-option>
              <mat-option [value]="sortMode.BestScoreFirst">
                Meilleurs votes
              </mat-option>
              <mat-option [value]="sortMode.ControversialFirst">
                Controversé
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>


        <div class="flex-grow"></div>

        <mat-divider vertical></mat-divider>
        <div class="m-1">
          <button mat-icon-button matTooltip="Surligner mes messages" (click)="toggleMyMessagesHighlighting()">
            <mat-icon>highlight</mat-icon>
          </button>
        </div>

        <mat-divider vertical></mat-divider>

        <div class="m-1">
          <button mat-icon-button matTooltip="Rafraîchir le fil" (click)="synchronize(false)">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>

        <mat-divider vertical></mat-divider>

        <div class="m-1">
          <button matTooltip="Aide" mat-icon-button (click)="helpModal()">
            <mat-icon>help_outline</mat-icon>
          </button>
        </div>

      </div>

      <mat-divider></mat-divider>

      <div class="lg:mx-16">
        <div class="m-2" *ngIf="rootNodeId !== undefined">
          <div>
            <button matTooltip="Vous consultez un sous-fil de réponse" mat-stroked-button color="primary"
                    (click)="rootNodeId = undefined">
              Revenir au fil principal
            </button>
          </div>

        </div>
        <app-message-thread *ngIf="threadPipe !== undefined"
                            [contextButtonDefinition]="contextButtonDefinition"
                            (event)="forwardThreadEvent($event)"
                            [thread]="threadPipe"
                            [maxNestingLevel]="maxNesting"
                            [messageProcessor]="messageProcessor"
        ></app-message-thread>
      </div>

    </div>

    <div *ngIf="loading" class="flex mt-8">
      <div class="flex-grow"></div>
      <mat-spinner></mat-spinner>
      <div class="flex-grow"></div>
    </div>

    <div *ngIf="!loading" class="text-center m-4 text-gray-500">
      Fin du fil de discussion
    </div>

  `
})
export class ThreadViewComponent implements OnInit, OnDestroy {
  @Input("session")
  public session!: ThreadSession;

  @Output()
  public event = new EventEmitter<ThreadEvent>();

  @Input()
  contextButtonDefinition!: MessageContextButtonDefinition[];

  @Input()
  syncDebounceChannel!: Subject<boolean>;

  @Input()
  messageProcessor: (node: ThreadNode) => string = node => node.message;

  public threadPipe!: Observable<ThreadView | undefined>;
  public maxNesting = 10;
  public loading = true;
  public entities = new Subject<ThreadMessageEntity>();
  public sortMode = ThreadSortMode;

  private _rootNodeId: string | undefined = undefined;
  private myUserId!: string | undefined;
  private intervalId!: any;
  private highlightMyMessages: boolean = false;

  constructor(private liveThreadService: LiveThreadService,
              private authService: AuthService,
              private revogoClient: RevogoClientService,
              private snackbar: MatSnackBar,
              private dialog: MatDialog,
              private route: ActivatedRoute,
              private router: Router,
              private threadViewCacheService: ThreadViewCacheService) {
  }

  async ngOnInit() {
    this.threadViewCacheService.reset();
    this.myUserId = this.authService.getUserId();
    this.determineMaxNestingLevel();
    this.threadPipe = await this.liveThreadService.bind(this.session.sessionId, this.myUserId);
    if (this._rootNodeId) {
      this.liveThreadService.setRoot(this.rootNodeId);
    }
    this.liveThreadService.setSort(ThreadSortMode.RecentFirst);
    await this.liveThreadService.synchronize();
    this.loading = false;

    this.intervalId = setInterval(() => this.synchronize(true), 10000);

    this.route.queryParams.subscribe(async params => {
      this._rootNodeId = params['root'];
      this.liveThreadService.setRoot(this._rootNodeId);
      await this.liveThreadService.synchronize();
    });
    this.syncDebounceChannel.pipe(debounce((shouldDebounce) => {
      if (shouldDebounce) {
        return interval(1000)
      } else {
        return interval(0);
      }
    })).subscribe(() => {
      this.liveThreadService.synchronize();
    });
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  private determineMaxNestingLevel() {
    let maxNestingLevel = Math.floor(window.innerWidth / 100) -1;
    if (maxNestingLevel <= 0) {
      maxNestingLevel = 1;
    }
    this.maxNesting = maxNestingLevel;
  }

  public synchronize(debounce: boolean = true) {
    this.syncDebounceChannel.next(debounce);
  }

  public set rootNodeId(id: string | undefined) {
    this._rootNodeId = id;
    this.router.navigate([], {queryParams: {root: id}});
  }

  public get rootNodeId() {
    return this._rootNodeId;
  }

  public async forwardThreadEvent(event: ThreadEvent) {
    if (event.kind === ThreadEventKind.ChangeRoot) {
      const chrootEvent = event as ThreadChangeRootEvent;
      this.rootNodeId = chrootEvent.rootNodeId;
    }
    this.event.emit(event);
  }

  public rootMessageEvent() {
    this.event.emit({
      kind: ThreadEventKind.Reply,
      parentNodeId: undefined,
    });
  }

  public helpModal() {
    let authpart: string;
    if (this.authService.globalAuthMode === GlobalAuthMode.Account) {
      authpart = 'Vous êtes connecté: votre prénom sera visible sous vos contributions.';
    } else {
      authpart = "Vous avez rejoint ce fil en tant qu'invité: vos contributions sont anonymes.\n" +
        "Elles sont cependant reliées à un identifiant unique, ce qui permet aux modérateurs de bannir les utilisateurs abusifs.";
    }

    const ref = this.dialog.open(ThreadHelpModalComponent);
    ref.componentInstance.helpText = `
        Vous pouvez poster des messages via le bouton "Répondre". Il est possible de répondre à n'importe quel message.
        Les boutons "pouce" servent à voter sur les messages que vous trouvez pertinents.
        Vous avez toujours la possibilité de modifier vos messages tant que l'administrateur n'a pas mit fin à la session.
        Vous pouvez également supprimer votre message tant que personne n'y a répondu.

        ${authpart}
    `;
  }

  public setSort($event: MatSelectChange) {
    this.liveThreadService.setSort($event.value);
  }

  public toggleMyMessagesHighlighting() {
    if (this.highlightMyMessages === true) {
      this.highlightMyMessages = false;
      this.liveThreadService.setHighlighter(() => false);
    } else {
      this.highlightMyMessages = true;
      this.liveThreadService.setHighlighter(node => node.isMine);
    }
  }
}
