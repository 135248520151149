<div class="border border-gray-500 rounded-sm shadow-md">

  <div class="flex m-2">
    <div>
      <app-session-type class="text-lg" [type]="session.sessionType"></app-session-type>
      <span class="m-2 text-md primary-text italic">
        <app-session-state [state]="session.sessionState"></app-session-state>
      </span>
    </div>
    <div class="flex-grow"></div>
    <div class="text-lg">{{session.creationDate | date:'dd/MM/YYYY'}}</div>
  </div>

  <div *ngIf="canWrite" class="m-2">
    <app-session-start-button [redirect]="false" *ngIf="session.sessionState === 'draft'" [session]="session"></app-session-start-button>
    <app-session-close-button [redirect]="false" *ngIf="session.sessionState === 'started'" [session]="session"></app-session-close-button>
  </div>

  <mat-divider></mat-divider>

  <div class="m-2 mt-4">
    <div class="text-sm text-gray-500 italic">Titre</div>
      <mat-form-field class="w-full ml-2">
        <input class="w-full" matInput type="text" [disabled]="!editable" [(ngModel)]="session.sessionName">
      </mat-form-field>
  </div>

  <div class="m-2">
    <div class="text-sm text-gray-500 italic">
      Mode de participation
    </div>
    <div class="m-2">
      <app-session-participation-mode [mode]="session.participationMode"></app-session-participation-mode>
      <div *ngIf="session.participationMode === participationMode.AnonymousInvitation" class="w-full">
        <span>Jetons disponibles :&nbsp;</span>
        <input id="token-count-input" min="0" max="500" class="w-min" matInput type="number" [disabled]="!editable"
               [(ngModel)]="session.anonymousInvitation.tokenCount">
      </div>
    </div>
  </div>

  <div class="m-2" *ngIf="session.participationMode === participationMode.AnonymousInvitation">
    <div class="text-sm text-gray-500 italic">
      Lien d'invitation pour participation anonyme
    </div>
    <div class="m-2 underline">
      <a [href]="'http://'+domain+'/join/'+session.inviteSlug">{{domain}}/join/{{session.inviteSlug}}</a>
    </div>
  </div>

  <div class="m-2">
    <div class="text-sm text-gray-500 italic">
      Lien de participation pour utilisateurs authentifiés (ex: Modérateurs)
    </div>
    <div class="m-2 underline">
      <a [href]="'http://'+domain+'/play/'+session.sessionId">{{domain}}/play/{{session.sessionId}}</a>
    </div>
  </div>

  <mat-divider></mat-divider>
  <div class="m-2">
    <div class="text-sm text-gray-500 italic">
      Date de création
    </div>
    <div class="ml-2">
      {{session.creationDate | date:dateFormat}}
    </div>
  </div>

  <ng-container *ngIf="session.startDate">
    <div class="m-2">
      <div class="text-sm text-gray-500 italic">
        Date de début
      </div>
      <div class="ml-2">
        {{session.startDate | date:dateFormat }}
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="session.endDate">
    <div class="m-2">
      <div class="text-sm text-gray-500 italic">
        Date de fin
      </div>
      <div class="ml-2">
        {{session.endDate | date:dateFormat }}
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="canWrite">
    <mat-divider></mat-divider>
    <div class="flex m-2">
      <div *ngIf="editable">
        <button mat-raised-button color="primary" (click)="updateMetadata()">Sauvegarder</button>
      </div>
      <div class="flex-grow"></div>
      <div *ngIf="session.sessionState === 'draft'" class="mx-2">
        <button mat-stroked-button color="error" (click)="deleteModal()">Supprimer</button>
      </div>
      <div>
        <button mat-stroked-button color="primary" (click)="cloneModal()">Dupliquer</button>
      </div>
    </div>
  </ng-container>
</div>





