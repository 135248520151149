import { NgModule } from "@angular/core";
import { SignupComponent } from "./signup.component";
import {MatInputModule} from '@angular/material/input';
import { STATE } from "src/app/app-states";
import {MatIconModule} from "@angular/material/icon";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatButtonModule} from "@angular/material/button";
import {CommonModule} from "@angular/common";
import {ConfirmRegistrationComponent} from "./confirm-registration.component";
import {ForgotPasswordComponent} from "./forgot-password.component";

export const SIGNUP_STATE = {
  path: STATE.SIGNUP,
  component: SignupComponent
};

export const CONFIRM_REGISTRATION_STATE = {
  path: STATE.CONFIRM_REGISTRATION,
  component: ConfirmRegistrationComponent,
}

export const FORGOT_PASSWORD_STATE = {
  path: STATE.FORGOT_PASSWORD,
  component: ForgotPasswordComponent,
}

@NgModule({
  declarations: [
    SignupComponent,
    ConfirmRegistrationComponent,
    ForgotPasswordComponent,
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    FormsModule,
  ],
  providers: [],
})
export class SignupModule {}
