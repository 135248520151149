import {Component, OnInit} from "@angular/core";
import {GuestAuthService} from "../../auth/guest-auth.service";
import {AuthService, GlobalAuthMode} from "../../auth/auth.service";
import {StateRouterService} from "../../state-router/state-router.service";
import {STATE} from "../app-states";
import {ActivatedRoute} from "@angular/router";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-join-component',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.sass']
})
export class JoinComponent implements OnInit {
  public loading = true;
  public messages: string[] = [];

  constructor(
    private authService: AuthService,
    private guestAuthService: GuestAuthService,
    private stateRouter: StateRouterService,
    private route: ActivatedRoute,
  ) {
  }

  async ngOnInit() {
    this.guestAuthService.bindToInvitation(this.route.snapshot.params['inviteSlug']);
    const currentAuthMode = this.authService.globalAuthMode;
    if (currentAuthMode === GlobalAuthMode.Account) {
      // FIXME: handle invite resolution without token grant in the backend
      // For now we logout the user and force him as to act as a guest
      // That's not the full functionality of the app
      this.authService.signOut()
      this.authService.setGlobalAuthMode(GlobalAuthMode.Guest);
    }
    // currentAuthMode === GlobalAuthMode.Guest
    const sessionId = this.guestAuthService.getSessionId();
    console.log(sessionId);
    if (sessionId !== null) {
      this.stateRouter.nav(STATE.PLAY, sessionId)
    } else {
      await this.resolve();
    }
  }

  private async resolve() {
    console.log('resolving invite');
    const inviteSlug = this.route.snapshot.params['inviteSlug'];
    await this.guestAuthService.resolveInvitation(inviteSlug).then(
      token => {
        this.loading = false;
        this.stateRouter.nav(STATE.PLAY, token.sessionId);
      }
    ).catch(error => this.handleInviteResolutionError(error));

  }

  private handleInviteResolutionError(err: HttpErrorResponse) {
    switch (err.status) {
      case 404:
        this.messages = ["Il semble que cette invitation n'existe pas.",
          "Vous allez être redirigé dans quelques instants."];
        setTimeout(() => this.stateRouter.nav(STATE.ROOT), 5000);
        break;
      case 400:
      case 403:
        this.messages = ["Vous ne pouvez pas rejoindre cette session maintenant.",
          "Si elle n'a pas encore commencé, vous serez redirigé automatiquement lorsque que vous pourrez participer."];
        setTimeout(async () => {await this.resolve()}, 5000);
        break;
      default:
        this.messages = [`Erreur inconnue : ${err.message}`, "Vous allez être redirigé dans quelques instants."];
        setTimeout(() => this.stateRouter.nav(STATE.ROOT), 10000);
    }
  }
}
