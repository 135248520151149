import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import {absStateUrl, STATE} from "../app-states";
import {StateRouterService} from "../../state-router/state-router.service";
import {ActivatedRoute, Router} from "@angular/router";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {GuestAuthService} from "../../auth/guest-auth.service";
import {TempRedirectService} from "../../temp-redirect/temp-redirect.service";


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {
  public form!: FormGroup;
  public errorDurationMs: number = 10000;
  public loading: boolean = false;
  public signUpLink: string = absStateUrl([STATE.SIGNUP]);
  public showForgotPassword = false;

  constructor(
    private authService: AuthService,
    private guestAuthService: GuestAuthService,
    private stateRouterService: StateRouterService,
    private tempRedirectService: TempRedirectService,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    ) {
    this.form = new FormGroup({
      email: new FormControl('', Validators.email),
      password: new FormControl('', Validators.required)
    })
  }

  ngOnInit() {
    this.guestAuthService.clearCache();
    if (this.route.snapshot.queryParams['from'] !== undefined) {
      this.tempRedirectService.setUrl(this.route.snapshot.queryParams['from']);
    }
    if (this.authService.isAuthenticated()) {
        this.loading = true;
        const userData = this.authService.getUserData();
        if (!userData.knownUser) {
          this._snackBar.open('Votre session a expiré. Veuillez vous connecter à nouveau.', undefined, {duration: 10000});
          this.loading = false;
        } else {
          this._snackBar.open(`Bienvenue ${userData.displayName} ! Nous préparons votre retour ...`, undefined, {duration: 3000});
          this.stateRouterService.nav(STATE.ORGANIZATION_SELECT);
        }
    } else if (this.route.snapshot.queryParams["code"]) {
        this.loading = true;
        this.authService.loginWithOAuthAuthorizationToken(
          this.route.snapshot.queryParams["code"],
          window.location.href.split(/[?#]/)[0]
        ).then(async (res) => {
          if (this.tempRedirectService.hasRedirect()) {
            await this.tempRedirectService.execute();
          } else {
            await this.stateRouterService.nav(STATE.ORGANIZATION_SELECT);
          }
        }, (error) => {
          this.loading = false;
          this._snackBar.open(`Nous n'avons pas pu vous authentifier: ${error}`, undefined, {duration: this.errorDurationMs, panelClass: ['error-snackbar']});
        });
    }
  }

  public async login() {
    this.loading = true;
    return this.authService.signInWithPassword(
      {username: this.form.controls['email'].value, password: this.form.controls['password'].value}
    ).then(() => {
      this.loading = false;
      this.stateRouterService.nav(STATE.ORGANIZATION_SELECT);
    }, (error) => {
      this.loading = false;
      let msg;
      if (error === "NotAuthorizedException" || error === "UserNotFoundException") {
        msg = "Combinaison Utilisateur / mot de passe invalide";
        this.showForgotPassword = true;
      } else if (error === "UserNotConfirmedException") {
        this.router.navigate(['/', STATE.CONFIRM_REGISTRATION], {queryParams: {u: this.form.controls.email.value}});
      } else {
        msg = `Erreur non prévue: ${error}`
      }
      if (msg !== undefined) {
        this._snackBar.open(msg, undefined, {duration: this.errorDurationMs, panelClass: ['error-snackbar']});
      }
    })
  }

  public async googleSignIn() {
    this.authService.authorizeOAuthProvider(
      {
        providerName: "Google",
        redirectUri: window.location.href.split(/[?#]/)[0],
        scope: 'email profile openid'
      });
  }

  public async microsoftSignIn() {
    this.authService.authorizeOAuthProvider(
      {
        providerName: "Microsoft-AzureDirectory",
        redirectUri: window.location.href.split(/[?#]/)[0],
        scope: '',
      }
    )
  }
}
