import {Injectable} from "@angular/core";

@Injectable()
export class ThreadViewCacheService {
  // Store arbitrary data that is kept between thread updates

  private cache: {[key: string]: any} = {};

  public set(key: string, value: any) {
    this.cache[key] = value;
  }

  public get(key: string): any {
    return this.cache[key];
  }

  public reset() {
    this.cache = {};
  }
}
