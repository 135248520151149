import {Injectable} from "@angular/core";
import {RevogoClientService} from "../revogo-client/revogo-client.service";
import {Organization, UserAuthorizations} from "../revogo-client/revogo-client.types";
import {map, tap} from "rxjs/operators";
import {from, Observable} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  private storageKey: string = 'selectedOrganizationId';
  private authorizedOrganizations!: Organization[];
  private selectedOrganization: Organization | null = null;
  private userAuthorizations: UserAuthorizations | null = null;

  constructor(private revogoClient: RevogoClientService) {
    this.revogoClient.getOrganizations().subscribe(
      organizations => {
        this.authorizedOrganizations = organizations;
        if (localStorage.getItem(this.storageKey) !== undefined) {
          if (this.authorizedOrganizations.length > 0) {
            const selectedOrganization = this.authorizedOrganizations.filter(
              org => org.organizationId === localStorage.getItem(this.storageKey)
            );
            if (selectedOrganization.length === 1) {
              this.setSelectedOrganization(selectedOrganization[0]);
            } else {
              this.clearSelected();
            }
          } else {
            this.clearSelected();
          }
        }
      }
    );
    this.getUserAuthorizations();
  }

  private static selectedOrganizationExtractor(organizationId: string) {
    return (organizations: Organization[]) => organizations.filter(org => org.organizationId === organizationId)[0];
  }

  public clearSelected() {
    localStorage.removeItem(this.storageKey);
    this.selectedOrganization = null;
  }

  public setSelectedOrganization(organization: Organization) {
    localStorage.setItem(this.storageKey, organization.organizationId);
    this.selectedOrganization = organization;
  }


  public getSelectedOrganization(): Observable<Organization | null> {
    const orgId = localStorage.getItem(this.storageKey);
    if (this.selectedOrganization !== null) {
      return from<Promise<Organization>>(
        new Promise((resolve, reject) => {
          if (this.selectedOrganization !== null) { resolve(this.selectedOrganization); }
          else {
            reject(this.selectedOrganization)
          }
        })
      );
    } else if (orgId === null) {
      return from<Promise<null>>(
        new Promise((r) => r(null))
      );
    }  else {
      return this.getAuthorizedOrganizations().pipe(
        map(OrganizationService.selectedOrganizationExtractor(orgId)),
      ).pipe(
        tap(org => this.setSelectedOrganization(org)
        )
      );
    }
  }

  public getAuthorizedOrganizations(): Observable<Organization[]> {
    return this.revogoClient.getOrganizations().pipe(
      tap(
        organizations => this.authorizedOrganizations = organizations
      )
    );
  }

  public async getUserAuthorizations(): Promise<UserAuthorizations> {
    if (this.userAuthorizations === null) {
      this.userAuthorizations = await this.revogoClient.getAuthorizations().toPromise();
    }
    return this.userAuthorizations;
  }

  public async userHasRole(targetRoles: string[]): Promise<boolean> {
    const org = this.selectedOrganization;
    if (org === null) {
      return false;
    } else {
      let found = false;
      const userAuthorizations = await this.getUserAuthorizations();
      userAuthorizations.roles.forEach(role => {
        if (role.organizationId === '*' || role.organizationId === org.organizationId) {
          targetRoles.forEach(targetRole => {
            if (role.services.indexOf(targetRole) !== -1) {
              found = true;
            }
          });
        }
      });
      return found;
    }
  }
}
