import {Component, Input} from "@angular/core";

@Component({
  selector: 'app-thread-help-modal',
  template: `
    <mat-dialog-content>
      <h2 mat-dialog-title class="text-center">Fil de discussion</h2>
      <div class="text-center">
        <p class="whitespace-pre-line">{{helpText}}</p>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions class="flex">
      <div class="flex-grow"></div>
      <div>
        <button mat-raised-button color="primary" [mat-dialog-close]>Ok</button>
      </div>
      <div class="flex-grow"></div>
    </mat-dialog-actions>
  `
})
export class ThreadHelpModalComponent {
  @Input()
  helpText!: string;
}
