import {Component, OnInit} from "@angular/core";
import {Organization, UserAuthorizations} from "../../revogo-client/revogo-client.types";
import {ActivatedRoute} from "@angular/router";
import {AuthService} from "../../auth/auth.service";
import {StateRouterService} from "../../state-router/state-router.service";
import {OrganizationService} from "../../auth/organization.service";
import {STATE} from "../app-states";
import {RevogoClientService} from "../../revogo-client/revogo-client.service";

@Component({
  selector: 'app-header',
  template: `
    <div class="header-container">
      <div mat-ripple>
        <div class="text-white mt-1 m-2 cursor-pointer text-center" routerLink="/sessions/list">
          <div class="text-3xl">revogo</div>
          <div class="text-xs text-gray-300 text-bold">Hiram Finance</div>
        </div>
      </div>

      <div class="header-nav flex text-lg text-white">
        <mat-divider vertical></mat-divider>
        <div mat-ripple class=" cursor-pointer" routerLink="/sessions/list">
          <div class="mt-4 mx-4">Sessions</div>
        </div>
        <mat-divider vertical></mat-divider>
        <div mat-ripple class=" cursor-pointer" routerLink="/problems/list">
          <div class="mt-4 mx-4">Problèmes</div>
        </div>
        <mat-divider vertical></mat-divider>
      </div>
      <div class="header-space"></div>
      <mat-divider vertical></mat-divider>
      <div class="header-userinfo-container cursor-pointer" mat-ripple [matMenuTriggerFor]="userMenu">
        <div class="header-username">{{username}}</div>
        <div class="header-organization">{{organization.organizationName}}</div>
        <mat-menu #userMenu="matMenu">
          <button mat-menu-item (click)="goToOrganizationSwitcher()">
            <mat-icon [color]="'primary'">corporate_fare</mat-icon>
            Changer d'organisation
          </button>
          <button *ngIf="isOrganizationAdmin" mat-menu-item (click)="goToAdmin()">
            <mat-icon [color]="'primary'">admin_panel_settings</mat-icon>
            Administration
          </button>
        </mat-menu>
      </div>
      <mat-divider vertical></mat-divider>
      <mat-icon class="logout-icon" color="warn" (click)="signOut()">logout</mat-icon>
    </div>
  `,
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {
  public userData!: { [key: string]: any };
  public username!: string;
  public organization!: Organization;
  public userAuthorizations!: UserAuthorizations;
  public isOrganizationAdmin = false;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private stateRouterService: StateRouterService,
    private organizationService: OrganizationService,
    private revogoClient: RevogoClientService,
  ) {
    this.userData = this.route.snapshot.data.userData;
    // console.log(this.userData);
    if (this.userData === null || this.userData === undefined) {
      this.username = "Utilisateur Inconnu";
    } else {
      this.username = this.userData.displayName;
    }
    this.organization = this.route.snapshot.data.selectedOrganization;
  }

  async ngOnInit() {
    if (!this.authService.isAuthenticated()) {
      this.stateRouterService.nav(STATE.LOGIN);
    }

    this.isOrganizationAdmin = await this.organizationService.userHasRole(['superadmin', 'administrator']);
  }

  public signOut() {
    this.authService.signOut();
    this.organizationService.clearSelected();
    this.stateRouterService.nav(STATE.ROOT);
  }

  public goToOrganizationSwitcher() {
    this.organizationService.clearSelected();
    this.stateRouterService.nav(STATE.ORGANIZATION_SELECT);
  }

  public goToAdmin() {
    this.stateRouterService.nav(STATE.ADMIN_PANEL);
  }
}
