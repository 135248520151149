<mat-expansion-panel expanded>

  <mat-expansion-panel-header>
    <mat-panel-title class="text-lg">
      Contenu de la session: {{session.sessionDefinition.propositions.length}}
      proposition{{session.sessionDefinition.propositions.length > 1 ? 's' : ''}}
    </mat-panel-title>
  </mat-expansion-panel-header>

  <div *ngIf="editable && session.sessionDefinition.propositions.length > 0" class="my-4">
    <button mat-raised-button color="primary" (click)="updateSessionDefinition()">
      Sauvegarder
    </button>
  </div>

  <div *ngIf="session.sessionDefinition.propositions.length > 0" class="w-auto">

    <div class="m-4 p-4 border border-gray-500 rounded"
         *ngFor="let proposition of session.sessionDefinition.propositions; let propositionIndex = index;">

      <div class="text-lg w-full my-4 flex">

        <mat-form-field class="flex-grow">
          <mat-label>Titre de la question / affirmation</mat-label>
          <input matInput type="text" [(ngModel)]="session.sessionDefinition.propositions[propositionIndex].title"
                 [disabled]="!editable" (change)="setDirty()">
        </mat-form-field>

        <div class="mt-4">

          <mat-icon (click)="swapPropositions(propositionIndex, propositionIndex+1)"
                    matTooltip="Décale la proposition après la suivante" class="cursor-pointer px-2">south
          </mat-icon>
          <mat-icon (click)="swapPropositions(propositionIndex, propositionIndex-1)"
                    matTooltip="Décale la proposition avant la précédente" class="cursor-pointer px-2">north
          </mat-icon>

        </div>

      </div>

      <div class="m-2">Réponses possibles:</div>
      <mat-accordion class="allowed-answers-container">
        <mat-expansion-panel *ngFor="let answer of proposition.allowedAnswers; let answerIndex = index;" hideToggle>
          <mat-expansion-panel-header class="justify-between">
            <mat-panel-title>
                  <span class="primary-text">
                    <app-qcm-answer-type [type]="answer.type"></app-qcm-answer-type>&nbsp;:&nbsp;
                  </span>&nbsp;{{answer.label}}
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div *ngIf="editable">
            <div>
              <div>
                <mat-form-field>
                  <mat-label>Contenu de la réponse</mat-label>
                  <input class="w-full" matInput type="text"
                         [(ngModel)]="session.sessionDefinition.propositions[propositionIndex].allowedAnswers[answerIndex].label"
                         (change)="setDirty()">
                </mat-form-field>
              </div>
              <div>
                <mat-checkbox (change)="setAnswerType(propositionIndex, answerIndex, $event)">
                  Demander une explication
                </mat-checkbox>
              </div>
            </div>
            <div class="flex">
              <div class="flex-grow"></div>
              <button mat-stroked-button color="warn" *ngIf="editable"
                      (click)="deleteAnswer(propositionIndex, answerIndex)">
                Supprimer cette réponse
              </button>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <div *ngIf="editable" class="mt-4 flex">
        <button mat-stroked-button color="primary" (click)="pushAllowedAnswer(propositionIndex)">Ajouter une réponse
          possible
        </button>
        <div class="flex-grow"></div>
        <button mat-stroked-button color="warn" (click)="deleteProposition(propositionIndex)">Supprimer cette
          proposition
        </button>
      </div>

    </div>
  </div>

  <div *ngIf="editable">
    <button mat-stroked-button color="primary" (click)="pushProposition()">Ajouter une proposition</button>
  </div>

</mat-expansion-panel>
